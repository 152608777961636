import axios from "axios";
import { IQueryParams } from "models/base";
import { IBranch, IBranchPayload, IBranchesResponse } from "models/branches";

export function getBranches(params?: Partial<IQueryParams>) {
  return axios.get<IBranchesResponse>(`/branch`, { params });
}

export function createBranch(payload: IBranchPayload) {
  return axios.post(`/branch`, payload);
}
export function updateBranch(branchId: string, payload: IBranch) {
  return axios.patch(`/branch/${branchId}`, payload);
}
