import React, { useEffect, useRef, useState } from "react";
import * as icons from "assets/svg/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useLogOut } from "hooks/useLogOut";
import { motion, AnimatePresence } from "framer-motion";
import {
  activeSubscription,
  isAdmin,
  truncateString,
  customStyles,
} from "helpers";
import { getNavLinks } from "helpers/getNavLinks";
import { ChevronDown } from "assets/svg/icons";
import { _getBranches } from "../../../store/actions";
import { setBranch } from "../../../store/actions/auth";
import useWindowDimension from "hooks/useWindowDimension";
import useOnClickOutside from "hooks/useOnClickOutside";
import SubscriptionModal from "components/SubscriptionModal";
import Select from "react-select";
import Logo from "components/Elements/Logo";
import styles from "./Navbar.module.scss";

type Props = {
  title: string;
  setSideBarDisplay?: () => void;
};

export default function Navbar({ title, setSideBarDisplay }: Props) {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { logOut } = useLogOut();
  const { width } = useWindowDimension();
  const { data: settings } = useSettingsQuery();
  const { pathname } = useLocation();
  const { user, branch, subscriptionDaysLeft, permissions } = useSelector(
    (state) => state.auth
  );
  const { data: branches } = useSelector((state) => state.branches);
  const { reports } = useSelector((state) => state.reports);
  const [dropdown, setDropdown] = useState(false);
  const [switchBranch, setSwitchBranch] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(false);
  useOnClickOutside(dropdownRef, () => {
    setDropdown(false);
    setSwitchBranch(false);
  });

  useEffect(() => {
    dispatch(_getBranches());
  }, []);

  return (
    <>
      <div className={styles.container}>
        {!activeSubscription(reports) && isAdmin(user) && (
          <div className={styles["subscription-banner"]}>
            <p>
              You're on a free plan. Click{" "}
              <span onClick={() => setModalDisplay(true)}>here</span> to upgrade
              your free plan.
            </p>
          </div>
        )}

        {subscriptionDaysLeft <= 7 && isAdmin(user) && (
          <div className={styles["subscription-banner"]}>
            <p>
              {subscriptionDaysLeft <= 0
                ? "Your subscription has expired."
                : `Your subscription expires in ${subscriptionDaysLeft} day${
                    subscriptionDaysLeft > 1 ? "s" : ""
                  }.`}{" "}
              Click <span onClick={() => setModalDisplay(true)}>here</span> to
              renew your subscription.
            </p>
          </div>
        )}

        <nav>
          {width! <= 1260 && pathname !== "/pos" && <Logo mini />}
          {pathname === "/pos" && (
            <Link to="/">
              <img
                src={settings?.data.data[0].logo}
                alt="logo"
                style={{ height: "4rem" }}
              />
            </Link>
          )}
          <h1>{title}</h1>
          <div>
            <h4>
              {truncateString(
                settings?.data.data[0].businessName ?? "",
                width! > 380 ? 100 : 10
              )}
              <br />
              {/* <span>{user.account.role}</span> */}
            </h4>
              <span>
                {branch?.name}
                {isAdmin(user) && (
                  <ChevronDown onClick={() => setSwitchBranch(true)} />
                )}
                <AnimatePresence>
                  {switchBranch && (
                    <motion.div
                      ref={dropdownRef}
                      initial={{ opacity: 0 }}
                      exit={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ type: "tween" }}
                    >
                      <Select
                        styles={customStyles({
                          height: "auto",
                          borderRadius: "4px",
                          menu: { position: "static", color: "#000000" },
                        })}
                        options={branches?.map((branch) => ({
                          value: branch,
                          label: branch.name,
                        }))}
                        id="coupon"
                        placeholder="Select Branch"
                        value={[{ value: branch, label: branch?.name }]}
                        onChange={(e: any) => {
                          dispatch(setBranch(e?.value));
                          setSwitchBranch(false);
                        }}
                        noOptionsMessage={() => "Not found"}
                        classNamePrefix="react-select"
                        // isLoading={isLoading}
                        defaultMenuIsOpen
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </span>
          </div>
          {(pathname === "/pos" || width! < 768) && (
            <span
              className={styles["svg-container"]}
              onClick={() => {
                setSideBarDisplay
                  ? setSideBarDisplay()
                  : setDropdown((prevState) => !prevState);
              }}
            >
              <icons.HamburgerIcon width={24} />
            </span>
          )}
          <AnimatePresence>
            {dropdown && (
              <motion.div
                ref={dropdownRef}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "tween" }}
                className={styles.dropdown}
              >
                <ul>
                  {getNavLinks(permissions)?.map((link) => {
                    if (link.allowed || isAdmin(user)) {
                      return (
                        <li key={link.path} onClick={() => setDropdown(false)}>
                          <NavLink
                            activeClassName={styles.active}
                            to={link.path}
                          >
                            {link.icon}
                            <span>{link.title}</span>
                          </NavLink>
                        </li>
                      );
                    }
                  })}
                </ul>
                <button onClick={logOut}>
                  <i className="mdi mdi-logout menu-icon" />
                  Sign Out
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </div>

      <SubscriptionModal
        display={modalDisplay}
        close={() => setModalDisplay(false)}
      />
    </>
  );
}
