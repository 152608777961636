import { loginBusiness, loginStaff, loginCustomer } from "services/auth";
import { toast } from "react-toastify";
import * as types from "../types";
import axios from "axios";

export function loading(bool) {
  return {
    type: types.LOADING,
    bool,
  };
}

export function setUser(payload) {
  return {
    type: types.SET_USER,
    user: payload,
  };
}

export function setEmail(payload) {
  return {
    type: types.SET_EMAIL,
    email: payload,
  };
}

export function clearEmail() {
  return {
    type: types.CLEAR_EMAIL,
  };
}

export function setRole(payload) {
  return {
    type: types.SET_PERMISSIONS,
    role: payload,
  };
}

export function logOut() {
  return {
    type: types.LOG_OUT,
  };
}

export function error() {
  return {
    type: types.ERROR,
  };
}

export function invalidSession() {
  return {
    type: types.INVALID_SESSION,
  };
}

export function loginAsBusiness() {
  return {
    type: types.LOGIN_AS_BUSINESS,
  };
}

export function loginAsCustomer() {
  return {
    type: types.LOGIN_AS_CUSTOMER,
  };
}

export function loginAsStaff() {
  return {
    type: types.LOGIN_AS_STAFF,
  };
}

export function setBranch(payload) {
  return {
    type: types.SET_BRANCH,
    branch: payload,
  };
}

export function setSubscriptionDaysLeft(payload) {
  return {
    type: types.SET_SUBSCRIPTION_DAYS_LEFT,
    days: payload,
  };
}

export function _loginBusiness({ email, password }) {
  return (dispatch) => {
    dispatch(loading());
    loginBusiness({ email, password })
      .then((response) => {
        dispatch(loading());
        dispatch(setUser(response.data.data));
        toast.success("This session is valid for only 12 hours!", {
          toastId: "valid-for-12-hours",
        });
      })
      .catch(() => {
        dispatch(loading());
      });
  };
}

export function _loginStaff({ email, password }) {
  return (dispatch) => {
    dispatch(loading());
    loginStaff({ email, password })
      .then((response) => {
        axios
          .get("/roles", {
            headers: { Authorization: response.data.data.token },
          })
          .then((_response) => {
            dispatch(loading());
            dispatch(setUser(response.data.data));
            dispatch(setRole(_response.data.data.data));
            toast.success("This session is valid for only 12 hours!", {
              toastId: "valid-for-12-hours",
            });
          })
          .catch(() => {
            dispatch(loading());
          });
      })
      .catch(() => {
        dispatch(loading());
      });
  };
}

export function _loginCustomer({ email, password }) {
  return (dispatch) => {
    dispatch(loading());
    loginCustomer({ email, password })
      .then((response) => {
        dispatch(loading());
        toast.success("This session is valid for only 12 hours!", {
          toastId: "valid-for-12-hours",
        });
        dispatch(setUser(response.data.data));
      })
      .catch(() => {
        dispatch(loading());
      });
  };
}
