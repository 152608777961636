import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IQueryParams } from "models/base";
import { getCurrencies, getSettings } from "services/Settings";

export function useSettingsQuery(params?: IQueryParams) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/settings`, { ...params }],
    queryFn: () => getSettings({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}

export function useCurrenciesQuery(params?: IQueryParams) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/settings/currency`, { ...params }],
    queryFn: () => getCurrencies({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}
