import axios from "axios";
import { IQueryParams } from "models/base";
import { IInvoicesResponse } from "models/invoice";

export function getInvoices(params: IQueryParams) {
  return axios.get<IInvoicesResponse>(`/sale/invoice/`, { params });
}

export function createInvoice(payload: any, params: IQueryParams) {
  return axios.post(`/sale/order`, payload, { params });
}

export function updateInvoice(payload: any, params: IQueryParams) {
  return axios.patch(`/sale`, payload, { params });
}
