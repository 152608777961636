import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IQueryParams } from "models/base";
import { getReceiptSettings } from "services/receipt";

export function useReceiptSettingsQuery(params?: IQueryParams) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/receipt/settings`, { ...params, branchId: _id }],
    queryFn: () => getReceiptSettings({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}
