import React, { useState, useEffect } from "react";
import * as actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSageCustomerGroups } from "hooks/useSageCustomerGroupsQuery";
import {
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "services/customers";
import { customStyles, notify } from "helpers";
import { ClipLoader } from "react-spinners";
import locations, { compare } from "data/location";
import Modal from "components/Elements/Modal";
import Select from "react-select";
import styles from "assets/styles/ActionModals.module.scss";

type Props = {
  display: boolean;
  close: () => void;
  customer: any;
};

export default function AddCustomer({ display, close, customer }: Props) {
  const dispatch = useDispatch();
  const { user, branch } = useSelector((state) => state.auth);
  const { data: sageCustomerGroups, isLoading: loadingSageCustomerGroups } =
    useSageCustomerGroups();
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [cities, setCities] = useState(null);
  const [defaultCustomerForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    group: "",
  });
  const [customerForm, setCustomerForm] = useState(defaultCustomerForm);
  const groups = sageCustomerGroups?.data.data
    ? sageCustomerGroups.data.data.map((group) => ({
        value: group,
        label: group.InterestProfile?.toLowerCase(),
      }))
    : [];
  const states = locations?.map((location) => ({
    value: location.state,
    label: location.state,
  }));
  const _cities = cities?.map((city) => ({
    value: city,
    label: city,
  }));

  const payload = {
    firstName: customerForm.firstName,
    lastName: customerForm.lastName,
    email: customerForm.email,
    phone: customerForm.phone,
    location: {
      address: customerForm.address,
      city: customerForm.city,
      state: customerForm.state,
      country: customerForm.country,
    },
    branchId: branch?._id,
    ...(customerForm.group && {
      GroupCode: customerForm.group?.GroupCode,
      Description: customerForm.group?.Description,
      TaxGroup: customerForm.group?.TaxGroup,
      AccountSet: customerForm.group?.AccountSet,
      BillingCycle: customerForm.group?.BillingCycle,
      InterestProfile: customerForm.group?.InterestProfile,
      AccountType: customerForm.group?.AccountType,
      Terms: customerForm.group?.Terms,
    }),
  };

  function updateForm(e) {
    const { value, name } = e.target;
    setCustomerForm({
      ...customerForm,
      [name]: value,
    });
  }

  function addCustomerHandler() {
    createCustomer(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        close();
        dispatch(actions._getCustomers());
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function updateCustomerHandler() {
    updateCustomer(customer._id, payload)
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        close();
        dispatch(actions._getCustomers());
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function deleteCustomerHandler() {
    setDeleting(true);
    deleteCustomer(customer._id)
      .then(({ data }) => {
        setDeleting(false);
        notify(data.message, "success");
        close();
        dispatch(actions._getCustomers());
      })
      .catch(() => {
        setDeleting(false);
      });
  }

  function submitHandler(e) {
    e.preventDefault();
    setLoading(true);
    // if (customerForm.type?.trim() === "") {
    //   toast.warning("Fill all required fields");
    // }

    if (customer) {
      updateCustomerHandler();
      return;
    }
    addCustomerHandler();
  }

  function clearForm() {
    setCustomerForm(defaultCustomerForm);
    setReadOnly(false);
  }

  useEffect(() => {
    if (customer) {
      for (const [key, value] of Object.entries(customer)) {
        setCustomerForm((prevState) => ({
          ...prevState,
          [key]: value,
          address: customer.location.address,
          city: customer.location.city,
          state: customer.location.state,
          country: customer.location.country,
        }));
      }
      setReadOnly(true);
    }
  }, [customer]);

  useEffect(() => {
    locations.map((location) => {
      if (location.state === customerForm.state) {
        const sort = location.lgas.sort(compare);
        return setCities(sort);
      }
    });

    if (customerForm.state === undefined) {
      setCities(null);
      setCustomerForm({
        ...customerForm,
        state: "",
        city: "",
      });
    }
  }, [customerForm.state]);

  return (
    <Modal
      display={display}
      close={() => {
        clearForm();
        close();
      }}
      styles={styles}
      title={customer ? "Update Customer" : "Add Customer"}
    >
      <form onSubmit={submitHandler}>
        <div className={styles.body}>
          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="first-name">
              First Name
              <span>*</span>
            </label>
            <input
              type="text"
              name="firstName"
              id="first-name"
              value={customerForm.firstName}
              onChange={updateForm}
              disabled={readOnly}
              required
            />
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="last-name">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="last-name"
              value={customerForm.lastName}
              onChange={updateForm}
              disabled={readOnly}
            />
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={customerForm.email}
              onChange={updateForm}
              disabled={readOnly}
            />
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="phone">
              Phone Number
              <span>*</span>
            </label>
            <input
              type="number"
              name="phone"
              id="phone"
              value={customerForm.phone}
              onChange={updateForm}
              disabled={readOnly}
              required
            />
          </fieldset>

          {user.sageEnabled && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="group">Group</label>
              {readOnly && (
                <input
                  type="text"
                  value={customerForm.group}
                  disabled={readOnly}
                />
              )}
              {!readOnly && (
                <Select
                  styles={customStyles()}
                  options={groups}
                  id="group"
                  placeholder=""
                  isClearable
                  value={[
                    {
                      value: customerForm.group,
                      label: customerForm.group?.InterestProfile?.toLowerCase(),
                    },
                  ]}
                  onChange={(e) =>
                    setCustomerForm({
                      ...customerForm,
                      group: e?.value,
                    })
                  }
                  classNamePrefix="react-select"
                />
              )}
            </fieldset>
          )}

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="country">Country</label>
            {readOnly && (
              <input
                type="text"
                value={customerForm.country}
                disabled={readOnly}
              />
            )}
            {!readOnly && (
              <Select
                styles={customStyles()}
                options={[{ value: "nigeria", label: "nigeria" }]}
                id="country"
                placeholder=""
                isClearable
                value={[
                  {
                    value: customerForm.country,
                    label: customerForm.country,
                  },
                ]}
                onChange={(e) =>
                  setCustomerForm({
                    ...customerForm,
                    country: e?.value,
                  })
                }
                classNamePrefix="react-select"
              />
            )}
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="state">State</label>
            {readOnly && (
              <input
                type="text"
                value={customerForm.state}
                disabled={readOnly}
              />
            )}
            {!readOnly && (
              <Select
                styles={customStyles()}
                options={states}
                id="state"
                placeholder=""
                isClearable
                value={[
                  {
                    value: customerForm.state,
                    label: customerForm.state,
                  },
                ]}
                onChange={(e) =>
                  setCustomerForm({
                    ...customerForm,
                    state: e?.value,
                  })
                }
                classNamePrefix="react-select"
              />
            )}
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="city">City</label>
            {readOnly && (
              <input
                type="text"
                value={customerForm.city}
                disabled={readOnly}
              />
            )}
            {!readOnly && (
              <Select
                styles={customStyles()}
                options={_cities}
                id="city"
                placeholder=""
                isClearable
                value={[
                  {
                    value: customerForm.city,
                    label: customerForm.city,
                  },
                ]}
                onChange={(e) =>
                  setCustomerForm({
                    ...customerForm,
                    city: e?.value,
                  })
                }
                isDisabled={customerForm.state === ""}
                classNamePrefix="react-select"
              />
            )}
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="address">Address</label>
            <input
              type="text"
              name="address"
              id="address"
              value={customerForm.address}
              onChange={updateForm}
              disabled={readOnly}
            />
          </fieldset>
        </div>
        <div className={styles.footer}>
          {customer && (
            <button type="button" onClick={deleteCustomerHandler}>
              {deleting ? <ClipLoader color="#ffffff" size={12} /> : "Delete"}
            </button>
          )}
          {!customer && (
            <button
              type="button"
              onClick={() => {
                clearForm();
                close();
              }}
              className={styles.cancel}
            >
              Cancel
            </button>
          )}
          {readOnly && (
            <button
              style={{ width: "8rem" }}
              type="button"
              onClick={() => setReadOnly(false)}
            >
              Edit Customer
            </button>
          )}
          {!readOnly && (
            <button
              style={{
                width: customer ? "10rem" : "8rem",
                backgroundColor: "var(--accent-color)",
                borderColor: "var(--accent-color)",
              }}
            >
              {customer &&
                (loading ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : (
                  "Update Customer"
                ))}
              {!customer &&
                (loading ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : (
                  "Add Customer"
                ))}
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
}
