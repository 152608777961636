import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getStaffs } from "services/Staffs";

export function useStaffsQuery(params?: any, queryOptions?: UseQueryOptions) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/staffs`, { ...params, branchId: _id }],
    queryFn: () => getStaffs({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
}
