import axios from "axios";
import { IQueryParams } from "models/base";
import { IWarehouseResponse } from "models/warehouse";

export function getProducts(params?: any) {
  return axios.get<IWarehouseResponse>(`/warehouse`, {
    params,
  });
}

export function getHistory(params: any) {
  return axios.get(`/warehouse/history`, {
    params,
  });
}

export function createProduct(body: any, params: IQueryParams) {
  return axios.post(`/warehouse`, body, { params });
}

export function updateProduct(itemId: string, body: any, params: IQueryParams) {
  return axios.patch(`/warehouse/${itemId}`, body, { params });
}

export function deleteProduct(itemId: string, params: IQueryParams) {
  return axios.delete(`/warehouse/${itemId}`, { params });
}

export function transferProduct(
  itemId: string,
  body: any,
  params: IQueryParams
) {
  return axios.patch(`/warehouse/${itemId}/transfer`, body, { params });
}

export function exportWarehouse(params: IQueryParams) {
  return axios.get(`/warehouse/export`, { params, responseType: "blob" });
}

export function exportWarehouseToCSV(params: IQueryParams) {
  return axios.get(`/warehouse/export/csv`, { params, responseType: "blob" });
}
