import * as actions from "store/actions";
import * as utils from "helpers";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSettingsQuery } from "./useSettingsQuery";
import { IInventory, IVariant } from "models/inventory";
import { toast } from "react-toastify";
import { v4 } from "uuid";

export function usePOS() {
  const dispatch = useDispatch();
  const { savedOrders, offlineSales, cart } = useSelector((state) => state.pos);
  const { user, branch } = useSelector((state) => state.auth);
  const { data: _settings } = useSettingsQuery();
  const tax = _settings?.data.data[0].tax ?? 0;

  const setMeasurementQuantity = useCallback(
    (product: IInventory & { qty: number; totalPrice: number }) => {
      let updatedCartItems = [...cart.items];
      updatedCartItems = updatedCartItems?.map((_product) =>
        _product._id === product._id
          ? {
              ..._product,
              qty: product.qty,
              totalPrice: product.totalPrice,
            }
          : _product
      );
      const grandTotal = utils.getGrandTotal(
        updatedCartItems,
        cart.delivery?.value?.deliveryFee
      );
      dispatch(
        actions.updateCart({
          ...cart,
          items: updatedCartItems,
          grandTotal,
          tax: calculateTax(grandTotal),
          totalItems: utils.getTotalItems(updatedCartItems),
          totalQty: utils.getTotalQuantity(updatedCartItems),
        })
      );
    },
    [cart, dispatch]
  );

  const increment = useCallback(
    (product) => {
      let updatedCartItems = [...cart.items];
      updatedCartItems = updatedCartItems?.map((_product) => {
        if (
          product._id === _product._id &&
          product.price?.selling === _product.price?.selling
        ) {
          return {
            ..._product,
            qty: utils.removeDecimalIfWhole((_product.qty + 1).toFixed(2)),
            totalPrice: _product.totalPrice + _product.price.selling,
          };
        } else {
          return _product;
        }
      });
      const grandTotal = utils.getGrandTotal(
        updatedCartItems,
        cart.delivery?.value?.deliveryFee
      );
      dispatch(
        actions.updateCart({
          ...cart,
          items: updatedCartItems,
          grandTotal,
          tax: calculateTax(grandTotal),
          totalItems: utils.getTotalItems(updatedCartItems),
          totalQty: utils.getTotalQuantity(updatedCartItems),
        })
      );
    },
    [cart, dispatch]
  );

  const addProductToCart = useCallback(
    (
      product: IInventory | IVariant,
      closeModal: () => void,
      variant?: IVariant,
      variantQty?: number
    ) => {
      if (!cart.items) {
        const updatedCartItems = [
          { ...product, qty: 1, totalPrice: product.price.selling },
        ];

        const grandTotal =
          "type" in product && product.type === "measurement"
            ? product.totalPrice
            : product.price.selling;

        dispatch(
          actions.updateCart({
            ...cart,
            items:
              "type" in product && product.type === "measurement"
                ? [product]
                : updatedCartItems,
            grandTotal,
            tax: calculateTax(grandTotal),
            totalItems: utils.getTotalItems(updatedCartItems),
            totalQty:
              "type" in product && product.type === "measurement"
                ? product.qty
                : utils.getTotalQuantity(updatedCartItems),

            id: v4(),
          })
        );
        closeModal();
        return;
      }
      const productInCart = cart.items.find(
        (_product) =>
          product._id === _product._id &&
          product.price?.selling === _product.price?.selling
      );
      if (productInCart) {
        if ("type" in product && product.type === "measurement") {
          setMeasurementQuantity(product);
          closeModal();
          return;
        }
        increment(product);
      }
      if (!productInCart) {
        const updatedCartItems = [
          ...cart.items,
          {
            ...product,
            qty: 1,
            totalPrice: variant
              ? variant.price.selling * variantQty
              : product.price.selling,
          },
        ];
        const grandTotal = cart.grandTotal + product.price.selling;
        dispatch(
          actions.updateCart({
            ...cart,
            items:
              "type" in product && product.type === "measurement"
                ? [...cart.items, product]
                : updatedCartItems,
            grandTotal,
            tax: calculateTax(grandTotal),
            totalItems: utils.getTotalItems(updatedCartItems),
            totalQty: utils.getTotalQuantity(updatedCartItems),
          })
        );
      }
      closeModal();
    },
    [cart, dispatch, increment, setMeasurementQuantity]
  );

  const decrement = useCallback(
    (product: IInventory | IVariant) => {
      let updatedCartItems = [...cart.items];
      updatedCartItems = updatedCartItems?.map((_product) => {
        if (
          product._id === _product._id &&
          product.price?.selling === _product.price?.selling
        ) {
          let updatedProduct = _product;
          if (_product.qty > 1) {
            updatedProduct = {
              ..._product,
              qty: utils.removeDecimalIfWhole(+(_product.qty - 1).toFixed(2)),
              totalPrice: _product.totalPrice - _product.price.selling,
            };
          }
          return updatedProduct;
        } else {
          return _product;
        }
      });
      const grandTotal = utils.getGrandTotal(
        updatedCartItems,
        cart.delivery?.value?.deliveryFee
      );
      dispatch(
        actions.updateCart({
          ...cart,
          items: updatedCartItems,
          grandTotal,
          tax: calculateTax(grandTotal),
          totalItems: utils.getTotalItems(updatedCartItems),
          totalQty: utils.getTotalQuantity(updatedCartItems),
        })
      );
    },
    [cart, dispatch]
  );

  const removeProduct = useCallback(
    (product: IInventory) => {
      const updatedCartItems = cart.items.filter(
        (_product) =>
          product._id !== _product._id ||
          product.price?.selling !== _product.price?.selling
      );
      if (cart.items?.length === 1) {
        dispatch(actions.clearCart());
        return;
      }
      const grandTotal = utils.getGrandTotal(
        updatedCartItems,
        cart.delivery?.value?.deliveryFee
      );
      dispatch(
        actions.updateCart({
          ...cart,
          items: updatedCartItems,
          grandTotal,
          tax: calculateTax(grandTotal),
          totalItems: utils.getTotalItems(updatedCartItems),
          totalQty: utils.getTotalQuantity(updatedCartItems),
        })
      );
    },
    [cart, dispatch]
  );

  const deleteSavedOrder = useCallback(
    (id: string, closeModal: () => void) => {
      const updatedSavedOrders = savedOrders.filter((order) => order.id !== id);
      if (savedOrders?.length === 1) {
        dispatch(actions.clearSavedOrders());
        closeModal();
        return;
      }
      dispatch(actions.updateSavedOrders([...updatedSavedOrders]));
    },
    [savedOrders, dispatch]
  );

  const newOrder = useCallback(() => {
    dispatch(
      actions.updateCart({
        ...cart,
        items: [],
        grandTotal: 0,
        totalItems: 0,
        totalQty: 0,
        id: v4(),
      })
    );
    dispatch(
      actions.updateCustomer({ value: "guest", label: "walk-in customer" })
    );
    dispatch(actions.updateRider(null));
  }, [cart, dispatch]);

  const saveOrder = useCallback(
    (customerName: string, closeModal: () => void) => {
      if (!savedOrders) {
        dispatch(
          actions.updateSavedOrders([
            {
              ...cart,
              timeStamp: new Date(),
              customer: {
                ...cart.customer,
                label: customerName,
              },
            },
          ])
        );
      }
      if (savedOrders) {
        dispatch(
          actions.updateSavedOrders([
            ...savedOrders,
            {
              ...cart,
              timeStamp: new Date(),
              customer: {
                ...cart.customer,
                label: customerName,
              },
            },
          ])
        );
      }
      toast.success("Order saved successfully", { toastId: "save-order" });
      closeModal();
      dispatch(actions.clearCart());
    },
    [cart, dispatch, savedOrders]
  );

  function addRider(
    details: { rider: any; delivery: any },
    closeModal: () => void
  ) {
    // dispatch(actions.updateRider(details.rider));
    // dispatch(actions.updateDelivery(details.delivery));
    dispatch(
      actions.updateCart({
        ...cart,
        grandTotal: utils.getGrandTotal(
          cart.items,
          details.delivery?.value?.deliveryFee
        ),
        rider: details.rider,
        delivery: details.delivery,
      })
    );
    utils.notify("Rider added for delivery!", "success");
    closeModal();
  }

  function cancelDiscount(setDiscountOptions: (value: boolean) => void) {
    setDiscountOptions(false);
    dispatch(
      actions.updateCart({
        ...cart,
        grandTotalAfterDiscount: null,
        discountAmount: null,
        discountPercent: null,
      })
    );
  }

  function handleDiscount(discount: string, discountMode: string) {
    if (cart.grandTotalAfterDiscount) return;
    if (discount > cart.grandTotal) {
      toast.warning("Discount is greater than total amount");
      return;
    }
    if (discountMode === "amount") {
      dispatch(actions.discountAmount(discount));
      return;
    }
    if (discountMode === "percent") {
      dispatch(actions.discountPercent(discount));
    }
  }

  function getAmountPlusTax(): number {
    if (tax && +tax > 0) {
      if (cart.grandTotalAfterDiscount) {
        const number = cart.grandTotalAfterDiscount;
        return number + cart.tax;
      } else {
        const number = cart.grandTotal;
        return number + cart.tax;
      }
    }
    return cart.grandTotalAfterDiscount || cart.grandTotal;
  }

  function calculateTax(grandTotal: number) {
    dispatch(
      actions.updateCart({
        ...cart,
        tax: +((grandTotal / 100) * +tax!).toFixed(1),
      })
    );
    return +((grandTotal / 100) * +tax!).toFixed(1);
  }

  function getRequestPayload(
    paymentMethods: {
      _id: string;
      type: string;
      details: string;
      amount: number;
    }[],
    customer?: string,
    deliveryDetails?: any
  ) {
    return {
      items: cart.items?.map((item) => {
        return {
          _id: item._id,
          name: item.name,
          price: item.price.selling,
          cost: item.price.cost,
          quantity: item.qty,
        };
      }),
      status: "pending",
      amount: getAmountPlusTax(),
      staff: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.account.role,
      },
      createdAt: new Date().toISOString(),
      tax: cart.tax,
      discountAmount: cart.grandTotalAfterDiscount
        ? cart.grandTotal - cart.grandTotalAfterDiscount
        : null,
      branchId: branch?._id,
      paymentMethods,
      customer,
      deliveryDetails,
    };
  }

  const saveOfflineSale = useCallback(
    (
      payload: any,
      type: "guest" | "customer" | "delivery",
      setPrintReceipt: (value: boolean) => void
    ) => {
      if (!offlineSales) {
        dispatch(
          actions.updateOfflineSales([
            {
              ...payload,
              offlineId: v4(),
              customer: cart.customer,
              timeStamp: utils.timeStamp(),
              type,
            },
          ])
        );
      }
      if (offlineSales) {
        dispatch(
          actions.updateOfflineSales([
            ...offlineSales,
            {
              ...payload,
              offlineId: v4(),
              customer: cart.customer,
              timeStamp: utils.timeStamp(),
              type,
            },
          ])
        );
      }
      toast.info("Sale saved offline");
      setPrintReceipt(true);
    },
    [cart.customer, dispatch, offlineSales]
  );

  function deleteOfflineSale(offlineId: string, closeModal: () => void) {
    const updatedOfflineSales = offlineSales.filter(
      (sale) => sale.offlineId !== offlineId
    );
    if (offlineSales?.length === 1) {
      dispatch(actions.clearOfflineSales());
      closeModal();
      return;
    }
    dispatch(actions.updateOfflineSales([...updatedOfflineSales]));
  }

  return {
    addProductToCart,
    increment,
    decrement,
    removeProduct,
    calculateTax,
    deleteSavedOrder,
    newOrder,
    saveOrder,
    addRider,
    cancelDiscount,
    handleDiscount,
    getAmountPlusTax,
    getRequestPayload,
    saveOfflineSale,
    deleteOfflineSale,
    setMeasurementQuantity,
  };
}
