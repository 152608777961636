import * as types from "../types";

const INITIAL_STATE = {
  products: null,
  productsCopy: null,
  error: false,
  history: {
    data: null,
    dataCopy: null,
    error: false,
  },
};

export default function warehouse(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.WAREHOUSE_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.WAREHOUSE_HISTORY_ERROR:
      return {
        ...state,
        history: {
          ...state.history,
          error: action.bool,
        },
      };
    case types.CLEAR_WAREHOUSE_PRODUCTS:
      return {
        ...state,
        products: null,
        productsCopy: null,
        error: false,
      };
    case types.WAREHOUSE_PRODUCTS_LOADED:
      return {
        ...state,
        products: action.products,
        productsCopy: action.products,
      };
    case types.WAREHOUSE_HISTORY_LOADED:
      return {
        ...state,
        history: {
          ...state.history,
          data: action.history,
          dataCopy: action.history,
        },
      };
    case types.UPDATE_WAREHOUSE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case types.UPDATE_WAREHOUSE_HISTORY:
      return {
        ...state,
        history: {
          ...state.history,
          data: action.history,
        },
      };
    default:
      return state;
  }
}
