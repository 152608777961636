import React from "react";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  display: boolean;
  close: () => void;
  primaryAction: () => void;
  text?: string;
};

export default function ConfirmationDialog({
  text = "Are you sure",
  display,
  close,
  primaryAction,
}: Props) {
  return (
    <AnimatePresence>
      {display && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full p-4 bg-white"
        >
          <h1 className="mb-3 text-lg text-center text-gray-500">{text}</h1>
          <div className="flex items-center justify-center gap-4">
            <button
              type="button"
              className="flex items-center justify-center w-16 h-6 text-sm text-gray-500 border border-gray-400"
              onClick={close}
            >
              No
            </button>
            <button
              type="button"
              onClick={primaryAction}
              className="flex items-center justify-center w-16 h-6 text-sm text-white bg-blue-500 border"
            >
              Yes
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
