import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IQueryParams } from "models/base";
import { getSageCustomerGroups } from "services/sage";

export function useSageCustomerGroups(params?: IQueryParams) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/customer/sage/groups`, { ...params, branchId: _id }],
    queryFn: () => getSageCustomerGroups({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}
