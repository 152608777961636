import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import Button from "components/Elements/Button";
import Logo from "components/Elements/Logo";
import Slideshow from "components/Slideshow";
import styles from "./PasswordValidate.module.scss";
import useWindowDimension from "hooks/useWindowDimension";
import { useParams } from 'react-router-dom';
import { passwordValidate } from "services/auth";

export default function PasswordValidate() {
  const { width } = useWindowDimension();
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false)

const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const newPassword = { password, confirmPassword }
    await passwordValidate(id, newPassword)
    setLoading(false)
  }

  return (
    <div className={styles.container}>
      <Logo />
      <form onSubmit={handleSubmit}>
        <h1>New Password</h1>
        <fieldset>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </fieldset>
        <Button>
          {loading ? <ClipLoader size={12} color="#ffffff" /> : "Continue"}
        </Button>
      </form>
      {width > 1045 && (
        <div>
          <Slideshow />
        </div>
      )}
    </div>
  );
}
