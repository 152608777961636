import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosErrorNotifier, notify } from "./helpers";
import { invalidSession } from "./store/actions/auth";
import { online, offline } from "./store/actions/network";
import { ErrorBoundary } from "react-error-boundary";
import { OfflineIcon } from "./assets/svg/icons";
import axios from "axios";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./views/Auth/Login";
import Registration from "./views/Auth/Registration";
import AccountSettings from "./views/Auth/AccountSettings";
import PasswordRecovery from "./views/Auth/PasswordRecovery";
import PasswordValidate from "./views/Auth/PasswordValidate";
import NotFound from "./views/NotFound";
import Dashboard from "./views/Admin/Dashboard";
import POS from "./views/Admin/POS";
import Sales from "./views/Admin/Sales";
import Report from "./views/Admin/Sales/Report";
import Returns from "views/Admin/Sales/Returns";
import Invoices from "views/Admin/Invoices";
import Error from "views/Error";
import PrePageLoader from "components/PrePageLoader/PrePageLoader";
import "./App.scss";

export default function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { network } = useSelector((state) => state);
  const PriceUpdateHistory = lazy(
    () => import("views/Admin/PriceUpdateHistory")
  );
  const QuantityUpdateHistory = lazy(
    () => import("views/Admin/QuantityUpdateHistory")
  );
  const Warehouse = lazy(() => import("views/Admin/Warehouse"));
  const WarehouseHistory = lazy(() => import("views/Admin/Warehouse/History"));
  const Suppliers = lazy(() => import("views/Admin/Suppliers"));
  const Reports = lazy(() => import("views/Admin/Reports"));
  const Reservations = lazy(() => import("views/Admin/Reservations"));
  const ReturnOrders = lazy(() => import("views/Admin/ReturnOrders"));
  const PriceChecker = lazy(() => import("views/Admin/PriceChecker"));
  const Customers = lazy(() => import("views/Admin/Customers"));
  const Expenses = lazy(() => import("views/Admin/Expenses"));
  const UserSettings = lazy(() => import("views/Admin/UserSettings"));
  const Products = lazy(() => import("views/Admin/Products"));
  const Riders = lazy(() => import("views/Admin/Riders"));
  const RidersHistory = lazy(() => import("views/Admin/Riders/History"));
  const Loyalty = lazy(() => import("views/Admin/Loyalty"));
  const Purchases = lazy(() => import("views/Admin/Purchases"));
  const PurchasesHistory = lazy(() => import("views/Admin/Purchases/History"));
  const Logs = lazy(() => import("views/Admin/Logs"));
  const Account = lazy(() => import("views/Admin/Account"));

  axios.interceptors.response.use(
    function (response) {
      if (!network.online) dispatch(online());
      return response;
    },
    function (error) {
      if (error?.response?.data?.message === "jwt expired") {
        dispatch(invalidSession());
      }
      if (error?.response?.data?.message) {
        axiosErrorNotifier(error);
      }
      if (
        !error?.response?.data?.message &&
        error.request.responseType !== "blob"
      ) {
        if (network.online) dispatch(offline());
      }
      if (error.request.responseType === "blob") {
        notify(error?.response?.statusText, "error", "axios-error");
      }
      return Promise.reject(error);
    }
  );

  // axios.defaults.timeout = 5000;
  axios.defaults.baseURL =
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_VERCEL_ENV === "preview"
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.NODE_ENV === "production" ||
        process.env.REACT_APP_VERCEL_ENV === "production"
      ? process.env.REACT_APP_LIVE_SERVER
      : "";
  axios.defaults.headers.common["Authorization"] = token;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return (
    <>
      {!network.online && (
        <div className="flex items-center justify-center gap-4 fixed left-1/2 -translate-x-1/2 my-8 bg-[#dee2e6] w-72 h-12 z-[1042]">
          <OfflineIcon width={20} /> <h6>Can't connect to the server</h6>
        </div>
      )}

      <ErrorBoundary FallbackComponent={Error}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/password/recovery">
              <PasswordRecovery />
            </Route>
            <Route path="/password/validate/:id">
              <PasswordValidate />
            </Route>
            <Route path="/register">
              <Registration />
            </Route>
            <Route path="/account-settings">
              <AccountSettings />
            </Route>
            <ProtectedRoute exact path="/dashboard" title="dashboard">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute exact path="/pos">
              <POS />
            </ProtectedRoute>
            <ProtectedRoute exact path="/sales" title="todays's sales">
              <Sales />
            </ProtectedRoute>
            <ProtectedRoute exact path="/sales/returns" title="sales returns">
              <Returns />
            </ProtectedRoute>
            <ProtectedRoute exact path="/sales/report" title="sales report">
              <Report />
            </ProtectedRoute>
            <ProtectedRoute exact path="/invoices" title="invoices">
              <Invoices />
            </ProtectedRoute>
            <ProtectedRoute exact path="/" title="dashboard">
              <Dashboard />
            </ProtectedRoute>
            <Suspense fallback={<PrePageLoader />}>
              <ProtectedRoute
                exact
                path="/price-update-history"
                title="price update history"
              >
                <PriceUpdateHistory />
              </ProtectedRoute>
              <ProtectedRoute
                exact
                path="/quantity-update-history"
                title="quantity update history"
              >
                <QuantityUpdateHistory />
              </ProtectedRoute>
              <ProtectedRoute
                exact
                path="/riders/history"
                title="riders history"
              >
                <RidersHistory />
              </ProtectedRoute>
              <ProtectedRoute
                exact
                path="/purchases/history"
                title="purchases history"
              >
                <PurchasesHistory />
              </ProtectedRoute>
              <ProtectedRoute exact path="/warehouse" title="warehouse">
                <Warehouse />
              </ProtectedRoute>
              <ProtectedRoute
                exact
                path="/warehouse/history"
                title="warehouse history"
              >
                <WarehouseHistory />
              </ProtectedRoute>
              <ProtectedRoute exact path="/suppliers" title="suppliers">
                <Suppliers />
              </ProtectedRoute>
              <ProtectedRoute exact path="/reports" title="reports">
                <Reports />
              </ProtectedRoute>
              <ProtectedRoute exact path="/reservations" title="reservations">
                <Reservations />
              </ProtectedRoute>
              <ProtectedRoute exact path="/return-orders" title="return orders">
                <ReturnOrders />
              </ProtectedRoute>
              <ProtectedRoute exact path="/price-checker">
                <PriceChecker />
              </ProtectedRoute>
              <ProtectedRoute exact path="/customers" title="customers">
                <Customers />
              </ProtectedRoute>
              <ProtectedRoute exact path="/expenses" title="expenses">
                <Expenses />
              </ProtectedRoute>
              <ProtectedRoute exact path="/settings" title="settings">
                <UserSettings />
              </ProtectedRoute>
              <ProtectedRoute exact path="/inventory" title="inventory">
                <Products />
              </ProtectedRoute>
              <ProtectedRoute exact path="/riders" title="riders">
                <Riders />
              </ProtectedRoute>
              <ProtectedRoute exact path="/loyalty" title="loyalty">
                <Loyalty />
              </ProtectedRoute>
              <ProtectedRoute exact path="/logs" title="logs">
                <Logs />
              </ProtectedRoute>
              <ProtectedRoute exact path="/account" title="account">
                <Account />
              </ProtectedRoute>
              <ProtectedRoute exact path="/purchases" title="purchases">
                <Purchases />
              </ProtectedRoute>
            </Suspense>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    </>
  );
}
