import React, { useMemo, useState } from "react";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  ChevronUp,
  ChevronDown,
  StepBackward,
  StepBackward2,
  StepForward,
  StepForward2,
} from "assets/svg/icons";
import { capitalizeFirstLetter, isValidDate } from "helpers";
import styles from "./Table.module.scss";

export default function Table(props) {
  const { columns, data = [], getRowProps = defaultPropGetter } = props || {};
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: 20 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  function defaultPropGetter() {}
  function dateBetweenFilterFn(rows, id, filterValues) {
    const startDate = new Date(filterValues[0]);
    const endDate = new Date(filterValues[1]);
    if (!isValidDate(filterValues[0]) || !isValidDate(filterValues[1]))
      return rows;
    return rows.filter((row) => {
      const date = new Date(row.values[id]);
      if (filterValues.length === 0) return rows;
      return date >= startDate && date <= endDate.setUTCHours(22, 59, 59, 999);
    });
  }
  dateBetweenFilterFn.autoRemove = (value) => !value;

  return (
    <>
      <div className={styles.table}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th key={index}>
                    <p
                      className="mb-2"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDown />
                        ) : (
                          <ChevronUp />
                        )
                      ) : (
                        <span className="inline-block w-4 h-4 ml-[0.2rem]" />
                      )}
                    </p>
                    {props.data?.length > 0 &&
                      (column.canFilter ? (
                        column.render("Filter")
                      ) : (
                        <div className="h-8" />
                      ))}
                  </th>
                ))}
              </tr>
            ))}
            {preGlobalFilteredRows?.length > 1 && (
              <tr className="bg-white">
                <th colSpan={visibleColumns?.length}>
                  {preGlobalFilteredRows?.length > 1 && (
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  )}
                </th>
              </tr>
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {props.data?.length === 0 && (
              <tr>
                <td colSpan={100} className="text-center">
                  <svg
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto"
                  >
                    <g
                      transform="translate(0 1)"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <ellipse
                        fill="#f5f5f5"
                        cx="32"
                        cy="33"
                        rx="32"
                        ry="7"
                      ></ellipse>
                      <g fill-rule="nonzero" stroke="#d9d9d9">
                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                        <path
                          d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                          fill="#fafafa"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <span className="text-gray-400">No Data</span>
                </td>
              </tr>
            )}
            {props.data?.length > 0 &&
              (preGlobalFilteredRows?.length === 0 ||
                (globalFilter && page.length === 0)) && (
                <tr>
                  <td colSpan={100} className="text-center">
                    <svg
                      width="64"
                      height="41"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto"
                    >
                      <g
                        transform="translate(0 1)"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <ellipse
                          fill="#f5f5f5"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        ></ellipse>
                        <g fill-rule="nonzero" stroke="#d9d9d9">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            fill="#fafafa"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <span className="text-gray-400">Not Found</span>
                  </td>
                </tr>
              )}
            {props.data?.length > 0 &&
              !props.noPagination &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            {props.data?.length > 0 &&
              props.noPagination &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {!props.noPagination && (
        <form className={styles.pagination}>
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <StepBackward2 />
          </button>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <StepBackward />
          </button>
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <StepForward />
          </button>
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <StepForward2 />
          </button>
          <fieldset>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions?.length}
            </strong>
          </fieldset>
          <fieldset>
            <label htmlFor="">Go to page:</label>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="">Rows per page:</label>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </fieldset>
        </form>
      )}
    </>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
      />
    </form>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <>
      <select
        value={filterValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        className="capitalize"
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
}

export function PaymentMethodColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(
        row.original.saleDetails.paymentMethods
          .map((paymentMethod) => paymentMethod.type)
          .join(", ")
      );
    });
    return [...options.values()];
  }, [preFilteredRows]);

  return (
    <>
      <select
        value={filterValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        className="capitalize"
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {capitalizeFirstLetter(option)}
          </option>
        ))}
      </select>
    </>
  );
}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = useMemo(() => {
    let min = new Date(preFilteredRows[0]?.values[id]);
    let max = new Date(preFilteredRows[0]?.values[id]);
    preFilteredRows.forEach((row) => {
      min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min;
      max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max;
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className="flex items-center gap-2 text-[#666666] font-normal">
      <input
        type="date"
        value={filterValue[0] || ""}
        className="py-[0.3rem] px-[0.5rem] text-[0.8rem] w-[7rem]"
        // min={min.toISOString().slice(0, 10)}
        // max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const date = e.target.value;
          setFilter((old = []) => [date ? date : undefined, old[1]]);
        }}
      />
      <span>to</span>
      <input
        type="date"
        value={filterValue[1] || ""}
        className="py-[0.3rem] px-[0.5rem] text-[0.8rem] w-[7rem]"
        // min={min.toISOString().slice(0, 10)}
        // max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const date = e.target.value;
          setFilter((old = []) => [old[0], date ? date : undefined]);
        }}
      />
    </div>
  );
}
