import { ISalesResponse } from "models/sales";
import { IQueryParams } from "models/base";
import axios from "axios";

export function getSales(params?: IQueryParams) {
  return axios.get<ISalesResponse>(`/sale`, { params });
}

export function getSalesReport(params?: IQueryParams) {
  return axios.get<ISalesResponse>(`/sale/reports`, { params });
}

export function createWalkInSale(payload: any, params: IQueryParams) {
  return axios.post(`/sale/walkin`, payload, { params });
}

export function createCustomerSale(payload: any, params: IQueryParams) {
  return axios.post(`/sale/order`, payload, { params });
}

export function createDeliverySale(payload: any, params: IQueryParams) {
  return axios.post(`/sale/delivery`, payload, { params });
}

export function updateSale(saleId: string, payload: any) {
  return axios.patch(`/sale/${saleId}`, payload);
}

export function returnItems(
  saleId: string,
  payload: any,
  params: IQueryParams
) {
  return axios.post(`/sale/return/${saleId}`, payload, { params });
}

export function getReturns(params?: IQueryParams) {
  return axios.get(`/sale/returns`, { params });
}

export function sendReceipt(customerId: string) {
  return axios.post(`/receipt/send/${customerId}`);
}

export function exportSales(params?: IQueryParams) {
  return axios.get(`/v2/sale/export`, { params, responseType: "blob" });
}

export function exportSalesToCSV(params?: IQueryParams) {
  return axios.get(`/v2/sale/export/csv`, { params, responseType: "blob" });
}

export function deleteSale(
  saleId: string,
  password: string,
  params?: IQueryParams
) {
  return axios.delete<ISalesResponse>(`/sale/${saleId}`, {
    data: { password },
    params,
  });
}
