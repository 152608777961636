import axios from "axios";
import {
  ICreateBusinessPayload,
  ILoginPayload,
  ILoginResponse,
  IPasswordRecoveryPayload,
  IPasswordValidatePayload,
  IUpdatePasswordPayload,
  IValidateUser,
} from "models/auth";

export function loginBusiness(payload: ILoginPayload) {
  return axios.post<ILoginResponse>(`/auth/login/business`, payload);
}

export function loginCustomer(payload: ILoginPayload) {
  return axios.post(`/auth/login/customer`, payload);
}

export function loginStaff(payload: ILoginPayload) {
  return axios.post<ILoginResponse>(`/auth/login/staff`, payload);
}

export function createBusiness(payload: ICreateBusinessPayload) {
  return axios.post(`/auth/signup/business`, payload);
}

export function updatePassword(payload: IUpdatePasswordPayload) {
  return axios.patch(`/auth/password/update/business`, payload);
}

export function passwordRecovery(payload: IPasswordRecoveryPayload) {
  return axios.post(`/auth/password/reset/business`, payload);
}

export function passwordValidate(
  token: string,
  payload: IPasswordValidatePayload
) {
  return axios.post(`/auth/password/validate`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function validateUser(payload: IValidateUser) {
  return axios.post(`/auth/password/validate-user`, payload);
}
