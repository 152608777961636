import * as types from "../types";
import { getLogs, getAdminData } from "services/business";

export function logsLoaded(payload) {
  return {
    type: types.LOGS_LOADED,
    logs: payload,
  };
}

export function logsError(bool) {
  return {
    type: types.LOGS_ERROR,
    bool,
  };
}

export function adminDataLoaded(payload) {
  return {
    type: types.ADMIN_DATA_LOADED,
    adminData: payload,
  };
}

export function adminDataError(bool) {
  return {
    type: types.ADMIN_DATA_ERROR,
    bool,
  };
}

export function _getLogs() {
  return (dispatch) => {
    dispatch(logsError(false));
    getLogs()
      .then((response) => {
        dispatch(logsLoaded(response.data.data.data));
      })
      .catch(() => {
        dispatch(logsError(true));
        dispatch(logsLoaded(null));
      });
  };
}

export function _getAdminData() {
  return (dispatch) => {
    dispatch(adminDataError(false));
    getAdminData()
      .then((response) => {
        dispatch(adminDataLoaded(response.data.data));
      })
      .catch(() => {
        dispatch(adminDataError(true));
        dispatch(adminDataLoaded(null));
      });
  };
}
