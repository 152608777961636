import * as types from "../types";

const INITIAL_STATE = {
  riders: null,
  ridersCopy: null,
  error: false,
};

export default function riders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.RIDERS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_RIDERS:
      return {
        riders: null,
        ridersCopy: null,
        error: false,
      };
    case types.RIDERS_LOADED:
      return {
        ...state,
        riders: action.riders,
        ridersCopy: action.riders,
      };
    case types.UPDATE_RIDERS:
      return {
        ...state,
        riders: action.riders,
      };
    default:
      return state;
  }
}
