import * as types from "../types";

const INITIAL_STATE = {
  sales: null,
  salesCopy: null,
  todaysSales: null,
  error: false,
  returns: {
    data: null,
    dataCopy: null,
    error: false,
  },
};

export default function sales(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SALES_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.SALES_RETURNS_ERROR:
      return {
        ...state,
        returns: {
          ...state.returns,
          error: action.bool,
        },
      };
    case types.CLEAR_SALES:
      return {
        sales: null,
        salesCopy: null,
        error: false,
      };
    case types.SALES_LOADED:
      return {
        ...state,
        sales: action.sales,
        salesCopy: action.sales,
      };
    case types.SALES_RETURNS_LOADED:
      return {
        ...state,
        returns: {
          ...state.returns,
          data: action.returns,
          dataCopy: action.returns,
        },
      };
    case types.UPDATE_SALES:
      return {
        ...state,
        sales: action.sales,
      };
    case types.UPDATE_SALES_RETURNS:
      return {
        ...state,
        returns: {
          ...state.returns,
          data: action.returns,
        },
      };
    default:
      return state;
  }
}
