import * as types from "../types";

const INITIAL_STATE = {
  suppliers: null,
  suppliersCopy: null,
  error: false,
};

export default function suppliers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SUPPLIERS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_SUPPLIERS:
      return {
        suppliers: null,
        suppliersCopy: null,
        error: false,
      };
    case types.SUPPLIERS_LOADED:
      return {
        ...state,
        suppliers: action.suppliers,
        suppliersCopy: action.suppliers,
      };
    case types.UPDATE_SUPPLIERS:
      return {
        ...state,
        suppliers: action.suppliers,
      };
    default:
      return state;
  }
}
