import axios from "axios";
import { IQueryParams } from "models/base";
import {
  ICreatePurchasePayload,
  IPurchasesHistoryResponse,
  IPurchase,
  IPurchasesResponse,
} from "models/purchases";

export function getPurchases(params: IQueryParams) {
  return axios.get<IPurchasesResponse>(`/purchase`, {
    params,
  });
}

export function getPurchasesHistory(params: IQueryParams) {
  return axios.get<IPurchasesHistoryResponse>(`/purchase/history`, {
    params,
  });
}

export function createPurchase(
  supplierId: string,
  payload: ICreatePurchasePayload,
  params: IQueryParams
) {
  return axios.post(`/purchase/${supplierId}`, payload, { params });
}

export function updatePurchase(supplierId: string, payload: IPurchase) {
  return axios.patch(`/purchase/${supplierId}`, payload);
}

export function deletePurchase(purchaseId: string) {
  return axios.delete(`/purchase/${purchaseId}`);
}
