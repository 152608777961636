import React, { useState, useMemo } from "react";
import { useInvoicesQuery } from "hooks/useInvoicesQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { toast } from "react-toastify";
import classNames from "classnames";
import { SelectColumnFilter, DateRangeColumnFilter } from "components/Table";
import {
  capitalizeFirstLetter,
  convertUtc,
  formatNumber,
  truncateString,
} from "helpers";
import AddInvoice from "./AddInvoice";
import Button from "components/Elements/Button";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import styles from "assets/styles/Pages.module.scss";
import Table from "components/Table/Table";

export default function Invoices() {
  const { data: settings } = useSettingsQuery();
  const { data: invoices, isLoading, isError } = useInvoicesQuery();
  const [modalDisplay, setModalDisplay] = useState(false);
  const [reservation, setInvoice] = useState(null);
  const currency = settings?.data.data[0].currency;

  const data = useMemo(
    () => (!invoices ? [] : [...invoices.data.data].reverse()),
    [invoices]
  );

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_originalRow: any, rowIndex: any) => rowIndex + 1,
        disableFilters: true,
      },
      {
        Header: "Ref",
        accessor: "sale.saleRef",
        Cell: ({ value }) => <span>{truncateString(value, 12)}</span>,
        disableFilters: true,
      },
      {
        Header: "Items",
        accessor: (originalRow) => (
          <span>{`${formatNumber(
            originalRow.sale.items.length
          )} Item(s)`}</span>
        ),
        disableFilters: true,
      },
      {
        Header: "Total Amount",
        accessor: (originalRow) => originalRow.amount.saleAmount,
        Cell: ({ value }) => `${currency?.symbol || ""}${formatNumber(value)}`,
        disableFilters: true,
      },
      {
        Header: "Amount Paid",
        accessor: (originalRow) => originalRow.amount.amountPaid,
        Cell: ({ value }) => `${currency?.symbol || ""}${formatNumber(value)}`,
        disableFilters: true,
      },
      {
        Header: "Balance",
        accessor: (originalRow) => originalRow.amount.balance,
        Cell: ({ value }) => `${currency?.symbol || ""}${formatNumber(value)}`,
        disableFilters: true,
      },
      {
        Header: "Customer",
        accessor: (originalRow) =>
          truncateString(
            `${originalRow.customer.firstName} ${originalRow.customer.lastName}`,
            15
          ),
        disableFilters: true,
      },
      {
        Header: "Sold By",
        accessor: (originalRow) =>
          truncateString(
            `${originalRow.staff.firstName} ${originalRow.staff.lastName}`,
            15
          ),
        disableFilters: true,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }: any) => convertUtc(value),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
      },
      //   {
      //     Header: "Actions",
      //     Cell: ({ cell }: { cell: any }) => (
      //       <div
      //         className="relative flex"
      //         onClick={(e: React.MouseEvent<HTMLElement>) => {
      //           e.stopPropagation();
      //         }}
      //       >
      //         <ActionDropdown
      //           items={[
      //             {
      //               title:
      //                 cell.row.original.status === "complete"
      //                   ? "Completed"
      //                   : "Complete Reservation",
      //               function: () => completeInvoice(cell.row.original),
      //             },
      //           ]}
      //         />
      //       </div>
      //     ),
      //   },
    ],
    []
  );

  //   function completeInvoice(reservation: any) {
  //     if (reservation.status === "complete") {
  //       toast.info("Reservation is already complete!", {
  //         toastId: "reservation-is-already-complete",
  //       });
  //       return;
  //     }
  //     toast.promise(
  //       updateReservation(reservation._id, {
  //         ...reservation,
  //         status: "complete",
  //       }),
  //       {
  //         pending: "Completing Reservation...",
  //         success: {
  //           render() {
  //             refetch();
  //             return "Reservation completed!";
  //           },
  //         },
  //       }
  //     );
  //   }

  return (
    <>
      <div
        className={styles.container}
        style={data?.length > 0 ? { minHeight: "90%" } : { height: "80%" }}
      >
        <div className={styles.buttons}>
          {/* <Button>Print Bar Codes</Button> */}
          <Button onClick={() => setModalDisplay(true)}>Add Invoice</Button>
        </div>
        {isLoading && <Loader />}
        {isError && <ErrorMessage message="An error occurred" />}
        {invoices && (
          <>
            <h6>Total Invoices: {data?.length}</h6>
            <Table
              data={data}
              columns={columns}
              getRowProps={(row: any) => ({
                onClick: () => {
                  setModalDisplay(true);
                  setInvoice(row.cells[0].row.original);
                },
              })}
            />
          </>
        )}
      </div>
      <AddInvoice
        display={modalDisplay}
        close={() => {
          setModalDisplay(false);
          setInvoice(null);
        }}
        reservation={reservation}
      />
    </>
  );
}
