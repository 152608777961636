import React from "react";

export function getNavLinks(permissions: any) {
  return [
    {
      title: "dashboard",
      icon: <i className="mdi mdi-view-dashboard menu-icon" />,
      path: "/dashboard",
      allowed: false,
    },
    {
      title: "point of sales",
      icon: <i className="mdi mdi-point-of-sale menu-icon" />,
      path: "/pos",
      allowed: true,
    },
    {
      title: "sales",
      icon: <i className="mdi mdi-shopping menu-icon" />,
      path: "/sales",
      allowed: permissions?.sales.view,
    },
    {
      title: "inventory",
      icon: <i className="mdi mdi-cart-outline menu-icon" />,
      path: "/inventory",
      allowed: permissions?.inventory.view,
    },
    {
      title: "Price update history",
      icon: <i className="mdi mdi-history menu-icon" />,
      path: "/price-update-history",
      allowed: false,
    },
    {
      title: "Quantity update history",
      icon: <i className="mdi mdi-history menu-icon" />,
      path: "/quantity-update-history",
      allowed: false,
    },
    {
      title: "warehouse",
      icon: <i className="mdi mdi-warehouse menu-icon" />,
      path: "/warehouse",
      allowed: permissions?.warehouse.view,
    },
    {
      title: "suppliers",
      icon: <i className="mdi mdi-truck menu-icon" />,
      path: "/suppliers",
      allowed: permissions?.suppliers.view,
    },
    {
      title: "purchases",
      icon: <i className="mdi mdi-cash-multiple menu-icon" />,
      path: "/purchases",
      allowed: permissions?.purchases.view,
    },
    {
      title: "customers",
      icon: <i className="mdi mdi-account-multiple menu-icon" />,
      path: "/customers",
      allowed: permissions?.customers.view,
    },
    {
      title: "riders",
      icon: <i className="mdi mdi-bike menu-icon" />,
      path: "/riders",
      allowed: permissions?.riders.view,
    },
    {
      title: "expenses",
      icon: <i className="mdi mdi-finance menu-icon" />,
      path: "/expenses",
      allowed: permissions?.expenses.view,
    },
    {
      title: "reservations",
      icon: <i className="mdi mdi-content-paste menu-icon" />,
      path: "/reservations",
      allowed: permissions?.reservations.view,
    },
    // {
    //   title: "reports",
    //   icon: <i className="mdi mdi-file-table menu-icon" />,
    //   path: "/reports",
    //   allowed: permissions?.reports.view,
    // },
    {
      title: "price checker",
      icon: <i className="mdi mdi-cash-usd-outline menu-icon" />,
      path: "/price-checker",
      allowed: true,
    },
    {
      title: "account",
      icon: <i className="mdi mdi-account-outline menu-icon" />,
      path: "/account",
      allowed: false,
    },
    {
      title: "logs",
      icon: <i className="mdi mdi-file-document-box-outline menu-icon" />,
      path: "/logs",
      allowed: false,
    },
    {
      title: "loyalty",
      icon: <i className="mdi mdi-file-document-box-outline menu-icon" />,
      path: "/loyalty",
      allowed: false,
    },
    {
      title: "settings",
      icon: <i className="mdi mdi-settings menu-icon" />,
      path: "/settings",
      allowed: false,
    },
  ];
}
