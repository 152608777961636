import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  FormEvent,
  memo,
} from "react";
import * as actions from "store/actions";
import * as utils from "helpers";
import * as icons from "assets/svg/icons";
import * as services from "services/Sale";
import { useReactToPrint } from "react-to-print";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useInventoryQuery } from "hooks/useInventoryQuery";
import { useDispatch, useSelector } from "react-redux";
import { useDeliveriesQuery } from "hooks/useDeliveriesQuery";
import { usePOS } from "hooks/usePOS";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import { redeemCoupon } from "services/coupons";
import { validateUser } from "services/auth";
import { ClipLoader } from "react-spinners";
import { IPaymentMethod } from "models/settings";
import { ICreateSalePayload, IOfflineSalePayload, ISale } from "models/sales";
import { ModalTitles } from "../POS";
import useNetworkStatus from "hooks/useNetworkStatus";
import Receipt from "components/Receipt";
import Modal from "components/Elements/Modal";
import Select from "react-select";
import CheckoutHeader from "./Elements/CheckoutHeader";
import Actions from "./Elements/Actions";
import Footer from "./Elements/Footer";
import classNames from "classnames";
import styles from "./ControlPanel.module.scss";

type Props = {
  title: string;
  product: any;
  display: boolean;
  close: () => void;
  variant: any;
  variants: any;
  setVariant: any;
  addVariantToCart: any;
  saveOrder: any;
  deleteSavedOrder: any;
};

export default memo(function Index({
  title,
  product,
  display,
  close,
  variant,
  variants,
  setVariant,
  addVariantToCart,
  saveOrder,
  deleteSavedOrder,
}: Props) {
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const { online } = useNetworkStatus();
  const { network } = useSelector((state) => state);
  const { user, branch } = useSelector((state) => state.auth);
  const { cart } = useSelector((state) => state.pos);
  const { savedOrders } = useSelector((state) => state.pos);
  const { offlineSales } = useSelector((state) => state.pos);
  const { riders } = useSelector((state) => state.riders);
  const {
    addProductToCart,
    addRider,
    cancelDiscount,
    handleDiscount,
    getAmountPlusTax,
    getRequestPayload,
    saveOfflineSale,
    deleteOfflineSale,
  } = usePOS();
  const { refetch: refetchProducts } = useInventoryQuery();
  const { data: _settings } = useSettingsQuery();
  const { data: _deliveries } = useDeliveriesQuery();
  const settings = _settings?.data.data[0];
  const deliveries = _deliveries?.data.data;
  const [loading, setLoading] = useState(false);
  const [priceLevel, setPriceLevel] = useState(null);
  const [customerName, setCustomerName] = useState(cart.customer?.label);
  const [couponCode, setCouponCode] = useState("");
  const [couponDialog, setCouponDialog] = useState(false);
  const [discountDialog, setDiscountDialog] = useState(false);
  const [discountOptions, setDiscountOptions] = useState(false);
  const [discountMode, setDiscountMode] = useState("");
  const [measurementQuantity, setMeasurementQuantity] = useState("");
  const [collectedAmount, setCollectedAmount] = useState<string | number>("");
  const [password, setPassword] = useState("");
  const [sale, setSale] = useState<ISale | null>(null);
  const [balance, setBalance] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState<
    (IPaymentMethod & { amount: number })[] | []
  >([]);
  const [points, setPoints] = useState(false);
  const [ContinueSplitPayment, setContinueSplitPayment] = useState(false);
  const [printReceipt, setPrintReceipt] = useState(false);
  const [pendingSale, setPendingSale] = useState(false);
  const [savedOrder, setSavedOrder] = useState(null);
  const [offlineSale, setOfflineSale] = useState(null);
  const [removeSavedOrder, setRemoveSavedOrder] = useState(false);
  const [discount, setDiscount] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [riderDetails, setRiderDetails] = useState({
    rider: null,
    delivery: null,
  });
  const currency = _settings?.data.data[0].currency;
  const motionProps = {
    initial: { opacity: 0, scale: 0 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 },
    transition: { type: "tween", duration: 0.1 },
  };
  const riderOptions = riders?.map((rider) => ({
    value: rider,
    label: rider.fullName,
  }));
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const _variant = (
    <>
      <div className={styles.variants}>
        <div className={styles.header}>
          <h1>Variants</h1>
        </div>
        <ul className={styles.main}>
          {variants &&
            product &&
            variants?.map((_variant, index) => (
              <li
                key={index}
                onClick={() => setVariant(_variant)}
                className={
                  variant?._id === _variant?._id ? styles.selected : null
                }
              >
                <h2>
                  {_variant.name}
                  <span>
                    Qty:
                    {_variant.stock.quantity}
                  </span>
                </h2>
                <button>
                  {currency?.symbol || ""}
                  {utils.formatNumber(_variant.price.selling)}
                </button>
              </li>
            ))}
        </ul>
      </div>
      <Footer cancel={closeModal} confirm={addVariantToCart} styles={styles} />
    </>
  );

  const _selectPrice = (
    <>
      <div className={styles.variants}>
        <div className={styles.header}>
          <h1>Prices</h1>
        </div>
        <ul className={styles.main}>
          {product?.priceLevels?.map((_priceLevel) => (
            <li
              key={_priceLevel._id}
              onClick={() => setPriceLevel(_priceLevel)}
              className={
                priceLevel?._id === _priceLevel?._id ? styles.selected : null
              }
            >
              <h2>
                {_priceLevel.name}
                <span>
                  Qty:
                  {_priceLevel.quantity}
                </span>
              </h2>
              <button>
                {currency?.symbol || ""}
                {utils.formatNumber(_priceLevel.price)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <Footer
        cancel={closeModal}
        confirm={() =>
          addProductToCart(
            {
              ...product,
              name: `${priceLevel?.name}`,
              price: { ...product.price, selling: priceLevel.price },
            },
            closeModal
          )
        }
        styles={styles}
      />
    </>
  );

  const _addDelivery = (
    <>
      <div className={styles["form-group"]}>
        <div className="mb-10">
          <label
            htmlFor="riders"
            className="absolute text-sm text-black -top-6"
          >
            Select Rider
          </label>
          <icons.TruckIcon />
          <Select
            styles={utils.customStyles({
              valueContainer: { marginLeft: "1.4rem" },
            })}
            options={riderOptions}
            // placeholder="Select Rider"
            isClearable
            name="riders"
            id="riders"
            isLoading={!riders}
            value={[
              {
                value: riderDetails.rider?.value,
                label: riderDetails.rider?.label,
              },
            ]}
            onChange={(e) => setRiderDetails({ ...riderDetails, rider: e })}
            classNamePrefix="react-select"
          />
        </div>
        <div>
          <label
            htmlFor="riders"
            className="absolute text-sm text-black -top-6"
          >
            Select Delivery Package
          </label>
          <icons.ArchiveArrowDown />
          <Select
            styles={utils.customStyles({
              valueContainer: { marginLeft: "1.4rem" },
            })}
            options={
              deliveries
                ? (deliveries?.data || deliveries)?.map((delivery) => ({
                    value: delivery,
                    label: `${delivery?.name} (${utils.formatNumber(
                      delivery?.deliveryFee
                    )})`,
                  }))
                : []
            }
            placeholder=""
            isClearable
            name="riders"
            id="riders"
            isLoading={!settings}
            value={[
              {
                value: riderDetails.delivery?.value,
                label: riderDetails.delivery?.label,
              },
            ]}
            onChange={(e) => setRiderDetails({ ...riderDetails, delivery: e })}
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <Footer
        cancel={() => {
          setRiderDetails({ rider: null, delivery: null });
          dispatch(
            actions.updateCart({
              ...cart,
              grandTotal: utils.getGrandTotal(cart.items, 0),
              rider: null,
              delivery: null,
            })
          );
          closeModal();
        }}
        confirm={() => {
          if (!riderDetails.rider) {
            utils.notify("Select Rider!", "warning");
            return;
          }
          if (!riderDetails.delivery) {
            utils.notify("Select Package!", "warning");
            return;
          }
          addRider(riderDetails, closeModal);
        }}
        styles={styles}
      />
    </>
  );

  const _enterMeasurementQuantity = (
    <>
      <div className={styles["form-group"]}>
        <div>
          <icons.HashTagIcon />
          <input
            type="number"
            value={measurementQuantity}
            onChange={(e) => setMeasurementQuantity(e.target.value)}
          />
          <span>{product?.measurementType}</span>
        </div>
      </div>
      <Footer
        cancel={closeModal}
        confirm={() => {
          addProductToCart(
            {
              ...product,
              qty: +measurementQuantity,
              totalPrice: +measurementQuantity * product.price.selling,
            },
            closeModal
          );
          setMeasurementQuantity("");
        }}
        styles={styles}
      />
    </>
  );

  const _saveOrder = (
    <>
      <div className={styles["form-group"]}>
        <div>
          <icons.User />
          <input
            style={{ textTransform: "capitalize" }}
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
      </div>
      <Footer
        cancel={closeModal}
        confirm={() => saveOrder(customerName)}
        confirmLabel="Save"
        styles={styles}
      />
    </>
  );

  const _savedOrders = (
    <div className={styles["saved-orders"]}>
      <ul>
        {savedOrders?.map((order) => (
          <li
            key={order.id}
            className={savedOrder === order.id ? styles.selected : null}
            onClick={() => {
              setRemoveSavedOrder(false);
              savedOrder === order.id
                ? setSavedOrder(null)
                : setSavedOrder(order.id);
            }}
          >
            <div className={styles.title}>
              <h1>
                {order.customer?.label} @{" "}
                <span>{utils.convertUtc(order.timeStamp)}</span>
              </h1>
              <span
                className="ml-auto !text-[#ff3f39]"
                onClick={(e) => {
                  e.stopPropagation();
                  setSavedOrder(order.id);
                  setRemoveSavedOrder(true);
                  // deleteSavedOrder(order.id);
                }}
              >
                Remove
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    actions.updateCart({
                      ...order,
                      customer:
                        order.customer.value === "guest"
                          ? { value: "guest", label: "walk-in customer" }
                          : order.customer,
                    })
                  );
                  utils.notify("Order added to cart", "success");
                  deleteSavedOrder(order.id);
                  closeModal();
                }}
              >
                Push to cart
              </span>
            </div>

            {savedOrder === order.id && !removeSavedOrder && (
              <motion.table
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: "tween", duration: 0.05 }}
              >
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.qty}</td>
                      <td>{item.totalPrice}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td>{utils.getTotalItems(order.items)}</td>
                    <td>
                      {utils.getGrandTotal(
                        order.items,
                        cart.delivery?.value?.deliveryFee
                      )}
                    </td>
                  </tr>
                </tfoot>
              </motion.table>
            )}
            {savedOrder === order.id && removeSavedOrder && (
              <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  validateUser({ password })
                    .then(() => {
                      setLoading(false);
                      setPassword("");
                      deleteSavedOrder(order.id);
                    })
                    .catch(() => setLoading(false));
                }}
                className="absolute right-0 z-10 flex flex-col p-6 space-y-4 bg-white border border-gray-300 shadow-md top-10 w-52"
              >
                <input
                  type="password"
                  className="h-8 px-2 text-xs border"
                  placeholder="Enter admin password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <fieldset className="flex justify-around text-sm">
                  <button
                    type="button"
                    className="text-[#aaaaaa] w-12"
                    onClick={() => {
                      setRemoveSavedOrder(false);
                      setSavedOrder(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="text-[#258af4] w-12">
                    {loading ? (
                      <ClipLoader size={11} color="#258af4" />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </fieldset>
              </form>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  const _offlineSales = (
    <div className={styles["offline-sales"]}>
      <h1>Sale(s) will upload automatically when network is restored</h1>
      <ul>
        {offlineSales?.map((sale, index) => (
          <li
            key={index}
            className={offlineSale === sale.id ? styles.selected : null}
          >
            <div
              className={styles.title}
              onClick={() =>
                offlineSale === sale.id
                  ? setOfflineSale(null)
                  : setOfflineSale(sale.id)
              }
            >
              <h1>
                {sale.customer?.label} @<span>{sale.timeStamp}</span>
              </h1>
              <span
                className="ml-auto !text-[#ff3f39]"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteOfflineSale(sale.id, closeModal);
                }}
              >
                Remove
              </span>
            </div>

            {offlineSale === sale.id && (
              <motion.table
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: "tween", duration: 0.05 }}
              >
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {sale.items?.map((item) => (
                    <tr key={item._id}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.price}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td>{utils.getTotalItems(sale.items)}</td>
                    <td>{sale.amount}</td>
                  </tr>
                </tfoot>
              </motion.table>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  const _clearCart = (
    <div className={styles["clear-cart"]}>
      <p>Are you sure you want to clear cart</p>
      <Actions
        cancel={closeModal}
        confirm={() => {
          dispatch(actions.clearCart());
          closeModal();
        }}
        cancelLabel="No"
        confirmLabel="Yes"
        styles={styles}
      />
    </div>
  );

  const _checkout = (
    <>
      <div className={styles.checkout}>
        <div className={styles.header}>
          <h1>
            Total amount to pay:{" "}
            <span
              className={classNames({
                "line-through text-[#ff4949]": cart.grandTotalAfterDiscount,
              })}
            >
              {currency?.symbol || ""}
              {utils.formatNumber(cart.grandTotal || 0)}
            </span>
            {cart.grandTotalAfterDiscount && (
              <i>
                {currency?.symbol || ""}
                {utils.formatNumber(cart.grandTotalAfterDiscount)}
              </i>
            )}
            {" + "}
            <span className="whitespace-nowrap">
              {currency?.symbol || ""}
              {utils.formatNumber(cart.tax || 0)} (Tax)
            </span>
            <br />
            Checkout with:
          </h1>
        </div>
        <div className={styles.options}>
          {settings?.paymentMethods &&
            settings?.paymentMethods.map((method) => (
              <button
                key={method._id}
                onClick={() =>
                  selectMultiple
                    ? handleMultiSelect(method)
                    : setPaymentMethods([
                        { ...method, amount: getAmountPlusTax() },
                      ])
                }
                className={classNames("", {
                  [styles.selected]:
                    paymentMethods.find(({ _id }) => _id === method._id) &&
                    selectMultiple,
                })}
              >
                {method.category === "cash" ? (
                  <icons.CashIcon />
                ) : method.category === "card" ? (
                  <icons.CreditCardIcon />
                ) : (
                  <icons.LibraryIcon />
                )}
                {method.type}
              </button>
            ))}
          {selectMultiple && (
            <button
              className="bg-[var(--accent-color)] text-white"
              onClick={() => {
                if (paymentMethods.length < 2) {
                  utils.notify("Select a minimum of two options", "info");
                  return;
                }
                if (paymentMethods.length > 3) {
                  utils.notify("Select a maximum of three options", "info");
                  return;
                }
                setContinueSplitPayment(true);
              }}
            >
              <icons.ArrowsExpandIcon />
              Continue
            </button>
          )}
          <div
            className={classNames(
              {
                "justify-start":
                  (selectMultiple
                    ? settings?.paymentMethods?.length! + 1
                    : settings?.paymentMethods?.length!) %
                    2 ===
                  0,
              },
              {
                "justify-end":
                  (selectMultiple
                    ? settings?.paymentMethods?.length! + 1
                    : settings?.paymentMethods?.length!) %
                    2 !==
                  0,
              }
            )}
          >
            <label htmlFor="select-multiple">Select Multiple</label>
            <input
              id="select-multiple"
              name="select-multiple"
              type="checkbox"
              checked={selectMultiple}
              onChange={(e) => {
                setSelectMultiple(e.target.checked);
                !e.target.checked && setPaymentMethods([]);
              }}
            />
          </div>
        </div>
        <AnimatePresence>
          {discountDialog && (
            <motion.div {...motionProps} className={styles["discount-dialog"]}>
              <h1>Apply discount?</h1>
              <Actions
                cancel={() => setDiscountDialog(false)}
                confirm={() => {
                  setDiscountDialog(false);
                  setDiscountOptions(true);
                }}
                cancelLabel="No"
                confirmLabel="Yes"
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {couponDialog && (
            <motion.div {...motionProps} className={styles["coupon-dialog"]}>
              <input
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <Actions
                cancel={() => setCouponDialog(false)}
                confirm={handleRedeemCoupon}
                confirmLabel="Validate"
                loading={loading}
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!selectMultiple && paymentMethods[0]?.category === "cash" && (
            <motion.div {...motionProps} className={styles["cash-dialog"]}>
              <CheckoutHeader option="cash" cart={cart} styles={styles} />
              <div className={styles.input}>
                <span className={styles.currency}>
                  {currency?.symbol || ""}
                </span>
                <input
                  type="number"
                  value={collectedAmount}
                  min={1}
                  onChange={handleCollectedAmount}
                />
                <span
                  onClick={() =>
                    setCollectedAmount(
                      cart.grandTotalAfterDiscount
                        ? cart.grandTotalAfterDiscount + (cart.tax ?? 0)
                        : cart.grandTotal + (cart.tax ?? 0)
                    )
                  }
                >
                  Exact
                </span>
              </div>
              <span>
                Customer's Change: {currency?.symbol || ""}
                {balance}
              </span>
              <Actions
                cancel={() => setPaymentMethods([])}
                confirm={checkoutWithCash}
                loading={loading}
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!selectMultiple &&
            (paymentMethods[0]?.category === "bankTransfer" ||
              paymentMethods[0]?.category === "card") && (
              <motion.div
                {...motionProps}
                className={styles["bank-transfer-dialog"]}
              >
                <CheckoutHeader
                  option={paymentMethods[0]?.type}
                  cart={cart}
                  styles={styles}
                />
                <p>Confirm payment</p>
                <Actions
                  cancel={() => setPaymentMethods([])}
                  confirm={checkoutWithOthers}
                  loading={loading}
                  styles={styles}
                />
              </motion.div>
            )}
        </AnimatePresence>
        <AnimatePresence>
          {points && (
            <motion.div {...motionProps} className={styles["points-dialog"]}>
              <CheckoutHeader
                option="loyalty points"
                cart={cart}
                styles={styles}
              />
              <Actions
                cancel={() => setPoints(false)}
                confirm={() => setPoints(false)}
                loading={loading}
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {ContinueSplitPayment && (
            <motion.div
              {...motionProps}
              className={styles["continue-split-payment-dialog"]}
            >
              <div className={styles.header}>
                <h1>
                  Total amount to pay:{" "}
                  <span
                    className={classNames({
                      "line-through text-[#ff4949]":
                        cart.grandTotalAfterDiscount,
                    })}
                  >
                    {currency?.symbol || ""}
                    {utils.formatNumber(cart.grandTotal + cart.tax || 0)}
                  </span>
                  {cart.grandTotalAfterDiscount && (
                    <i>
                      {currency?.symbol || ""}
                      {utils.formatNumber(
                        cart.grandTotalAfterDiscount + cart.tax
                      )}
                    </i>
                  )}
                  <br />
                  Checkout with these options
                </h1>
              </div>

              <form
                onSubmit={checkoutWithSplitPayment}
                className={classNames(styles.options, {
                  "grid-cols-2": paymentMethods.length === 2,
                  "grid-cols-3": paymentMethods.length === 3,
                })}
              >
                {paymentMethods.map((method) => (
                  <fieldset key={method._id} className={styles.cash}>
                    <label htmlFor={method.type}>
                      {utils.truncateString(method.type, 12)}
                    </label>
                    <div>
                      <input
                        type="number"
                        id={method.type}
                        name={method.type}
                        placeholder="Enter Amount"
                        value={method.amount}
                        onChange={(e) =>
                          handleSplitPaymentOptionsValue(
                            +e.target.value,
                            method._id
                          )
                        }
                        required
                      />
                      <icons.CloseIcon onClick={() => removeMethod(method)} />
                    </div>
                  </fieldset>
                ))}

                <Actions
                  cancel={() => setContinueSplitPayment(false)}
                  confirm={() => {}}
                  loading={loading}
                  styles={styles}
                />
              </form>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {pendingSale && (
            <motion.div {...motionProps} className={styles["pending-sale"]}>
              <div className={styles.header}>
                <h1>
                  New pending sale recorded
                  <br />
                  Do you want to complete sale?
                </h1>
              </div>
              <Actions
                cancel={() => {
                  setPrintReceipt(true);
                  toast.info("Transaction status set to pending");
                  refetchProducts();
                }}
                confirm={handleCompleteSale}
                cancelLabel="No"
                confirmLabel="Complete"
                loading={loading}
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {printReceipt && (
            <motion.div {...motionProps} className={styles["print-receipt"]}>
              <div className={styles.header}>
                <h1>
                  {/* Sale recorded successfully
                  <br /> */}
                  Print receipt?
                </h1>
              </div>
              <Actions
                cancel={() => {
                  closeModal();
                  dispatch(actions.clearCart());
                  toast.success("Transaction complete");
                }}
                confirm={() => {
                  closeModal();
                  setPrintReceipt(false);
                  dispatch(actions.clearCart());
                  handlePrint();
                  toast.success("Transaction complete, printing...");
                }}
                cancelLabel="No"
                confirmLabel="Yes"
                styles={styles}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!pendingSale && !printReceipt && (
        <div className={styles.footer}>
          {!discountOptions && (
            <>
              <div className={styles["form-group"]}>
                <input
                  type="radio"
                  id="discount"
                  name="discount"
                  checked={discountDialog}
                  onChange={() => {
                    setDiscountDialog((prevState) => !prevState);
                    setCouponDialog(false);
                  }}
                />
                <label htmlFor="discount">Apply Discount</label>
              </div>
              <div className={styles["form-group"]}>
                <input
                  type="radio"
                  id="coupon"
                  name="coupon"
                  checked={couponDialog}
                  onChange={() => {
                    setCouponDialog((prevState) => !prevState);
                    setDiscountDialog(false);
                  }}
                />
                <label htmlFor="coupon">Apply Coupon</label>
              </div>
            </>
          )}
          {discountOptions && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={styles["discount-options"]}
            >
              <h1>Select discount mode</h1>
              <div className={styles["form-group"]}>
                <input
                  type="radio"
                  id="amount"
                  name="amount"
                  checked={discountMode === "amount"}
                  onChange={(e) => setDiscountMode(e.target.name)}
                />
                <label htmlFor="amount" style={{ marginRight: "1rem" }}>
                  Amount
                </label>
                <input
                  type="radio"
                  id="percent"
                  name="percent"
                  checked={discountMode === "percent"}
                  onChange={(e) => setDiscountMode(e.target.name)}
                />
                <label htmlFor="percent">Percentage</label>
              </div>
              {discountMode && (
                <div
                  className={styles["form-group"]}
                  style={{ marginTop: "1rem" }}
                >
                  <label htmlFor="selected-mode" style={{ width: "1rem" }}>
                    {discountMode === "amount" ? currency?.symbol || "" : "%"}
                  </label>
                  <input
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    min={0}
                  />
                  <button
                    style={{
                      marginLeft: "auto",
                      color: "revert",
                      backgroundColor: "transparent",
                      border: "1px solid #cccccc",
                    }}
                    onClick={() => cancelDiscount(setDiscountOptions)}
                  >
                    Cancel
                  </button>
                  <button
                    style={{
                      marginLeft: "1rem",
                      cursor: cart.grandTotalAfterDiscount
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => handleDiscount(discount, discountMode)}
                  >
                    {cart.discountAmount || cart.discountPercent
                      ? "Discount Added"
                      : "Add Discount"}
                  </button>
                </div>
              )}
            </motion.div>
          )}
        </div>
      )}
    </>
  );

  function closeModal() {
    close();
    setPriceLevel(null);
    setDiscountDialog(false);
    setDiscountOptions(false);
    setDiscountMode("");
    setCollectedAmount("");
    setPassword("");
    setBalance(0);
    setSale(null);
    setPoints(false);
    setDiscount("");
    setCouponDialog(false);
    setCouponCode("");
    setContinueSplitPayment(false);
    setPrintReceipt(false);
    setPendingSale(false);
    setSavedOrder(null);
    setPaymentMethods([]);
    setRemoveSavedOrder(false);
    setCustomerName(cart.customer?.label);
    setRiderDetails({ rider: null, delivery: null });
    cart.grandTotalAfterDiscount && cancelDiscount(setDiscountOptions);
  }

  function handleMultiSelect(method: IPaymentMethod) {
    if (paymentMethods.length === 0) {
      setPaymentMethods([{ ...method, amount: 0 }]);
    }
    const methodExists = paymentMethods.find(({ _id }) => _id === method._id);
    if (methodExists) {
      let updatedMethods = [...paymentMethods];
      updatedMethods = updatedMethods.filter(({ _id }) => _id !== method._id);
      setPaymentMethods(updatedMethods);
    }
    if (!methodExists) {
      setPaymentMethods([...paymentMethods, { ...method, amount: 0 }]);
    }
  }

  function handleCollectedAmount(e: ChangeEvent<HTMLInputElement>) {
    setCollectedAmount(e.target.valueAsNumber);
    const _balance = +e.target.value - cart.grandTotal - cart.tax;
    if (_balance < 0) {
      setBalance(0);
      return;
    }
    setBalance(_balance);
  }

  function handleSplitPaymentOptionsValue(value: number, id: string) {
    const updatedPaymentMethods = paymentMethods.map((method) =>
      method._id === id ? { ...method, amount: value } : method
    );
    setPaymentMethods(updatedPaymentMethods);
  }

  function removeMethod(method: IPaymentMethod) {
    if (paymentMethods.length === 2) {
      utils.notify("a minimum of two options is allowed", "warning");
      return;
    }
    let updatedMethods = [...paymentMethods];
    updatedMethods = updatedMethods.filter(({ _id }) => _id !== method._id);
    setPaymentMethods(updatedMethods);
  }

  function _createWalkInSale(payload: ICreateSalePayload) {
    if (!online || !network.online) {
      saveOfflineSale(payload, "guest", setPrintReceipt);
      return;
    }
    setLoading(true);
    services
      .createWalkInSale(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        setDiscountOptions(false);
        setPendingSale(true);
        setSale(response.data.data);
      })
      .catch(({ response }) => {
        setLoading(false);
        if (!response?.data) {
          saveOfflineSale(payload, "guest", setPrintReceipt);
        }
      });
    // .catch((error) => {
    //   setLoading(false);
    //   if (error instanceof AxiosError<any>) {
    //     return;
    //   }
    //   saveOfflineSale(payload, "guest", setPrintReceipt);
    // });
  }

  function _createCustomerSale(payload: ICreateSalePayload) {
    if (!online || !network.online) {
      saveOfflineSale(payload, "customer", setPrintReceipt);
      return;
    }
    setLoading(true);
    services
      .createCustomerSale(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        setDiscountOptions(false);
        setPendingSale(true);
        setSale(response.data.data);
      })
      .catch(({ response }) => {
        setLoading(false);
        if (!response?.data) {
          saveOfflineSale(payload, "customer", setPrintReceipt);
        }
      });
  }

  function _createDeliverySale(payload: ICreateSalePayload) {
    if (!online || !network.online) {
      saveOfflineSale(payload, "delivery", setPrintReceipt);
      return;
    }
    setLoading(true);
    services
      .createDeliverySale(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        setDiscountOptions(false);
        setPendingSale(true);
        setSale(response.data.data);
      })
      .catch(({ response }) => {
        setLoading(false);
        if (!response?.data) {
          saveOfflineSale(payload, "delivery", setPrintReceipt);
        }
      });
    // .catch((error) => {
    //   setLoading(false);
    //   if (error instanceof AxiosError<any>) {
    //     return;
    //   }
    //   saveOfflineSale(payload, "delivery", setPrintReceipt);
    // });
  }

  function selectSaleType(
    paymentMethods: (Omit<IPaymentMethod, "category" | "setting"> & {
      amount: number;
    })[]
  ) {
    dispatch(actions.updateCart({ ...cart, paymentMethods }));
    if (user.sageEnabled) {
      const payload = {
        ...getRequestPayload(paymentMethods, cart.customer.value._id),
        ...cart.customer?.value?.sage,
      };
      _createCustomerSale(payload);
      return;
    }
    if (cart.customer.value === "guest" || !cart.customer) {
      const payload = getRequestPayload(paymentMethods);
      _createWalkInSale(payload);
    }

    if (cart.customer.value !== "guest" && !cart.rider) {
      const payload = getRequestPayload(
        paymentMethods,
        cart.customer.value._id
      );
      _createCustomerSale(payload);
    }

    if (cart.customer.value !== "guest" && cart.rider) {
      const deliveryDetails = {
        rider: {
          id: cart.rider.value._id,
          name: cart.rider.value.fullName,
          phone: cart.rider.value.phone,
          plate_number: cart.rider.value.plateNumber,
        },
        recipient: {
          name: cart.customer?.label,
          phone: cart.customer.value.phone,
          address: `${cart.customer.value.location.city} ${cart.customer.value.location.address}`,
        },
        deliveryId: cart.delivery.value._id,
      };
      const payload = getRequestPayload(
        paymentMethods,
        cart.customer.value._id,
        deliveryDetails
      );
      _createDeliverySale(payload);
    }
  }

  function checkoutWithCash() {
    if (collectedAmount === "") {
      toast.warning("Unable to confirm! Enter amount collected from customer");
      return;
    }
    if (collectedAmount < getAmountPlusTax()) {
      toast.warning(
        "Unable to confirm! Collected amount is less than total amount"
      );
      return;
    }
    const _paymentMethods = [
      {
        _id: paymentMethods[0]?._id,
        type: paymentMethods[0]?.type,
        details: "",
        amount: getAmountPlusTax(),
      },
    ];
    selectSaleType(_paymentMethods);
  }

  function checkoutWithOthers() {
    const _paymentMethods = [
      {
        _id: paymentMethods[0]?._id,
        type: paymentMethods[0]?.type,
        details: "",
        amount: getAmountPlusTax(),
      },
    ];
    selectSaleType(_paymentMethods);
  }

  function checkoutWithSplitPayment(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const sum = paymentMethods
      .map(({ amount }) => amount)
      .reduce((prev, curr) => prev + curr);
    if (sum < getAmountPlusTax()) {
      utils.notify(
        "Unable to confirm! Total amount is less than total cost",
        "warning"
      );
      return;
    }
    const _paymentMethods = paymentMethods.map((method) => ({
      _id: method._id,
      type: method.type,
      details: "",
      amount: method.amount,
    }));
    selectSaleType(_paymentMethods);
  }

  function handleCompleteSale() {
    setLoading(true);
    services
      .updateSale(sale._id, {
        ...sale,
        status: "complete",
        ...(user.sageEnabled && cart.customer?.value?.sage),
        ...(user.sageEnabled && { location: user.sageLocation }),
      })
      .then(() => {
        setLoading(false);
        setPrintReceipt(true);
        setSale({ ...sale, status: "complete " });
        refetchProducts();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handleRedeemCoupon() {
    setLoading(true);
    redeemCoupon({ code: couponCode })
      .then(({ data }) => {
        setLoading(false);
        setCouponCode("");
        setCouponDialog(false);
        if (data.data.value.type === "fixed") {
          dispatch(actions.discountAmount(data.data.value.value));
          utils.notify(
            `Redeemed successfully! Discount of ${currency?.code || ""}${
              data.data.value.value
            } applied`,
            "success"
          );
          return;
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    function completeOfflineSale(sale: ISale) {
      services
        .updateSale(sale._id, {
          ...sale,
          status: "complete",
        })
        .then(() => deleteOfflineSale(sale.offlineId, closeModal))
        .catch(({ response: { data } }) => {
          if (data.message === "cannot edit resolved") {
            deleteOfflineSale(sale.offlineId, closeModal);
          }
        });
    }

    if (offlineSales && network.online) {
      offlineSales.forEach((sale: IOfflineSalePayload) => {
        if (sale.type === "guest") {
          const { customer, ...rest } = sale;
          services
            .createWalkInSale(
              { ...rest, offline: true },
              { branchId: branch?._id ?? "" }
            )
            .then(({ data }) => completeOfflineSale(data.data))
            .catch(({ response }) => {
              if (response?.data?.message === "Offline sale already recorded") {
                completeOfflineSale(response.data.sale);
              }
            });
        }
        if (sale.type === "customer") {
          const { customer, ...rest } = sale;
          services
            .createCustomerSale(
              { ...rest, offline: true, customer: customer?.value?._id },
              { branchId: branch?._id ?? "" }
            )
            .then(({ data }) => completeOfflineSale(data.data))
            .catch(({ response }) => {
              if (response?.data?.message === "Offline sale already recorded") {
                completeOfflineSale(response.data.sale);
              }
            });
        }
        if (sale.type === "delivery") {
          const { customer, ...rest } = sale;
          services
            .createDeliverySale(
              { ...rest, offline: true, customer: customer?.value?._id },
              { branchId: branch?._id ?? "" }
            )
            .then(({ data }) => completeOfflineSale(data.data))
            .catch(({ response }) => {
              if (response?.data?.message === "Offline sale already recorded") {
                completeOfflineSale(response.data.sale);
              }
            });
        }
      });
    }
  }, [online, network.online, offlineSales]);

  // useEffect(() => {
  //   setCustomerName(cart.customer?.label);
  // }, [cart.customer?.label]);

  return (
    <Modal display={display} close={closeModal} styles={styles} title={title}>
      <div className={styles.body}>
        {title === ModalTitles.AddDelivery && _addDelivery}
        {title === ModalTitles.EnterQuantity && _enterMeasurementQuantity}
        {title === ModalTitles.SaveOrder && _saveOrder}
        {title === ModalTitles.SavedOrders && _savedOrders}
        {title === ModalTitles.OfflineSales && _offlineSales}
        {title === ModalTitles.ClearCart && _clearCart}
        {title === ModalTitles.SelectPrice && _selectPrice}
        {title?.includes(ModalTitles.Checkout) && _checkout}
        {title === product?.name && _variant}
      </div>
      <div style={{ display: "none" }}>
        <Receipt sale={sale!} ref={componentRef} />
      </div>
    </Modal>
  );
});
