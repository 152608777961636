import * as types from "../types";
import { getSales, getReturns } from "services/Sale";

export function salesLoaded(payload) {
  return {
    type: types.SALES_LOADED,
    sales: payload,
  };
}

export function salesReturnsLoaded(payload) {
  return {
    type: types.SALES_RETURNS_LOADED,
    returns: payload,
  };
}

export function updateSales(payload) {
  return {
    type: types.UPDATE_SALES,
    sales: payload,
  };
}

export function updateSalesReturns(payload) {
  return {
    type: types.UPDATE_SALES_RETURNS,
    returns: payload,
  };
}

export function clearSales() {
  return {
    type: types.CLEAR_SALES,
  };
}

export function salesError(bool) {
  return {
    type: types.SALES_ERROR,
    bool,
  };
}

export function salesReturnsError(bool) {
  return {
    type: types.SALES_RETURNS_ERROR,
    bool,
  };
}

export function _getSales() {
  return (dispatch) => {
    dispatch(salesError(false));
    getSales()
      .then((response) => {
        if (response.data.data) {
          dispatch(salesLoaded(response.data.data.allTransactions));
        }
        if (!response.data.data) {
          dispatch(salesLoaded([]));
        }
      })
      .catch(() => {
        dispatch(salesError(true));
        dispatch(salesLoaded(null));
      });
  };
}

export function _getSalesReturns() {
  return (dispatch) => {
    dispatch(salesReturnsError(false));
    getReturns()
      .then((response) => {
        if (response.data.data) {
          dispatch(salesReturnsLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(salesReturnsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(salesReturnsError(true));
        dispatch(salesReturnsLoaded(null));
      });
  };
}
