import * as types from "../types";
import { getReservations } from "services/Reservations";

export function reservationsLoaded(payload) {
  return {
    type: types.RESERVATIONS_LOADED,
    reservations: payload,
  };
}

export function updateReservations(payload) {
  return {
    type: types.UPDATE_RESERVATIONS,
    reservations: payload,
  };
}

export function clearReservations() {
  return {
    type: types.CLEAR_RESERVATIONS,
  };
}

export function reservationsError(bool) {
  return {
    type: types.RESERVATIONS_ERROR,
    bool,
  };
}

export function _getReservations() {
  return (dispatch) => {
    dispatch(reservationsError(false));
    getReservations()
      .then((response) => {
        if (response.data.data) {
          dispatch(reservationsLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(reservationsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(reservationsError(true));
        dispatch(reservationsLoaded(null));
      });
  };
}
