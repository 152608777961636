import React, { Dispatch, SetStateAction } from "react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";

type Props = {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
};

export default function ButtonToggle({ enabled, setEnabled }: Props) {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        "relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200",
        { "!bg-blue-500": enabled }
      )}
    >
      <span className="sr-only">Enable variant</span>
      <span
        className={`${
          enabled ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
  );
}
