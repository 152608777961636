import React, { useState, useEffect, ReactNode } from "react";
import * as actions from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useBranchesQuery } from "hooks/useBranchesQuery";
import { useLogOut } from "hooks/useLogOut";
import { Redirect } from "react-router-dom";
import { isAdmin, activeSubscription } from "helpers";
import useWindowDimension from "../../hooks/useWindowDimension";
import Navbar from "../Elements/Navbar";
import Sidebar from "../Elements/Sidebar";

type Props = {
  title: string;
  children: ReactNode;
};

export default function AdminLayout({ title, children }: Props) {
  const dispatch = useDispatch();
  const { logOut } = useLogOut();
  const { width } = useWindowDimension();
  const { data: _branches } = useBranchesQuery();
  const { user, token, branch, subscriptionDaysLeft, invalidSession } =
    useSelector((state) => state.auth);
  const { data: adminData } = useSelector((state) => state.business.adminData);
  const { reports } = useSelector((state) => state.reports);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [sideBarDisplay, setSideBarDisplay] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const branches = _branches?.data.data;

  useEffect(() => {
    if (width! < 768) setSideBarDisplay(false);
    if (width! >= 768) setSideBarDisplay(true);
  }, [width]);

  useEffect(() => {
    if (invalidSession) {
      setModalDisplay(true);
    }
  }, [invalidSession]);

  useEffect(() => {
    if (branches && !branch) {
      dispatch(
        actions.setBranch(
          branches.find((branch) => branch.name.toLowerCase() === "home branch")
        )
      );
    }
  }, [branches]);

  useEffect(() => {
    if (user?.account?.role === "admin") {
      dispatch(actions._getReports());
      dispatch(actions._getAdminData());
    }
    dispatch(actions._getBranches());
  }, []);

  useEffect(() => {
    if (isAdmin(user)) {
      const activeSubscription = adminData?.subscription.find(
        (subscription) => subscription.isActive
      );
      if (activeSubscription) {
        const expiryDate = activeSubscription?.expires;
        const daysLeft = Math.floor(
          (new Date(expiryDate) - new Date()) / (1000 * 3600 * 24)
        );
        dispatch(actions.setSubscriptionDaysLeft(daysLeft));
      }
    } else {
      dispatch(actions.setSubscriptionDaysLeft(null));
    }
  }, [adminData]);

  if (!token) return <Redirect to="/login" />;

  return (
    <div className="flex mx-auto max-w-[1600px]">
      {width! > 0 && (
        <Sidebar
          display={sideBarDisplay}
          close={() => width! < 768 && setSideBarDisplay(false)}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          collapse={() => setCollapsed(true)}
        />
      )}
      <div className="w-full md:w-[calc(100%_-_4rem)] ml-auto bg-white">
        <Navbar
          title={title}
          setSideBarDisplay={() => {
            setSideBarDisplay((prevState) => !prevState);
            setCollapsed(true);
          }}
        />
        <main
          className="overflow-y-auto"
          style={{
            height:
              (!activeSubscription(reports) && isAdmin(user)) ||
              (subscriptionDaysLeft <= 7 && isAdmin(user))
                ? "calc(100vh - 8.5rem)"
                : "calc(100vh - 6.5rem)",
          }}
        >
          {children}
        </main>
      </div>
      {modalDisplay && (
        <div
          className="flex fixed top-0 left-0 w-full h-full z-[1200]"
          style={{
            backgroundColor: "rgb(0 0 0 / 60%)",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "3rem",
              margin: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h1
              style={{
                textAlign: "center",
                fontSize: "1rem",
                marginBottom: "1.5rem",
              }}
            >
              This session has expired, login again.
            </h1>
            <button
              style={{
                backgroundColor: "var(--accent-color)",
                border: "none",
                color: "#ffffff",
                width: "100%",
                padding: "0.4rem",
              }}
              onClick={logOut}
            >
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
