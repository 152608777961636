import * as types from "../types";

const INITIAL_STATE = {
  data: null,
  dataCopy: null,
  error: false,
};

export default function branches(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.BRANCHES_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_BRANCHES:
      return {
        data: null,
        dataCopy: null,
        error: false,
      };
    case types.BRANCHES_LOADED:
      return {
        ...state,
        data: action.branches,
        dataCopy: action.branches,
      };
    case types.UPDATE_BRANCHES:
      return {
        ...state,
        data: action.branches,
      };
    default:
      return state;
  }
}
