import React from "react";

type Props = {
  cancel: () => void;
  confirm: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  styles: { [key: string]: string };
};

export default function Footer({
  cancel,
  confirm,
  cancelLabel,
  confirmLabel,
  styles,
}: Props) {
  return (
    <div className={styles.footer}>
      <span onClick={cancel}>{cancelLabel || "Cancel"}</span>
      <button onClick={confirm}>{confirmLabel || "Add"}</button>
    </div>
  );
}
