import axios from "axios";
import {
  ICouponPayload,
  IRedeemCouponPayload,
  IUpdateCouponPayload,
} from "models/coupon";
import { IQueryParams } from "models/base";

export function createCoupon(payload: ICouponPayload, params: IQueryParams) {
  return axios.post(`/coupon`, payload, { params });
}

export function redeemCoupon(payload: IRedeemCouponPayload) {
  return axios.post(`/coupon/redeem`, payload);
}

export function updateCoupon(couponId: string, payload: IUpdateCouponPayload) {
  return axios.patch(`/coupon/${couponId}`, payload);
}

export function getCoupons(params: IQueryParams) {
  return axios.get(`/coupon`, { params });
}

export function invalidateCoupon(couponId: string) {
  return axios.patch(`/coupon/invalidate/${couponId}`);
}
