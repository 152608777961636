import axios from "axios";
import { IQueryParams } from "models/base";
import { ICustomersResponse } from "models/customer";

export function createCustomer(payload: any, params: IQueryParams) {
  return axios.post(`/customer`, payload, { params });
}

export function getCustomers(params?: IQueryParams) {
  return axios.get<ICustomersResponse>(`/customer`, { params });
}

export function updateCustomer(customerId: string, payload: any) {
  return axios.patch(`/customer/${customerId}`, payload);
}

export function deleteCustomer(customerId: string) {
  return axios.delete(`/customer/${customerId}`);
}
