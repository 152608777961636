import { useQuery } from "@tanstack/react-query";
import { IQueryParams } from "models/base";
import { useSelector } from "react-redux";
import { getProducts } from "services/Inventory";

export function useInventoryQuery(params?: IQueryParams) {
  const { user } = useSelector((state) => state.auth);
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [
      `/products`,
      { ...params, sync: user.sageEnabled ? true : false, branchId: _id },
    ],
    queryFn: () =>
      getProducts({
        ...params,
        sync: user.sageEnabled ? true : false,
        branchId: _id,
      }),
    refetchOnWindowFocus: false,
  });
}
