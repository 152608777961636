import axios from "axios";
import { IQueryParams } from "models/base";

export function getReservations(params: any) {
  return axios.get(`/reservation`, {
    params,
  });
}

export function createReservation(body: any, params: IQueryParams) {
  return axios.post(`/reservation`, body, { params });
}

export function updateReservation(reservationId: string, body: any) {
  return axios.patch(`/reservation/${reservationId}`, body);
}

export function deleteReservation(reservationId: string) {
  return axios.delete(`/reservation/${reservationId}`);
}
