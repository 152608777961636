import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createExpense, getExpenses } from "services/expenses";

export function useExpensesQuery(params?: any) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/expenses`, { ...params, branchId: _id }],
    queryFn: () => getExpenses({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}

export function useExpenseMutation() {
  const {
    mutate: mutateExpense,
    isLoading,
    error,
  } = useMutation(createExpense, {
    onSuccess: () => {
      // Success actions
    },
    onError: (error) => {
      // Error actions
    },
  });

  return {
    mutateExpense,
  };
}
