import React from "react";
import { useSelector } from "react-redux";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useReceiptSettingsQuery } from "hooks/useReceiptSettingsQuery";
import { ISale } from "models/sales";
import styles from "./Receipt.module.scss";

type Props = {
  sale?: ISale;
  bill?: boolean;
};

const Receipt = React.forwardRef<HTMLDivElement, Props>(
  ({ sale, bill }, ref) => {
    const { data: _receiptSettings } = useReceiptSettingsQuery();
    const { data: _settings } = useSettingsQuery();
    const { cart } = useSelector((state) => state.pos);
    const { user } = useSelector((state) => state.auth);
    const settings = _settings?.data.data[0];
    const currency = _settings?.data.data[0].currency;
    const receiptSettings = _receiptSettings?.data.data;
    const deliveryFee = sale?.deliveryDetails
      ? sale?.deliveryDetails?.deliveryFee
      : cart?.delivery?.value?.name;
    const customer = sale?.customer?.firstName
      ? `${sale?.customer?.firstName} ${sale?.customer?.lastName}`
      : cart?.customer?.label;
    const rider = sale?.deliveryDetails
      ? sale?.deliveryDetails?.rider?.name
      : cart?.rider?.label;
    const grandTotal = cart?.grandTotalAfterDiscount
      ? cart?.grandTotalAfterDiscount + (cart?.tax || 0)
      : cart?.grandTotal
      ? cart?.grandTotal + (cart?.tax || 0)
      : sale?.amount;

    return (
      <div ref={ref} className={styles.container}>
        <header>
          <img src={settings?.logo} alt="" />
          <h1>{settings?.businessName}</h1>
          <h4>{receiptSettings?.businessAddress || ""}</h4>
          <h4>
            Tel: {receiptSettings?.businessPhone1 || ""}{" "}
            {receiptSettings?.businessPhone2 || ""}
          </h4>
          <h4 className="lowercase">
            {receiptSettings?.businessEmail || settings?.email}
          </h4>
        </header>

        <div className={styles.body}>
          <p>
            Receipt No:{" "}
            <span className="uppercase">
              #{sale?.saleRef || Math.floor(Math.random() * 100000000)}
            </span>
          </p>
          <p>
            Time:
            <span>
              {new Date(
                cart?.createdAt || sale?.createdAt || new Date()
              )?.toLocaleString("en-GB", {
                hour12: false,
              })}
            </span>
          </p>
          <p>
            Cashier:
            <span>{user.firstName}</span>
          </p>
          {customer && (
            <p>
              Customer: <span>{customer}</span>
            </p>
          )}
          {rider && (
            <p>
              Rider: <span>{rider}</span>
            </p>
          )}
          <p>
            Transaction: <span>{sale?.status || "pending"}</span>
          </p>
          <p>
            Payment:
            <span>
              {sale?.paymentMethods
                ?.map((method) =>
                  method.type === "pos" ? "card" : method.type
                )
                .join(", ") ||
                cart?.paymentMethods
                  ?.map((method) =>
                    method.type === "pos" ? "card" : method.type
                  )
                  .join(", ")}
            </span>
          </p>
        </div>

        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Qty</th>
              <th>Total</th>
            </tr>
          </thead>
          {!sale && (
            <tbody>
              {cart?.items?.map((item, index) => (
                <tr key={item._id + index}>
                  <td>{item.name}</td>
                  <td>
                    {currency?.symbol || ""}
                    {item?.price?.selling?.toLocaleString()}
                  </td>
                  <td className="!pl-2">{item.qty}</td>
                  <td>
                    {currency?.symbol || ""}
                    {item?.totalPrice?.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {sale && (
            <tbody>
              {sale?.items?.map((item) => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>
                    {currency?.symbol || ""}
                    {item?.price?.toLocaleString()}
                  </td>
                  <td className="!pl-2">{item.quantity}</td>
                  <td>
                    {currency?.symbol || ""}
                    {(item.price * item.quantity).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              <td>Sub-Total</td>
              <td />
              <td className="!pl-2">
                {cart?.totalQty ||
                  sale?.items
                    ?.map((item) => item.quantity)
                    .reduce((prev, curr) => prev + curr)}
              </td>
              <td>
                {currency?.symbol || ""}
                {(
                  cart?.grandTotal ||
                  sale?.items
                    ?.map((item) => item.price * item.quantity)
                    .reduce((prev, curr) => prev + curr)
                )?.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td>Tax</td>
              <td />
              <td />
              <td>
                {currency?.symbol || ""}
                {(cart?.tax || sale?.tax)?.toLocaleString()}
              </td>
            </tr>
            {deliveryFee && (
              <tr>
                <td>Delivery Fee</td>
                <td />
                <td />
                <td>
                  {currency?.symbol || ""}
                  {deliveryFee?.toLocaleString()}
                </td>
              </tr>
            )}
            <tr>
              <td>Discount</td>
              <td />
              <td />
              <td>
                {currency?.symbol || ""}
                {(cart?.discountAmount || cart?.discountPercent
                  ? cart?.grandTotal - cart?.grandTotalAfterDiscount
                  : sale?.discountAmount || 0
                )?.toLocaleString()}
              </td>
            </tr>
            <tr style={{ borderTop: "1px solid #000000" }}>
              <td>Grand-Total</td>
              <td />
              <td />
              <td>
                {currency?.symbol || ""}
                {grandTotal?.toLocaleString()}
              </td>
            </tr>
          </tfoot>
        </table>

        <p
          style={{ marginTop: "2rem", fontSize: ".8rem", textAlign: "center" }}
        >
          {bill && "Bill"}
          <br />
          {receiptSettings?.message ?? ""}
        </p>
        <p style={{ marginTop: "3rem" }}>*</p>
      </div>
    );
  }
);

export default Receipt;
