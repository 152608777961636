import * as types from "../types";

const INITIAL_STATE = {
  customers: null,
  customersCopy: null,
  error: false,
};

export default function customers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CUSTOMERS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_CUSTOMERS:
      return {
        customers: null,
        customersCopy: null,
        error: false,
      };
    case types.CUSTOMERS_LOADED:
      return {
        ...state,
        customers: action.customers,
        customersCopy: action.customers,
      };
    case types.UPDATE_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
      };
    default:
      return state;
  }
}
