import React from "react";
import { motion } from "framer-motion";

export default function Backdrop({ close }: { close: () => void }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.5 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      onClick={close}
      className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[1100]"
    />
  );
}
