import { Link } from "react-router-dom";
import React from "react";
import DefaultLogo from "assets/images/onFlare/Logo/logo.png";
import MiniLogo from "assets/images/onFlare/Logo/logo-mini.png";

const Logo = ({
  mini,
  src,
  width = 40,
}: {
  mini?: boolean;
  src?: string;
  width?: number;
}) => {
  return (
    <Link to="/">
      <img
        src={mini ? MiniLogo : src ? src : DefaultLogo}
        alt="logo"
        className="logo"
        width={width}
      />
    </Link>
  );
};
export default Logo;
