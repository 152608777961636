import { useState, useEffect } from "react";
import { padWithLeadingZeros } from "helpers";

export default function usePagination(
  data: any[] | null,
  page: number,
  rowsPerPage = 5
) {
  const [tableRange, setTableRange] = useState<number[] | []>([]);
  const [slice, setSlice] = useState<any[]>([]);

  useEffect(() => {
    function getRange(data: any[], rowsPerPage: number) {
      const range = [];
      const num = Math.ceil(data.length / rowsPerPage);
      // let i = 1;
      for (let i = 1; i <= num; i++) {
        range.push(i);
      }
      return range;
    }
    function sliceData(data: any[], page: number, rowsPerPage: number) {
      return [...data]
        .reverse()
        .map((data, index) => ({
          ...data,
          index: padWithLeadingZeros(index + 1, 2),
        }))
        .slice((page - 1) * rowsPerPage, page * rowsPerPage);
    }

    if (data) {
      const range = getRange(data, rowsPerPage);
      setTableRange([...range]);
      const slice = sliceData(data, page, rowsPerPage);
      setSlice([...slice]);
    }
  }, [data, setTableRange, page, setSlice, rowsPerPage]);

  return { slice, range: tableRange };
}
