import React from "react";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { formatNumber } from "helpers";
import classNames from "classnames";

type Props = {
  option: any;
  cart: any;
  styles: { [key: string]: string };
};

export default function CheckoutHeader({ option, cart, styles }: Props) {
  const { data: _settings } = useSettingsQuery();
  const currency = _settings?.data.data[0].currency;
  return (
    <div className={styles.header}>
      <h1>
        Total amount to pay:{" "}
        <span
          className={classNames({
            "line-through text-[#ff4949]": cart.grandTotalAfterDiscount,
          })}
        >
          {currency?.symbol || ""}
          {formatNumber(cart.grandTotal + (cart.tax ?? 0))}
        </span>
        {cart.grandTotalAfterDiscount && (
          <i>
            {currency?.symbol || ""}
            {formatNumber(cart.grandTotalAfterDiscount + cart.tax)}
          </i>
        )}
        <br />
        Checkout with <span className="text-blue-500 capitalize">{option}</span>
      </h1>
    </div>
  );
}
