import * as types from "../types";
import { getCustomers } from "services/customers";

export function customersLoaded(payload) {
  return {
    type: types.CUSTOMERS_LOADED,
    customers: payload,
  };
}

export function updateCustomers(payload) {
  return {
    type: types.UPDATE_CUSTOMERS,
    customers: payload,
  };
}

export function clearCustomers() {
  return {
    type: types.CLEAR_CUSTOMERS,
  };
}

export function customersError(bool) {
  return {
    type: types.CUSTOMERS_ERROR,
    bool,
  };
}

export function _getCustomers(branchId) {
  return (dispatch) => {
    dispatch(customersError(false));
    getCustomers({ branchId })
      .then((response) => {
        if (response.data.data) {
          dispatch(customersLoaded(response.data.data));
          return;
        }
        if (!response.data.data) {
          dispatch(customersLoaded([]));
        }
      })
      .catch(() => {
        dispatch(customersError(true));
        dispatch(customersLoaded(null));
      });
  };
}
