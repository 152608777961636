import * as types from "../types";

const INITIAL_STATE = {
  logs: {
    data: null,
    error: false,
  },
  adminData: {
    data: null,
    error: false,
  },
};

export default function business(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOGS_ERROR:
      return {
        ...state,
        logs: {
          ...state.logs,
          error: action.bool,
        },
      };
    case types.LOGS_LOADED:
      return {
        ...state,
        logs: {
          ...state.logs,
          data: action.logs,
        },
      };
    case types.ADMIN_DATA_ERROR:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          error: action.bool,
        },
      };
    case types.ADMIN_DATA_LOADED:
      return {
        ...state,
        adminData: {
          ...state.adminData,
          data: action.adminData,
        },
      };
    default:
      return state;
  }
}
