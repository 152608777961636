import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { Widget } from "@uploadcare/react-widget";
import { createSettings, getAdminSettings } from "services/Settings";
import { ClipLoader } from "react-spinners";
import { notify } from "helpers";
import Select from "react-select";
import Slideshow from "components/Slideshow";
import useWindowDimension from "hooks/useWindowDimension";
import Button from "components/Elements/Button";
import Logo from "components/Elements/Logo";
import styles from "./AccountSettings.module.scss";

export default function AccountSettings() {
  const widgetApi = useRef();
  const history = useHistory();
  const { data } = useSettingsQuery();
  const { width } = useWindowDimension();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    businessName: "",
    logo: "",
    tax: "",
    email: "",
    externalLink: "",
    category: "",
  });
  const settings = data?.data.data[0];
  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    control: () => ({
      display: "flex",
      border: "1px solid #ccc",
      height: "2.5rem",
      borderRadius: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      textTransform: "capitalize",
      margin: 0,
    }),
  };

  function updateForm(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  }

  function uploadImage(e) {
    const { cdnUrl } = e;
    setForm({
      ...form,
      logo: cdnUrl,
    });
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const payload = {
      businessName: form.businessName,
      logo: form.logo,
      tax: form.tax,
      email: form.email,
      externalLink: form.externalLink,
      businessCategory: form.category,
    };

    createSettings(payload)
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        history.push("/dashboard");
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getAdminSettings().then((response) => {
      setCategories(
        response.data.data[0].category.flatMap((category) =>
          Object.values(category)
        )
      );
    });
  }, []);

  if (!token) return <Redirect to="/login" />;

  if (settings) return <Redirect to="/dashboard" />;

  if (user.account.role !== "admin") return <Redirect to="/pos" />;

  return (
    <div className={styles.container}>
      <Logo />
      <form onSubmit={handleSubmit}>
        <h1>Create Account Settings</h1>
        <fieldset>
          <label htmlFor="businessName">
            Business Name <span>*</span>
          </label>
          <input
            id="businessName"
            name="businessName"
            type="text"
            value={form.businessName}
            onChange={updateForm}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="email">
            Business Email <span>*</span>
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={form.email}
            onChange={updateForm}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="category">
            Business Category <span>*</span>
          </label>
          <Select
            styles={customStyles}
            options={categories?.map((category) => ({
              value: category,
              label: category,
            }))}
            id="branch"
            placeholder=""
            isClearable
            onChange={(e) =>
              setForm({
                ...form,
                category: e?.value,
              })
            }
            isLoading={!categories}
            classNamePrefix="react-select"
          />
        </fieldset>
        {/* <fieldset>
          <label htmlFor="tax">
            Tax <span>*</span>
          </label>
          <input
            id="tax"
            name="tax"
            type="number"
            value={form.tax}
            onChange={updateForm}
            required
          />
        </fieldset> */}
        <fieldset>
          <label htmlFor="externalLink">External Link</label>
          <input
            style={{ textTransform: "initial" }}
            id="externalLink"
            name="externalLink"
            type="text"
            value={form.externalLink}
            onChange={updateForm}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="logo">
            Logo <span>*</span>
          </label>
          <div>
            <Widget
              ref={widgetApi}
              onChange={uploadImage}
              publicKey={process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY}
              preloader={
                <p
                  style={{ height: 34.5, verticalAlign: "middle", margin: "0" }}
                >
                  Loading widget...
                </p>
              }
            />
          </div>
        </fieldset>
        <Button>
          {loading ? <ClipLoader size={12} color="#ffffff" /> : "Continue"}
        </Button>
      </form>
      {width! > 1045 && (
        <div>
          <Slideshow />
        </div>
      )}
    </div>
  );
}
