import * as types from "../types";
import { getExpenses } from "services/expenses";

export function expensesLoaded(payload) {
  return {
    type: types.EXPENSES_LOADED,
    expenses: payload,
  };
}

export function updateExpenses(payload) {
  return {
    type: types.UPDATE_EXPENSES,
    expenses: payload,
  };
}

export function clearExpenses() {
  return {
    type: types.CLEAR_EXPENSES,
  };
}

export function expensesError(bool) {
  return {
    type: types.EXPENSES_ERROR,
    bool,
  };
}

export function _getExpenses() {
  return (dispatch) => {
    dispatch(expensesError(false));
    getExpenses()
      .then((response) => {
        if (response.data.data) {
          dispatch(expensesLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(expensesLoaded([]));
        }
      })
      .catch(() => {
        dispatch(expensesError(true));
        dispatch(expensesLoaded(null));
      });
  };
}
