import { IQueryParams } from "models/base";
import {
  ICreateExpensePayload,
  IExpense,
  IExpenseResponse,
} from "models/expenses";
import axios from "axios";

export function getExpenses(params: IQueryParams) {
  return axios.get<IExpenseResponse>(`/expense`, {
    params,
  });
}

export function createExpense(
  payload: ICreateExpensePayload,
  params: IQueryParams
) {
  return axios.post<IExpenseResponse>(`/expense`, payload, { params });
}

export function updateExpense(expenseId: string, payload: IExpense) {
  return axios.patch<IExpenseResponse>(`/expense/${expenseId}`, payload);
}

export function deleteExpense(expenseId: string) {
  return axios.delete(`/expense/${expenseId}`);
}
