import React from "react";
import { Fade } from "react-slideshow-image";
import styles from "./Slideshow.module.scss";
import Image1 from "assets/images/onFlare/loginBackground.png";
import Image2 from "assets/images/onFlare/recoveryBackground.png";
import Image3 from "assets/images/onFlare/registrationBackground.png";
import "react-slideshow-image/dist/styles.css";

export default function Slideshow() {
  const images = [Image1, Image2, Image3];

  return (
    <Fade arrows={false} cssClass={styles.container} duration={2000}>
      {images?.map((image, index) => (
        <img
          key={index}
          style={{ objectFit: "cover", width: "100%" }}
          alt="slideshow"
          src={image}
        />
      ))}
    </Fade>
  );
}
