import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import pos from "./pos";
import auth from "./auth";
import network from "./network";
import products from "./products";
import warehouse from "./warehouse";
import sales from "./sales";
import suppliers from "./suppliers";
import expenses from "./expenses";
import riders from "./riders";
import customers from "./customers";
import branches from "./branches";
import staffs from "./staffs";
import reports from "./reports";
import reservations from "./reservations";
import business from "./business";

const persistConfig = {
  key: "one-flare-customer",
  storage,
  whitelist: ["auth", "pos"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth,
    network,
    pos,
    products,
    warehouse,
    sales,
    suppliers,
    expenses,
    riders,
    customers,
    branches,
    staffs,
    reports,
    reservations,
    business,
  })
);

const rootReducer = persistedReducer;

export default rootReducer;
