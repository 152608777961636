import * as types from "../types";
import { getHistory, getProducts } from "services/Warehouse";

export function warehouseProductsLoaded(payload) {
  return {
    type: types.WAREHOUSE_PRODUCTS_LOADED,
    products: payload,
  };
}

export function warehouseHistoryLoaded(payload) {
  return {
    type: types.WAREHOUSE_HISTORY_LOADED,
    history: payload,
  };
}

export function updateWarehouseProducts(payload) {
  return {
    type: types.UPDATE_WAREHOUSE_PRODUCTS,
    products: payload,
  };
}

export function updateWarehouseHistory(payload) {
  return {
    type: types.UPDATE_WAREHOUSE_HISTORY,
    history: payload,
  };
}

export function clearWarehouseProducts() {
  return {
    type: types.CLEAR_WAREHOUSE_PRODUCTS,
  };
}

export function warehouseProductsError(bool) {
  return {
    type: types.WAREHOUSE_PRODUCTS_ERROR,
    bool,
  };
}

export function warehouseHistoryError(bool) {
  return {
    type: types.WAREHOUSE_HISTORY_ERROR,
    bool,
  };
}

export function _getWarehouseProducts() {
  return (dispatch) => {
    dispatch(warehouseProductsError(false));
    getProducts()
      .then((response) => {
        if (response.data.data) {
          dispatch(warehouseProductsLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(warehouseProductsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(warehouseProductsError(true));
        dispatch(warehouseProductsLoaded(null));
      });
  };
}

export function _getWarehouseHistory() {
  return (dispatch) => {
    dispatch(warehouseHistoryError(false));
    getHistory()
      .then((response) => {
        if (response.data.data) {
          dispatch(warehouseHistoryLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(warehouseHistoryLoaded([]));
        }
      })
      .catch(() => {
        dispatch(warehouseHistoryError(true));
        dispatch(warehouseHistoryLoaded(null));
      });
  };
}
