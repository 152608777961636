import React from "react";
import { Menu } from "@headlessui/react";
import { Options } from "assets/svg/icons";
import classNames from "classnames";

type Props = {
  items: { title: string; function: () => void; type?: "error" | "primary" }[];
};

export default function ActionDropdown({ items }: Props) {
  return (
    <Menu>
      <Menu.Button className="!w-auto">
        <Options />
      </Menu.Button>
      <Menu.Items className="absolute right-16 top-2 z-10 min-w-[10rem] flex flex-col p-2 bg-white border rounded-md shadow-md">
        <ul>
          {items.map((item) => (
            <Menu.Item key={item?.title || ""}>
              {({ active }) => (
                <li
                  className={classNames("px-4 py-1 text-xs rounded-md", {
                    "bg-blue-100": active,
                    "bg-red-100": active && item.type === "error",
                  })}
                  onClick={item?.function || null}
                >
                  {item?.title || ""}
                </li>
              )}
            </Menu.Item>
          ))}
        </ul>
      </Menu.Items>
    </Menu>
  );
}
