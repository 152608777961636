import * as types from "../types";

const INITIAL_STATE = {
  token: null,
  user: null,
  loading: false,
  invalidSession: false,
  loginAs: "business",
  permissions: null,
  subscriptionDaysLeft: null,
  email: null,
  branch: null,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.INVALID_SESSION:
      return {
        ...state,
        invalidSession: true,
      };
    case types.LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case types.SET_USER:
      return {
        ...state,
        user: action.user.data,
        token: action.user.token,
        invalidSession: false,
      };
    case types.SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case types.CLEAR_EMAIL:
      return {
        ...state,
        email: null,
      };
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.role.find(
          (role) => role.role === state.user.account.role
        ).permissions,
      };
    case types.LOG_OUT:
      return {
        ...state,
        token: null,
        user: null,
        loading: false,
        invalidSession: false,
        branch: null,
      };
    case types.LOGIN_AS_BUSINESS:
      return {
        ...state,
        loginAs: "business",
      };
    case types.LOGIN_AS_STAFF:
      return {
        ...state,
        loginAs: "staff",
      };
    case types.LOGIN_AS_CUSTOMER:
      return {
        ...state,
        loginAs: "customer",
      };
    case types.SET_SUBSCRIPTION_DAYS_LEFT:
      return {
        ...state,
        subscriptionDaysLeft: action.days,
      };
    case types.SET_BRANCH:
      return {
        ...state,
        branch: action.branch,
      };
    default:
      return state;
  }
}
