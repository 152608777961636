import * as types from "../types";
import { getSuppliers } from "services/Supplier";

export function suppliersLoaded(payload) {
  return {
    type: types.SUPPLIERS_LOADED,
    suppliers: payload,
  };
}

export function updateSuppliers(payload) {
  return {
    type: types.UPDATE_SUPPLIERS,
    suppliers: payload,
  };
}

export function clearSuppliers() {
  return {
    type: types.CLEAR_SUPPLIERS,
  };
}

export function suppliersError(bool) {
  return {
    type: types.SUPPLIERS_ERROR,
    bool,
  };
}

export function _getSuppliers() {
  return (dispatch) => {
    dispatch(suppliersError(false));
    getSuppliers()
      .then((response) => {
        if (response.data.data) {
          dispatch(suppliersLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(suppliersLoaded([]));
        }
      })
      .catch(() => {
        dispatch(suppliersError(true));
        dispatch(suppliersLoaded(null));
      });
  };
}
