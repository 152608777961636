import React from "react";
import { capitalizeFirstLetter } from "helpers";
import styles from "./Error.module.scss";

function Error({ error }) {
  return (
    <>
      <p
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          margin: "1rem",
          color: "var(--error)",
        }}
      >
        {capitalizeFirstLetter(error.message)}.
      </p>

      <div className={styles.container}>
        <h1>Oops!</h1>
        <h3>Sorry, an unexpected error has occurred.</h3>
        <a href="/">Back to dashboard</a>
      </div>
    </>
  );
}

export default Error;
