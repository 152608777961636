import * as types from "../types";
import { getReports } from "services/Reports";

export function reportsLoaded(payload) {
  return {
    type: types.REPORTS_LOADED,
    reports: payload,
  };
}

export function updateReports(payload) {
  return {
    type: types.UPDATE_REPORTS,
    reports: payload,
  };
}

export function clearReports() {
  return {
    type: types.CLEAR_REPORTS,
  };
}

export function reportsError(bool) {
  return {
    type: types.REPORTS_ERROR,
    bool,
  };
}

export function _getReports() {
  return (dispatch) => {
    dispatch(reportsError(false));
    getReports()
      .then((response) => {
        if (response.data.data) {
          dispatch(reportsLoaded(response.data.data));
        }
        if (!response.data.data) {
          dispatch(reportsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(reportsError(true));
        dispatch(reportsLoaded(null));
      });
  };
}
