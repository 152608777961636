import * as types from "../types";
import { getBranches } from "services/branches";

export function branchesLoaded(payload) {
  return {
    type: types.BRANCHES_LOADED,
    branches: payload,
  };
}

export function updateBranches(payload) {
  return {
    type: types.UPDATE_BRANCHES,
    branches: payload,
  };
}

export function clearBranches() {
  return {
    type: types.CLEAR_BRANCHES,
  };
}

export function branchesError(bool) {
  return {
    type: types.BRANCHES_ERROR,
    bool,
  };
}

export function _getBranches() {
  return (dispatch) => {
    dispatch(branchesError(false));
    getBranches()
      .then((response) => {
        if (response.data.data) {
          dispatch(branchesLoaded(response.data.data));
          return;
        }
        if (!response.data.data) {
          dispatch(branchesLoaded([]));
        }
      })
      .catch(() => {
        dispatch(branchesError(true));
        dispatch(branchesLoaded(null));
      });
  };
}
