import axios from "axios";
import { IQueryParams } from "models/base";
import { IInventoryResponse } from "models/inventory";
import { IPriceUpdateHistoryResponse } from "models/priceUpdateHistory";

export function getProducts(params: IQueryParams) {
  return axios.get<IInventoryResponse>(`/inventory`, {
    params,
  });
}

export function createProduct(body: any, params: IQueryParams) {
  return axios.post(`/v2/inventory`, body, { params });
}

export function updateProduct(itemId: any, body: any, params: IQueryParams) {
  return axios.patch(`/inventory/${itemId}`, body, { params });
}

export function deleteProduct(itemId: any, params: IQueryParams) {
  return axios.delete(`/inventory/${itemId}`, { params });
}

export function createVariant(body: any, itemId: any) {
  return axios.post(`/inventory/variant/${itemId}`, body);
}

export function updateVariant(body: any, itemId: any) {
  return axios.patch(`/inventory/variant/${itemId}`, body);
}

export function getVariant(itemId: any) {
  return axios.get(`/inventory/variant/${itemId}`);
}

export function exportInventory(params: IQueryParams) {
  return axios.get(`/v2/inventory/export`, { params, responseType: "blob" });
}

export function exportInventoryToCSV(branchId: string) {
  return axios.get(`/v2/inventory/export/csv`, {
    params: { branchId },
    responseType: "blob",
  });
}

export function getPriceUpdate(params?: IQueryParams) {
  return axios.get<IPriceUpdateHistoryResponse>(`/inventory/history/price`, {
    params,
  });
}

export function hideProduct(productId: string) {
  return axios.patch(`/v2/inventory/${productId}/pause`, {
    hideFromPOS: true,
  });
}

export function showProduct(productId: string) {
  return axios.patch(`/v2/inventory/${productId}/pause`, {
    hideFromPOS: false,
  });
}
