import axios from "axios";
import { IQueryParams } from "models/base";
import { IRidersResponse } from "models/riders";

export function getRiders(params: any) {
  return axios.get<IRidersResponse>(`/rider`, {
    params,
  });
}

export function getRidersHistory(phoneNumber: any, params: any) {
  return axios.get(`/rider/0/history`, {
    params,
  });
}

export function createRider(body: any, params: IQueryParams) {
  return axios.post(`/rider`, body, { params });
}

export function updateRider(riderId: any, body: any) {
  return axios.patch(`/rider/${riderId}`, body);
}

export function deleteRider(riderId: any) {
  return axios.delete(`/rider/${riderId}`);
}
