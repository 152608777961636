import * as types from "../types";

const INITIAL_STATE = {
  reports: null,
  reportsCopy: null,
  error: false,
};

export default function reports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.REPORTS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_REPORTS:
      return {
        reports: null,
        reportsCopy: null,
        error: false,
      };
    case types.REPORTS_LOADED:
      return {
        ...state,
        reports: action.reports,
        reportsCopy: action.reports,
      };
    case types.UPDATE_REPORTS:
      return {
        ...state,
        reports: action.reports,
      };
    default:
      return state;
  }
}
