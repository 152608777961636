import React from "react";
import { ClipLoader } from "react-spinners";

type Props = {
  cancel: () => void;
  confirm: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  loading?: boolean;
  styles: { [key: string]: string };
};

export default function Actions({
  cancel,
  confirm,
  cancelLabel,
  confirmLabel,
  loading,
  styles,
}: Props) {
  return (
    <div className={styles.buttons}>
      <button type="button" onClick={cancel}>
        {cancelLabel || "Cancel"}
      </button>
      <button onClick={confirm}>
        {loading ? (
          <ClipLoader size={11} color="#ffffff" />
        ) : (
          confirmLabel || "Confirm"
        )}
      </button>
    </div>
  );
}
