import * as types from "../types";

const INITIAL_STATE = {
  data: null,
  dataCopy: null,
  error: false,
};

export default function reservations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.RESERVATIONS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_RESERVATIONS:
      return {
        data: null,
        dataCopy: null,
        error: false,
      };
    case types.RESERVATIONS_LOADED:
      return {
        ...state,
        data: action.reservations,
        dataCopy: action.reservations,
      };
    case types.UPDATE_RESERVATIONS:
      return {
        ...state,
        data: action.reservations,
      };
    default:
      return state;
  }
}
