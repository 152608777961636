import * as actions from "store/actions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

export function useLogOut() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  function logOut() {
    dispatch(actions.logOut());
    queryClient.clear();
    history.push("/login");
  }
  return { logOut };
}
