import React, { ReactNode } from "react";
import { Route, RouteProps } from "react-router-dom";
import AdminLayout from "components/AdminLayout";

interface Props extends RouteProps {
  title?: string;
  children: ReactNode;
}

export default function ProtectedRoute({ title, children, ...props }: Props) {
  return (
    <Route {...props}>
      <AdminLayout title={title ?? ""}>{children}</AdminLayout>
    </Route>
  );
}
