import axios from "axios";
import { IQueryParams } from "models/base";
import {
  ICreatePaymentMethodPayload,
  ICurrenciesResponse,
  ISettingsPayload,
  ISettingsResponse,
} from "models/settings";

export function getSettings(params?: IQueryParams) {
  return axios.get<ISettingsResponse>(`/settings`, {
    params,
  });
}

export function getCurrencies(params?: IQueryParams) {
  return axios.get<ICurrenciesResponse>(`/settings/currency`, {
    params,
  });
}

export function createSettings(payload: ISettingsPayload) {
  return axios.post(`/settings`, payload);
}

export function updateSettings(id: string, payload: Partial<ISettingsPayload>) {
  return axios.patch(`/settings/${id}`, payload);
}

export function getAdminSettings(params?: IQueryParams) {
  return axios.get(`/settings/admin`, {
    params,
  });
}

export function createPaymentMethod(payload: ICreatePaymentMethodPayload) {
  return axios.post(`/settings/payment-method`, payload);
}

export function getPaymentMethods(params?: IQueryParams) {
  return axios.get(`/settings/payment-methods`, { params });
}
