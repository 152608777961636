import * as types from "../types";

const INITIAL_STATE = {
  cart: {
    items: null,
    grandTotal: null,
    grandTotalAfterDiscount: null,
    discountAmount: null,
    discountPercent: null,
    totalItems: null,
    totalQty: null,
    id: null,
    customer: { value: "guest", label: "walk-in customer" },
    rider: null,
    delivery: null,
    createdAt: null,
    paymentMethods: null,
    tax: 0,
  },
  savedOrders: null,
  offlineSales: null,
};

export default function pos(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLEAR_CART:
      return {
        ...state,
        cart: INITIAL_STATE.cart,
      };
    case types.UPDATE_CART:
      return {
        ...state,
        cart: action.cart,
      };
    case types.UPDATE_CUSTOMER:
      return {
        ...state,
        cart: {
          ...state.cart,
          customer: action.customer,
        },
      };
    case types.UPDATE_RIDER:
      return {
        ...state,
        cart: {
          ...state.cart,
          rider: action.rider,
        },
      };
    case types.UPDATE_DELIVERY:
      return {
        ...state,
        cart: {
          ...state.cart,
          delivery: action.delivery,
        },
      };
    case types.DISCOUNT_AMOUNT:
      return {
        ...state,
        cart: {
          ...state.cart,
          grandTotalAfterDiscount: +state.cart.grandTotal - +action.discount,
          discountAmount: action.discount,
        },
      };
    case types.DISCOUNT_PERCENT:
      return {
        ...state,
        cart: {
          ...state.cart,
          grandTotalAfterDiscount:
            +state.cart.grandTotal -
            (+action.discount / 100) * +state.cart.grandTotal,
          discountPercent: action.discount,
        },
      };
    case types.UPDATE_SAVED_ORDERS:
      return {
        ...state,
        savedOrders: action.savedOrders,
      };
    case types.CLEAR_SAVED_ORDERS:
      return {
        ...state,
        savedOrders: null,
      };
    case types.UPDATE_OFFLINE_ORDERS:
      return {
        ...state,
        offlineSales: action.offlineSales,
      };
    case types.CLEAR_OFFLINE_ORDERS:
      return {
        ...state,
        offlineSales: null,
      };
    default:
      return state;
  }
}
