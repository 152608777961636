import React, { InputHTMLAttributes, useEffect, useRef } from "react";

export default function NumberInput(
  props: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "dangerouslySetInnerHTML" | "type"
  >
) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current &&
      inputRef.current.addEventListener("wheel", (e) => e.preventDefault());
    inputRef.current &&
      inputRef.current.addEventListener(
        "keydown",
        (e) =>
          (e.code === "ArrowUp" || e.code === "ArrowDown") && e.preventDefault()
      );
  }, [inputRef]);

  return <input ref={inputRef} type="number" {...props} />;
}
