import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useSalesReturnQuery } from "hooks/useSalesReturnQuery";
import { convertUtc, formatNumber, padWithLeadingZeros } from "helpers";
import Table, {
  DateRangeColumnFilter,
  PaymentMethodColumnFilter,
  SelectColumnFilter,
} from "components/Table/Table";
import Button from "components/Elements/Button";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import styles from "assets/styles/Pages.module.scss";

export default function Returns() {
  const { data: _settings } = useSettingsQuery();
  const { data: returns, isLoading, isError } = useSalesReturnQuery();
  const currency = _settings?.data.data[0].currency;
  const data = useMemo(
    () => (!returns ? [] : [...returns?.data?.data?.data].reverse()),
    [returns]
  );
  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        id: "sn",
        accessor: (_originalRow, rowIndex) =>
          padWithLeadingZeros(rowIndex + 1, 2),
        disableFilters: true,
      },
      {
        Header: "Ref",
        id: "ref",
        accessor: "saleDetails.saleRef",
        Cell: ({ value }) => <span className="!capitalize">{value}</span>,
        disableFilters: true,
      },
      {
        Header: "Items",
        id: "items",
        accessor: "items",
        Cell: ({ value }) => (
          <ul>
            {value.map((item) => (
              <li key={item._id}>{item.name}</li>
            ))}
          </ul>
        ),
        disableFilters: true,
      },
      {
        Header: "Quantity",
        id: "quantity",
        accessor: "items",
        Cell: ({ value }) => (
          <ul>
            {value.map((item) => (
              <li key={item._id}>{formatNumber(item.quantity)}</li>
            ))}
          </ul>
        ),
        disableFilters: true,
      },
      {
        Header: "Unit Price",
        id: "selling",
        accessor: "items",
        Cell: ({ value }) => (
          <ul>
            {value.map((item) => (
              <li key={item._id}>
                {currency?.symbol || ""}
                {formatNumber(item.price)}
              </li>
            ))}
          </ul>
        ),
        disableFilters: true,
      },
      {
        Header: "Total Amount",
        accessor: "amount",
        Cell: ({ value }) => (
          <span>
            {currency?.symbol || ""}
            {formatNumber(value)}
          </span>
        ),
        disableFilters: true,
      },
      {
        Header: "Payment Methods",
        accessor: "saleDetails.paymentMethods",
        Cell: ({ value }) => (
          <ul>
            {value.map((method) => (
              <li key={method._id}>{method.type}</li>
            ))}
          </ul>
        ),
        Filter: PaymentMethodColumnFilter,
      },
      {
        Header: "Staff",
        accessor: (originalRow) =>
          `${originalRow.staff.firstName} ${originalRow.staff.lastName}`,
        Filter: SelectColumnFilter,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }: any) => convertUtc(value),
        Filter: DateRangeColumnFilter,
        filter: "dateBetween",
      },
    ],
    []
  );

  return (
    <>
      <div
        className={styles.container}
        style={
          returns?.data?.data?.data?.length > 0
            ? { minHeight: "90%" }
            : { height: "80%" }
        }
      >
        <div className={styles.buttons}>
          <Link to="/sales">
            <Button>Go Back</Button>
          </Link>
        </div>
        {isLoading && <Loader />}
        {isError && <ErrorMessage message="An error occurred" />}
        {returns && (
          <>
            <h6>Total: {returns?.data?.data?.data?.length}</h6>
            <Table columns={columns} data={data} />
          </>
        )}
      </div>
    </>
  );
}
