import React from "react";
import MiniLogo from "assets/images/onFlare/Logo/logo-mini.png";
import styles from "./PrePageLoader.module.scss";

export default function PrePageLoader() {
  return (
    <div className={styles.container}>
      <img src={MiniLogo} alt="logo" className="logo" />
    </div>
  );
}
