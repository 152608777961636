import * as types from "../types";

export function online() {
  return {
    type: types.ONLINE,
  };
}

export function offline() {
  return {
    type: types.OFFLINE,
  };
}
