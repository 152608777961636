import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getPurchases, getPurchasesHistory } from "services/purchases";

export function usePurchasesQuery(params?: any) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/purchases`, { ...params, branchId: _id }],
    queryFn: () => getPurchases({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}

export function usePurchasesHistoryQuery(params?: any) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/purchases/history`, { ...params, branchId: _id }],
    queryFn: () => getPurchasesHistory({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}
