import axios from "axios";

export function getReports(params:any, branchId: string) {
  return axios.get(`/reports`, {
    ...params, branchId
  });
}

export function getReportDetails(params:any, branchId:string) {
  return axios.get(`/reports/details`, {
    ...params, branchId
  });
}
