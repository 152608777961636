import React from "react";
import { ExclamationIcon } from "assets/svg/icons";
import styles from "./ErrorMessage.module.scss";
import classNames from "classnames";

type Props = {
  message: string;
  fontSize?: string | number;
  width?: string | number;
  style?: any;
  className?: string;
};

export default function ErrorMessage({
  message,
  fontSize,
  width,
  style,
  className,
}: Props) {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <ExclamationIcon />
      <p style={{ fontSize, width }}>
        {message || "Ooops, an error occurred. Please try again later"}
      </p>
    </div>
  );
}
