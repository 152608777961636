import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
} from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./Button.module.scss";
import classNames from "classnames";

type Type = "button" | "submit" | "reset" | "link";

type BaseButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type BaseLinkProps = {
  to: string;
} & LinkProps;

type Props = {
  children: ReactNode;
  type?: Type;
} & (BaseButtonProps | BaseLinkProps);

export default function Button({ children, className, ...props }: Props) {
  if (props.type === "link") {
    return (
      <Link
        {...(props as BaseLinkProps)}
        className={classNames(styles.button, className)}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      {...(props as BaseButtonProps)}
      className={classNames(styles.button, className)}
    >
      {children}
    </button>
  );
}
