import { AxiosError } from "axios";
import { ISale } from "models/sales";
import { toast } from "react-toastify";

export function customStyles(args?: {
  height?: number | string;
  width?: number | string;
  borderRadius?: number | string;
  border?: string;
  container?: any;
  control?: any;
  menuList?: any;
  input?: any;
  singleValue?: any;
  multiValue?: any;
  menu?: any;
  valueContainer?: any;
  indicatorsContainer?: any;
}) {
  const {
    height = 29,
    width = "auto",
    borderRadius = 0,
    border = "1px solid #ccc",
  } = args || {};
  return {
    container: (provided: any) => ({
      ...provided,
      ...args?.container,
      width,
    }),
    control: () => ({
      ...args?.control,
      display: "flex",
      border,
      height,
      borderRadius,
    }),
    menuList: (provided: any) => ({
      ...provided,
      ...args?.menuList,
      // position: "absolute",
      // overflow: "auto",
      textTransform: "capitalize",
    }),
    input: (provided: any) => ({
      ...provided,
      ...args?.input,
      margin: 0,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      ...args?.singleValue,
      textTransform: "capitalize",
      margin: 0,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      ...args?.multiValue,
      textTransform: "capitalize",
    }),
    menu: (provided: any) => ({
      ...provided,
      ...args?.menu,
      fontSize: ".8rem",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      ...args?.valueContainer,
      fontSize: ".9rem",
      padding: "0 8px",
      overflowY: "auto",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      ...args?.indicatorsContainer,
    }),
  };
}

export function getFirstLetters(string = "") {
  const array = string.split(" ");
  let firstLetters = "";
  if (array?.length === 1) {
    firstLetters = array[0]
      .split("")
      .slice(0, 8)
      ?.map((word) => word[0])
      .join("");
  }

  if (array?.length !== 1) {
    firstLetters = array
      .slice(0, 2)
      ?.map((word) => word[0])
      .join("");
  }
  return firstLetters;
}

export function isValidDate(date: string) {
  const parsedDate = new Date(date);
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
}

export function truncateString(string: string, number: number) {
  if (string?.length > number) {
    return string.slice(0, number) + "...";
  } else {
    return string;
  }
}

export function convertUtc(date: string) {
  const localDate = new Date(date);
  return localDate.toLocaleString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function convertTime12To24(time: string) {
  if (!time) return;
  var hours = Number(time.match(/^(\d+)/)![1]);
  var minutes = Number(time.match(/:(\d+)/)![1]);
  var AMPM = time.match(/\s(.*)$/)![1];
  if (AMPM === "PM" && hours < 12) hours = hours + 12;
  if (AMPM === "AM" && hours === 12) hours = hours - 12;
  var sHours = hours.toString();
  var sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
}

export function capitalizeFirstLetter(string = "") {
  const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
  return capitalized;
}

export function formatNumber(number: number) {
  return (number || 0).toLocaleString();
}

export function currencyFormatter(currency = "NGN") {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
}

export function timeStamp() {
  const localDate = new Date();
  return localDate.toLocaleString("en-GB", { hour12: true });
}

export function getGrandTotal(products, deliveryFee: number) {
  const sum = products
    ?.map((product) => product.totalPrice)
    .reduce((prev, curr) => prev + curr, 0);
  return sum + (deliveryFee ?? 0);
}

export function getTotalQuantity(products) {
  const sum = products
    ?.map((product) => product.qty)
    .reduce((prev, curr) => +prev + +curr, 0);
  return sum;
}

export function getTotalItems(products) {
  return products?.length;
}

export function getTodaysData(
  data: { createdAt: string; [key: string]: any }[]
) {
  const todaysData = data?.filter((datum) => {
    const datumDate = new Date(datum.createdAt);
    const todaysDate = new Date();
    return (
      datumDate.getDate() === todaysDate.getDate() &&
      datumDate.getMonth() === todaysDate.getMonth() &&
      datumDate.getFullYear() === todaysDate.getFullYear()
    );
  });
  return todaysData;
}

export function getDaysInMonth(
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1
) {
  return new Date(year, month, 0).getDate();
}

export function getCurrentMonthsData(
  data: { createdAt: string; [key: string]: any }[]
) {
  const currentMonthsData = data?.filter((datum) => {
    const datumDate = new Date(datum.createdAt);
    const todaysDate = new Date();
    return (
      datumDate.getMonth() === todaysDate.getMonth() &&
      datumDate.getFullYear() === todaysDate.getFullYear()
    );
  });
  return currentMonthsData;
}

export function getOneMonthAgoData(
  data: { createdAt: string; [key: string]: any }[]
) {
  const todaysDate = new Date();
  const oneMonthAgo = new Date(todaysDate.setDate(todaysDate.getDate() - 30));
  const oneMonthAgoData = data?.filter(
    (datum) =>
      new Date(datum.createdAt) >= oneMonthAgo &&
      new Date(datum.createdAt) <= new Date()
  );
  return oneMonthAgoData;
}

export function getTwoMonthsAgoData(
  data: { createdAt: string; [key: string]: any }[]
) {
  const todaysDate = new Date();
  const twoMonthsAgo = new Date(todaysDate.setDate(todaysDate.getDate() - 60));
  const twoMonthsAgoData = data?.filter(
    (datum) =>
      new Date(datum.createdAt) >= twoMonthsAgo &&
      new Date(datum.createdAt) <= new Date()
  );
  return twoMonthsAgoData;
}

export function getThreeMonthsAgoData(
  data: { createdAt: string; [key: string]: any }[]
) {
  const todaysDate = new Date();
  const threeMonthsAgo = new Date(
    todaysDate.setDate(todaysDate.getDate() - 90)
  );
  const threeMonthsAgoData = data?.filter(
    (datum) =>
      new Date(datum.createdAt) >= threeMonthsAgo &&
      new Date(datum.createdAt) <= new Date()
  );
  return threeMonthsAgoData;
}

export function getDataFromPeriod(
  data: { createdAt: string; [key: string]: any }[],
  startDate: string,
  endDate: string
) {
  return data?.filter(
    (datum) =>
      new Date(datum.createdAt) >= new Date(startDate) &&
      new Date(datum.createdAt) <= new Date(endDate)
  );
}

export function getTotal(sale: ISale) {
  let total = sale.items
    ?.map((item) => item.price * item.quantity)
    ?.reduce((prev, curr) => prev + curr, 0);
  return total;
}

export function getTodaysTotalSales(sales: ISale[]) {
  const todaysSales = getTodaysData(sales) as ISale[];
  if (todaysSales?.length === 0) return;
  const total = todaysSales
    ?.map((sale) => sale?.amount)
    ?.reduce((prev, curr) => prev + curr, 0);
  return formatNumber(total || 0);
}

export function getFirstAndLastDayOfCurrentMonth(): [Date, Date] {
  const now = new Date();
  const firstDate = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  return [firstDate, lastDate];
}

export function toLowerCase(string: string) {
  return string.toLowerCase();
}

export function removeDecimalIfWhole(number: number): number {
  number = +number;
  if (Number.isInteger(number)) {
    return Math.trunc(number);
  }
  return number;
}

export function notify(
  message: string,
  status: "success" | "error" | "info" | "warning",
  toastId?: string
) {
  if (status === "success")
    toast.success(capitalizeFirstLetter(message), {
      toastId: toastId || encodeURI(message),
    });
  if (status === "error")
    toast.error(capitalizeFirstLetter(message), {
      toastId: toastId || encodeURI(message),
    });
  if (status === "info")
    toast.info(capitalizeFirstLetter(message), {
      toastId: toastId || encodeURI(message),
    });
  if (status === "warning")
    toast.warning(capitalizeFirstLetter(message), {
      toastId: toastId || encodeURI(message),
    });
}

export function axiosErrorNotifier(
  error: AxiosError<{
    message: string;
  }>
) {
  switch (true) {
    case error.response?.data?.message === "jwt expired":
      break;
    case error.response?.data?.message === "jwt malformed":
      break;
    case error.response?.data?.message === "Offline sale already recorded":
      break;
    case error.response?.data?.message === "cannot edit resolved":
      // notify("Already Completed", "info", "axios-error");
      break;
    case error.response?.data?.message === "no settings found":
      notify("Create account settings to continue", "info", "axios-error");
      break;
    default:
      notify(
        error?.response?.data?.message || "Poor connection, cannot fetch data",
        "error",
        "axios-error"
      );
  }
}

export function padWithLeadingZeros(number: number, totalLength: number) {
  return String(number).padStart(totalLength, "0");
}

export function isAdmin(user) {
  return user?.account.role === "admin";
}

export function activeSubscription(reports) {
  return JSON.stringify(reports?.subscription) !== "{}";
}
