import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  FormEvent,
} from "react";
import * as actions from "store/actions";
import * as services from "services/Sale";
import * as icons from "assets/svg/icons";
import * as utils from "helpers";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useSalesQuery } from "hooks/useSalesQuery";
import { ISale, ISalesResponse } from "models/sales";
import { AxiosResponse } from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import { toast } from "react-toastify";
import classNames from "classnames";
import Button from "components/Elements/Button";
import useWindowDimension from "hooks/useWindowDimension";
import usePageBlock from "hooks/usePageBlock";
import usePagination from "hooks/usePagination";
import PaginationControls from "components/PaginationControls";
import Modal from "components/Elements/Modal";
import Receipt from "components/Receipt";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "./Sales.module.scss";

const Sales = () => {
  const receiptRef = useRef(null);
  const dispatch = useDispatch();
  const todaysDate = format(new Date(), "yyyy-MM-dd");
  const { data: _settings } = useSettingsQuery();
  const { data, isLoading, isError, refetch } = useSalesQuery({
    startDate: todaysDate,
    endDate: todaysDate,
    page: 1,
    limit: 10000,
  });
  const [sales, setSales] = useState<ISale[] | null>(null);
  const animatedComponents = makeAnimated();
  const { width } = useWindowDimension();
  const { permissions, branch } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [sale, setSale] = useState<ISale | null>(null);
  const [returnItems, setReturnItems] = useState(null);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [emailReceipt, setEmailReceipt] = useState(false);
  const [email, setEmail] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("");
  const { slice, range } = usePagination(sales, page, rows);
  const currency = _settings?.data.data[0].currency;
  const saleOptions = sale?.items?.map((item) => ({
    value: item,
    label: item.name,
  }));
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  function searchSales(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearchInput(value);
    if (data) {
      const filteredSales = (
        data as AxiosResponse<ISalesResponse>
      ).data.data.transactions.filter((sale) => {
        if (value.trim() === "") return sale;
        if (sale.saleRef?.toLowerCase().includes(value.toLowerCase()))
          return sale;
      });
      setSales(filteredSales);
    }
  }

  function filterSales(e: ChangeEvent<HTMLSelectElement>) {
    setSearchInput("");
    const { value } = e.target;
    setFilter(value);
    if (data) {
      const filteredSales = (
        data as AxiosResponse<ISalesResponse>
      ).data.data.transactions.filter((sale) => sale.status === value);
      setSales(filteredSales);
    }
  }

  function clearInput() {
    setSearchInput("");
    setFilter("");
    setSales([
      ...(data as AxiosResponse<ISalesResponse>)?.data?.data?.transactions,
    ]);
  }

  function completeSale(sale: ISale) {
    if (sale.status === "complete") {
      toast.warning("Sale has already been completed");
      return;
    }
    toast.promise(
      services.updateSale(sale._id, { ...sale, status: "complete" }),
      {
        pending: "Completing Sale...",
        success: {
          render() {
            refetch();
            return "Sale completed!";
          },
        },
      }
    );
  }

  function updateProductQuantities(value, _id: string) {
    const updatedProducts = returnItems?.map((product) => {
      if (product.value._id === _id) {
        return {
          ...product,
          value: {
            ...product.value,
            quantity: +value,
          },
        };
      } else {
        return product;
      }
    });
    setReturnItems(updatedProducts);
  }

  function handleReturnItems(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const payload = {
      items: returnItems?.map((item) => ({
        ...item.value,
        amount: returnItems
          ?.map((item) => item.value.price)
          ?.reduce((prev, curr) => prev + curr),
      })),
    };
    toast.promise(
      services.returnItems(sale!._id, payload, { branchId: branch?._id }),
      {
        pending: "Processing",
        success: {
          render() {
            setModalDisplay(false);
            dispatch(actions._getSales());
            return "Success!";
          },
        },
      }
    );
  }

  function handleEmailReceipt(sale: ISale) {
    // e.preventDefault();
    toast.promise(services.sendReceipt(sale!._id), {
      pending: "Processing",
      success: {
        render() {
          // setModalDisplay(false);
          // dispatch(actions._getSales());
          return "Success!";
        },
      },
    });
  }

  useEffect(() => {
    if (data) setSales([...data?.data?.data?.transactions]);
  }, [data]);

  usePageBlock(permissions?.sales.view);

  return (
    <>
      <div
        className={classNames(styles.container, {
          "h-[80%]": isLoading || sales?.length === 0,
        })}
      >
        {(data as AxiosResponse<ISalesResponse>) && (
          <>
            <div className={styles.controls}>
              <div className={styles["form-group"]}>
                <input
                  type="text"
                  placeholder="Search Ref No."
                  value={searchInput}
                  onChange={searchSales}
                />
                {searchInput && <icons.CloseIcon onClick={clearInput} />}
              </div>
              <div className={styles["form-group"]}>
                {filter !== "date" && (
                  <select
                    name="filter"
                    id="filter"
                    value={filter}
                    onChange={filterSales}
                  >
                    <option value="" disabled>
                      Filter
                    </option>
                    <option value="pending">Pending Sales</option>
                    <option value="complete">Completed Sales</option>
                    {/* <option value="date">Date</option> */}
                  </select>
                )}
                {filter !== "" && <icons.CloseIcon onClick={clearInput} />}
              </div>
              <Button type="link" to="/sales/report">
                Sales Report
              </Button>
              <Button type="link" to="/sales/returns">
                Sales Returns
              </Button>
            </div>
            <h6>
              Total Sales: {currency?.symbol || ""}
              {utils.formatNumber(
                (data as AxiosResponse<ISalesResponse>)?.data?.data
                  ?.totalTransactions
              )}
            </h6>
            <h6>
              Total Profit: {currency?.symbol || ""}
              {utils.formatNumber(
                (data as AxiosResponse<ISalesResponse>)?.data?.data?.totalProfit
              )}
            </h6>
            <h6>Total Transactions: {sales?.length}</h6>
          </>
        )}
        {isLoading && <Loader />}
        {isError && <ErrorMessage message="An error occurred" />}
        {sales?.length === 0 && (
          <ErrorMessage
            message={
              searchInput
                ? "Not found"
                : "There are no sales today, go to POS page to make a sale"
            }
          />
        )}
        {sales && sales.length > 0 && (
          <>
            <div className={styles.table}>
              {(slice as (ISale & { index: number })[])?.map((_sale) => (
                <div
                  className={`${
                    sale?._id === _sale._id ? styles.selected : ""
                  } ${styles.row}`}
                  key={_sale._id}
                >
                  <div
                    onClick={() => {
                      sale?._id === _sale._id ? setSale(null) : setSale(_sale);
                    }}
                    className={styles.header}
                  >
                    <p>{_sale.index}</p>
                    <p className={styles.date}>
                      <span>
                        Ref:{" "}
                        {_sale.saleRef
                          ? _sale.saleRef
                          : utils.truncateString(_sale._id, 8)}
                      </span>
                      <span>
                        {width! > 500
                          ? utils.convertUtc(_sale.createdAt)
                          : new Date(_sale.createdAt).toLocaleDateString()}
                      </span>
                    </p>
                    {width! > 860 && (
                      <div className={styles.customer}>
                        {/* <span className={styles.thumbnail}>JD</span> */}
                        <div>
                          {/* <p>Type:</p> */}
                          <h3>
                            {_sale.type === "walkin"
                              ? "guest"
                              : _sale.type === "order"
                              ? `${_sale.customer.firstName} ${_sale.customer.lastName}`
                              : "delivery"}
                          </h3>
                        </div>
                      </div>
                    )}
                    <div className={styles.cashier}>
                      {width! > 1000 && (
                        <span className={styles.thumbnail}>
                          {/* {utils.getFirstLetters(
                            `${_sale.staff.firstName} ${_sale.staff.lastName}`
                          )} */}
                          Cashier
                        </span>
                      )}
                      <div>
                        <h3>{`${_sale.staff.firstName} ${_sale.staff.lastName}`}</h3>
                      </div>
                    </div>
                    <div className={styles.amount}>
                      <p>
                        <span>{currency?.symbol || ""}</span>
                        {utils.formatNumber(_sale.amount)}
                      </p>
                    </div>
                    <p className={styles.status}>
                      {_sale.status === "complete" ? "completed" : _sale.status}
                    </p>
                  </div>

                  <AnimatePresence>
                    {sale?._id === _sale._id && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{
                          opacity: 0,
                          height: 0,
                          transition: { duration: 0.1 },
                        }}
                        key={_sale._id}
                        transition={{ type: "tween" }}
                        className={styles.dropdown}
                      >
                        <h1>Details</h1>
                        <div className={styles["payment-methods"]}>
                          <span>
                            Payment Method
                            {sale.paymentMethods?.length > 1 && "s"}:{" "}
                            {sale?.paymentMethods
                              ?.map(
                                (method) =>
                                  `${
                                    method.type === "pos"
                                      ? "debit card"
                                      : method.type
                                  }(${
                                    currency?.symbol || ""
                                  }${utils.formatNumber(method.amount)})`
                              )
                              .join(", ")}
                          </span>
                          {_sale.deliveryDetails && (
                            <span>
                              Delivery Fee: {currency?.symbol || ""}
                              {utils.formatNumber(
                                _sale?.deliveryDetails?.deliveryFee
                              )}
                            </span>
                          )}
                          <span>
                            Discount: {currency?.symbol || ""}
                            {utils.formatNumber(_sale?.discountAmount)}
                          </span>
                          <span>
                            Tax: {currency?.symbol || ""}
                            {utils.formatNumber(_sale?.tax)}
                          </span>
                        </div>
                        <div
                          className={classNames("flex justify-between", {
                            "flex-col-reverseF": width! <= 640,
                          })}
                        >
                          <div className={styles.details}>
                            <ul className={styles.items}>
                              {_sale.items?.map((item, index) => (
                                <li key={item._id}>
                                  <h2>{index + 1}</h2>
                                  <h2>{item.name}</h2>
                                  <h2>{item.quantity}</h2>
                                  <h2 className="whitespace-nowrap">
                                    @ {currency?.symbol || ""}
                                    {utils.formatNumber(item.price)}
                                  </h2>
                                  {/* <p>Incl Tax (VAT)</p> */}
                                  <h2>
                                    {currency?.symbol || ""}
                                    {utils.formatNumber(
                                      item.price * item.quantity
                                    )}
                                  </h2>
                                </li>
                              ))}
                            </ul>
                            <div></div>
                          </div>
                          <div className={styles.actions}>
                            <div onClick={() => completeSale(_sale)}>
                              <icons.ClockArrowIcon />
                              <h2>Complete sale</h2>
                            </div>
                            <div onClick={() => setModalDisplay(true)}>
                              <icons.ReturnIcon />
                              <h2>Return Items</h2>
                            </div>
                            <div onClick={() => handleEmailReceipt(_sale)}>
                              <icons.Mail style={{ fill: "none" }} />
                              <h2>Email Receipt</h2>
                            </div>
                            <div
                              onMouseEnter={() => setSale(_sale)}
                              onClick={handlePrint}
                            >
                              <icons.PrintIcon style={{ fill: "none" }} />
                              <h2>Print Receipt</h2>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
            <PaginationControls
              range={range}
              slice={slice}
              page={page}
              setPage={setPage}
              rows={rows}
              setRows={setRows}
            />
          </>
        )}
      </div>
      <div className="hidden">
        <Receipt sale={sale!} ref={receiptRef} />
      </div>
      <Modal
        styles={styles}
        display={emailReceipt}
        close={() => {
          setEmailReceipt(false);
          setEmail("");
        }}
        title="Email Receipt"
      >
        <form onSubmit={undefined}>
          <fieldset>
            <input
              type="email"
              value={email}
              placeholder="Enter email address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </fieldset>
          <div className={styles.footer}>
            <span
              onClick={() => {
                setEmailReceipt(false);
                setEmail("");
              }}
            >
              Cancel
            </span>
            <button>Send Email</button>
          </div>
        </form>
      </Modal>
      <Modal
        styles={styles}
        display={modalDisplay}
        close={() => {
          setModalDisplay(false);
          setReturnItems(null);
        }}
        title="Return Items"
      >
        <form onSubmit={handleReturnItems}>
          <fieldset>
            <Select
              components={animatedComponents}
              styles={utils.customStyles({ height: "auto" })}
              options={saleOptions}
              placeholder="Items"
              isClearable
              name="riders"
              id="riders"
              isMulti
              onChange={(e) => {
                setReturnItems(e);
              }}
              classNamePrefix="react-select"
            />

            <div className={styles["product-quantity"]}>
              {returnItems?.map((product) => (
                <div>
                  <label htmlFor="">
                    {utils.truncateString(product.value.name, 10)}
                  </label>
                  <input
                    key={product._id}
                    type="text"
                    placeholder="Select quantity"
                    onChange={(e) =>
                      updateProductQuantities(e.target.value, product.value._id)
                    }
                    required
                  />
                </div>
              ))}
            </div>
          </fieldset>
          <div className={styles.footer}>
            <span
              onClick={() => {
                setModalDisplay(false);
                setReturnItems(null);
              }}
            >
              Cancel
            </span>
            <button>Return</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Sales;
