import { useState, useEffect } from "react";

export default function useNetworkStatus() {
  const [state, setState] = useState({
    since: undefined as Date | undefined,
    online: navigator.onLine,
  });

  useEffect(() => {
    function handleOnline() {
      setState({
        online: true,
        since: new Date(),
      });
    }

    function handleOffline() {
      setState({
        online: false,
        since: new Date(),
      });
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return state;
}
