import * as types from "../types";
import { getRiders } from "services/Riders";

export function ridersLoaded(payload) {
  return {
    type: types.RIDERS_LOADED,
    riders: payload,
  };
}

export function updateRiders(payload) {
  return {
    type: types.UPDATE_RIDERS,
    riders: payload,
  };
}

export function clearRiders() {
  return {
    type: types.CLEAR_RIDERS,
  };
}

export function ridersError(bool) {
  return {
    type: types.RIDERS_ERROR,
    bool,
  };
}

export function _getRiders() {
  return (dispatch) => {
    dispatch(ridersError(false));
    getRiders()
      .then((response) => {
        if (response.data.data) {
          dispatch(ridersLoaded(response.data.data.data));
        }
        if (!response.data.data) {
          dispatch(ridersLoaded([]));
        }
      })
      .catch(() => {
        dispatch(ridersError(true));
        dispatch(ridersLoaded(null));
      });
  };
}
