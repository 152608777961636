import React from "react";
import {
  CategoryScale,
  ChartData,
  Chart,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  BarElement,
  ArcElement,
} from "chart.js/auto";
import { Bar, Bubble, Line, Pie } from "react-chartjs-2";

type BarProps = {
  datasetIdKey: string;
  options: ChartOptions<"bar">;
  data: ChartData<"bar">;
};

type LineProps = {
  datasetIdKey: string;
  options: ChartOptions<"line">;
  data: ChartData<"line">;
};

type PieProps = {
  datasetIdKey: string;
  options: ChartOptions<"pie">;
  data: ChartData<"pie">;
};

type BubbleProps = {
  datasetIdKey: string;
  options: ChartOptions<"bubble">;
  data: ChartData<"bubble">;
};

export function BarChart(props: BarProps) {
  Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
  return <Bar {...props} />;
}

export function LineChart(props: LineProps) {
  Chart.register(
    PointElement,
    LineElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
  );
  return <Line {...props} />;
}

export function PieChart(props: PieProps) {
  Chart.register(ArcElement, Tooltip, Legend);
  return <Pie {...props} />;
}

export function BubbleChart(props: BubbleProps) {
  return <Bubble {...props} />;
}
