import * as types from "../types";
import { getProducts } from "services/Inventory";

export function productsLoaded(payload) {
  return {
    type: types.PRODUCTS_LOADED,
    products: payload,
  };
}

export function updateProducts(payload) {
  return {
    type: types.UPDATE_PRODUCTS,
    products: payload,
  };
}

export function clearProducts() {
  return {
    type: types.CLEAR_PRODUCTS,
  };
}

export function productsError(bool) {
  return {
    type: types.PRODUCTS_ERROR,
    bool,
  };
}

export function _getProducts() {
  return (dispatch) => {
    dispatch(productsError(false));
    getProducts()
      .then((response) => {
        if (response.data.data) {
          dispatch(productsLoaded(response.data.data));
        }
        if (!response.data.data) {
          dispatch(productsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(productsError(true));
        // dispatch(productsLoaded(null));
      });
  };
}
