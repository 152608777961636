import React from "react";
import * as utils from "helpers";
import { useSelector } from "react-redux";
import { useExpensesQuery } from "hooks/useExpensesQuery";
import { usePurchasesQuery } from "hooks/usePurchasesQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { IExpense } from "models/expenses";
import { IPurchase } from "models/purchases";
import { Redirect } from "react-router-dom";
import { useSalesQuery } from "hooks/useSalesQuery";
import { format } from "date-fns";
import { LineChart } from "components/Charts";
import Loader from "components/Loader/Loader";
import classNames from "classnames";
import styles from "./Dashboard.module.scss";

export default function Dashboard() {
  const todaysDate = format(new Date(), "yyyy-MM-dd");
  const { data: _settings } = useSettingsQuery();
  const [firstDay] = utils.getFirstAndLastDayOfCurrentMonth();
  const { data: todaysSales } = useSalesQuery({
    startDate: todaysDate,
    endDate: todaysDate,
    limit: 2000,
  });
  const { data: monthlySales } = useSalesQuery({
    startDate: format(new Date(firstDay), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    limit: 5000,
  });
  const { data: _expenses } = useExpensesQuery();
  const { data: _purchases } = usePurchasesQuery();
  const { user } = useSelector((state) => state.auth);
  const currency = _settings?.data.data[0].currency;
  const expenses = _expenses?.data.data.data;
  const purchases = _purchases?.data.data;
  const month = new Date().toLocaleString("en-us", {
    month: "long",
  });
  const chartData = {
    labels: prepareSalesChartData()?.map((item) => item.day),
    datasets: [
      {
        id: 1,
        label: "Days",
        data: prepareSalesChartData()?.map((item) =>
          item.sales
            ?.map((sale) => sale.amount)
            ?.reduce((prev, curr) => prev + curr, 0)
        ),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
        borderColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 159, 64)",
          "rgb(255, 99, 132)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
      },
    ],
  };

  function prepareSalesChartData() {
    if (!monthlySales) return;
    const todaysDate = new Date();
    const dailySales = utils.getCurrentMonthsData(
      monthlySales?.data?.data?.transactions
    );
    let data = [];
    for (let i = 1; i <= todaysDate.getDate(); i++) {
      data.push({
        day: i,
        sales: dailySales
          ?.map((sale) => {
            if (new Date(sale.createdAt).getUTCDate() === i) {
              return sale;
            }
            return null;
          })
          .filter(Boolean),
      });
    }
    return data;
  }

  function getTodaysTotalAmount(data: IExpense[] | IPurchase[]) {
    let todaysData = utils.getTodaysData(data);
    if (todaysData?.length === 0) return;
    todaysData = todaysData
      ?.map((data) => data.amount)
      ?.reduce((prev, curr) => prev + curr);
    return Number(todaysData || 0).toLocaleString();
  }

  if (user.account.role !== "admin") return <Redirect to="/pos" />;

  return (
    <div
      className={classNames(styles.container, {
        "h-[80%]": !expenses || !purchases,
      })}
    >
      {(!expenses || !purchases) && <Loader />}

      {expenses && purchases && todaysSales && (
        <div className={styles["daily-info"]}>
          <h1>Daily Overview</h1>
          <div className={styles.cards}>
            <div className="bg-[#ff8c8c]">
              <h1>
                {currency?.symbol || ""}
                {utils.formatNumber(todaysSales?.data?.data?.totalTransactions)}
              </h1>
              <p>Total sales</p>
            </div>
            <div className="bg-[#e693ff]">
              <h1>
                {utils.formatNumber(
                  todaysSales?.data?.data?.transactions?.length
                )}
              </h1>
              <p>Total transactions</p>
            </div>
            <div className="bg-[#91abff]">
              <h1>
                {currency?.symbol || ""}
                {getTodaysTotalAmount(expenses) || 0}
              </h1>
              <p>Total Expenses</p>
            </div>
            <div className="bg-[#ffc107]">
              <h1>
                {currency?.symbol || ""}
                {getTodaysTotalAmount(purchases) || 0}
              </h1>
              <p>Total Purchases</p>
            </div>
          </div>
          <LineChart
            datasetIdKey="daily-sales"
            data={chartData}
            options={{
              plugins: {
                tooltip: {
                  usePointStyle: true,
                  boxWidth: 5,
                  callbacks: {
                    title: function (context) {
                      const label = context[0].label;
                      const title = `${month} ${label}`;
                      return title;
                    },
                    label: function (context) {
                      let label = "Total sale";

                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed.y !== null) {
                        label += utils
                          .currencyFormatter(currency?.code || ""!)
                          .format(context.parsed.y);
                      }
                      return label;
                    },
                  },
                },
                title: {
                  display: true,
                  text: `Daily Sales Chart for ${month}`,
                },
                legend: {
                  // display: false,
                  position: "bottom",
                  labels: {
                    usePointStyle: true,
                    pointStyle: "circle",
                    padding: 15,
                    boxWidth: 0,
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, ticks) {
                      return `${currency?.symbol || ""}${utils.formatNumber(
                        +value
                      )}`;
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
