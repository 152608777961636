import axios from "axios";
import { IQueryParams } from "models/base";
import { IReceiptPayload, IReceiptSettings } from "models/receipt";

export function getReceiptSettings(params?: IQueryParams) {
  return axios.get<IReceiptSettings>(`/receipt/settings`, {
    params,
  });
}

export function createReceiptSettings(
  payload: IReceiptPayload,
  params: IQueryParams
) {
  return axios.post(`/receipt/settings`, payload, { params });
}

export function updateReceiptSettings(id: string, payload: IReceiptPayload) {
  return axios.patch(`/receipt/settings/${id}`, payload);
}
