import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  memo,
} from "react";
import * as actions from "store/actions";
import * as utils from "helpers";
import * as icons from "assets/svg/icons";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import { useInventoryQuery } from "hooks/useInventoryQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { usePOS } from "hooks/usePOS";
import { IInventory, IVariant } from "models/inventory";
import useWindowDimension from "hooks/useWindowDimension";
import AddCustomer from "views/Admin/Customers/AddCustomer";
import Receipt from "components/Receipt";
import Select from "react-select";
import Button from "components/Elements/Button";
import ControlPanel from "./ControlPanel";
import PageTitle from "components/PageTitle";
import ErrorMessage from "components/ErrorMessage";
import Navbar from "components/Elements/Navbar";
import classNames from "classnames";
import styles from "./POS.module.scss";

export enum ModalTitles {
  AddDelivery = "add delivery",
  SaveOrder = "save order",
  SavedOrders = "saved orders",
  OfflineSales = "offline sales",
  ClearCart = "clear cart",
  Checkout = "checkout",
  EnterQuantity = "enter quantity",
  SelectPrice = "select price",
}

export default memo(function POS() {
  const inputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {
    data: _products,
    isLoading: loadingProducts,
    isError: productsError,
    isRefetching: refetchingProducts,
    refetch: refetchProducts,
  } = useInventoryQuery();
  const { data: settings } = useSettingsQuery();
  const {
    addProductToCart,
    removeProduct,
    increment,
    decrement,
    deleteSavedOrder,
    setMeasurementQuantity,
    calculateTax,
    // newOrder,
    saveOrder,
  } = usePOS();
  const { width } = useWindowDimension();
  const { user, subscriptionDaysLeft, branch } = useSelector(
    (state) => state.auth
  );
  const { savedOrders, offlineSales, cart } = useSelector((state) => state.pos);
  const { customers } = useSelector((state) => state.customers);
  const { reports } = useSelector((state) => state.reports);
  const [products, setProducts] = useState<IInventory[] | null | undefined>(
    null
  );
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [product, setProduct] = useState<IInventory | null>(null);
  const [variant, setVariant] = useState<IVariant | null>(null);
  const [variants, setVariants] = useState<IVariant[] | null>(null);
  const [variantQty, setVariantQty] = useState(1);
  const [addCustomer, setAddCustomer] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [scanMode, setScanMode] = useState(false);
  const tax = settings?.data.data[0].tax;
  const currency = settings?.data.data[0].currency;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const customerOptions = useMemo(
    () =>
      customers
        ? customers?.map((customer) => {
            return {
              value: customer,
              label: `${customer.firstName} ${customer.lastName}`,
            };
          })
        : [],
    [customers]
  );
  const customStyles = {
    container: (provided: { [key: string]: string }) => ({
      ...provided,
      flexShrink: 0,
      position: "relative",
      minWidth: "10rem",
      maxWidth: "15rem",
      width: "60%",
      backgroundColor: "#ffffff",
    }),
    control: () => ({
      display: "flex",
      border: "1px solid #ccc",
      height: 29,
      borderRadius: 0,
    }),
    menuList: (provided: { [key: string]: string }) => ({
      ...provided,
      textTransform: "capitalize",
    }),
    input: (provided: { [key: string]: string }) => ({
      ...provided,
      margin: 0,
    }),
    singleValue: (provided: { [key: string]: string }) => ({
      ...provided,
      textTransform: "capitalize",
      margin: 0,
    }),
    menu: (provided: { [key: string]: string }) => ({
      ...provided,
      fontSize: ".8rem",
      color: "#000000",
    }),
    valueContainer: (provided: { [key: string]: string }) => ({
      ...provided,
      fontSize: ".8rem",
    }),
  };
  function clearInput() {
    setSearchInput("");
    setSelectedCategory("");
    setProducts(
      _products!.data.data.data
        .filter((product) => !product.hideFromPOS)
        .reverse()
    );
  }

  function closeModal() {
    setModalDisplay(false);
    setModalTitle("");
    setProduct(null);
    setVariant(null);
    setVariantQty(1);
    if (scanMode && inputRef.current) inputRef.current.focus();
  }

  function addToCart(product: IInventory) {
    if (product.variants) {
      setModalDisplay(true);
      setProduct(product);
      setVariants(product.variantItems);
      setModalTitle(product.name);
      return;
    }
    if (product.type === "measurement") {
      setModalDisplay(true);
      setProduct(product);
      setModalTitle(ModalTitles.EnterQuantity);
      return;
    }
    if (product.priceLevels?.length! > 0) {
      setModalDisplay(true);
      setProduct(product);
      setModalTitle(ModalTitles.SelectPrice);
      return;
    }
    addProductToCart(product, closeModal);
  }

  function addVariantToCart(variant: IVariant | null) {
    if (!variant) {
      toast.warning("Select Variant", {
        autoClose: 500,
        hideProgressBar: true,
        toastId: "select-variant",
      });
      return;
    }
    addProductToCart(
      { ...variant, name: `${product?.name} - ${variant.name}` },
      closeModal,
      variant,
      variantQty
    );
  }

  function searchProducts(e: ChangeEvent<HTMLInputElement>) {
    setSelectedCategory("");
    const { value } = e.target;
    setSearchInput(value);
    const filteredProducts = _products?.data.data.data.filter((product) => {
      if (value.trim() === "") return product;
      if (
        (product.name.toLowerCase().includes(value.toLowerCase()) ||
          product.barcode?.includes(value)) &&
        !product.hideFromPOS
      )
        return product;
      return null;
    });
    setProducts(filteredProducts);
  }

  function selectCategory(e: ChangeEvent<HTMLSelectElement>) {
    setSearchInput("");
    const { value } = e.target;
    setSelectedCategory(value);
    const filteredProducts = _products?.data.data.data.filter((product) => {
      if (value.trim() === "") return product;
      if (
        product.category?.toLowerCase().includes(value.toLowerCase()) &&
        !product.hideFromPOS
      )
        return product;
      return null;
    });
    setProducts(filteredProducts);
  }

  function showSavedOrders() {
    if (!savedOrders) {
      toast.info("No saved orders", { toastId: "show-saved-orders" });
      return;
    }
    setModalDisplay(true);
    setModalTitle(ModalTitles.SavedOrders);
  }

  function showOfflineSales() {
    if (!offlineSales) {
      toast.info("No offline sales", { toastId: "no-offline-sales" });
      return;
    }
    setModalDisplay(true);
    setModalTitle(ModalTitles.OfflineSales);
  }

  function addRider() {
    if (!cart.items) {
      toast.info("Add Items to cart!", { toastId: "add-rider" });
      return;
    }
    dispatch(actions._getRiders());
    setModalDisplay(true);
    setModalTitle(ModalTitles.AddDelivery);
  }

  function scanProduct(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearchInput(value);
    const product = _products?.data.data.data.find(
      (product) => product.barcode === value
    );
    if (product) {
      // toast.warning("Not found");
      // return;
      addToCart(product);
    }
  }

  function handleCheckout() {
    dispatch(
      actions.updateCart({
        ...cart,
        createdAt: new Date(),
        tax: calculateTax(cart.grandTotal),
      })
    );
    setScanMode(false);
    if (cart.items?.length === 0) {
      utils.notify("Cart is empty!", "info");
      return;
    }
    if (user.sageEnabled && cart.customer.value === "guest") {
      utils.notify("Select customer", "info");
      return;
    }
    if (cart.customer?.value === "guest" && cart.rider) {
      toast.info(
        "Select a customer to receive delivery or remove rider to continue",
        {
          toastId: "select-customer",
        }
      );
      return;
    }
    setModalDisplay(true);
    if (cart.customer.value === "guest" || !cart.customer)
      setModalTitle(`${ModalTitles.Checkout} (guest sale)`);
    if (cart.customer.value !== "guest")
      setModalTitle(`${ModalTitles.Checkout} (${cart.customer?.label})`);
    // if ((cart.customer.value === "guest" || !cart.customer) && cart.rider)
    //   setModalTitle(`checkout (guest sale - delivery)`);
    if (cart.customer.value !== "guest" && cart.rider)
      setModalTitle(`checkout (${cart.customer?.label} - delivery)`);
  }

  useEffect(() => {
    if (_products)
      setProducts(
        _products.data.data.data
          .filter((product) => !product.hideFromPOS)
          .reverse()
      );
  }, [_products]);

  useEffect(() => {
    dispatch(actions._getCustomers(branch?._id));
    dispatch(
      actions.updateCustomer({ value: "guest", label: "walk-in customer" })
    );
    dispatch(actions.updateRider(null));
  }, []);

  useEffect(() => {
    if (scanMode && inputRef.current) inputRef.current.focus();
  }, [scanMode]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (scanMode)
      timer = setTimeout(() => {
        setSearchInput("");
      }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["nav-bar"]}>
          <Navbar title={width! > 540 ? "point of sales" : "pos"} />
        </div>
        <div
          style={{
            maxWidth: "1600px",
            marginInline: "auto",
            marginTop:
              (!utils.activeSubscription(reports) && utils.isAdmin(user)) ||
              (subscriptionDaysLeft <= 7 && utils.isAdmin(user))
                ? "8.5rem"
                : "6.5rem",
          }}
        >
          {productsError && !products && (
            <ErrorMessage className="my-[30%]" message="An error occurred" />
          )}

          {_products && _products.data.data.data.length > 0 && (
            <header>
              <div>
                <Button onClick={() => setScanMode(true)}>Scan To Cart</Button>
                <button onClick={addRider}>
                  <icons.TruckIcon />
                  Add Delivery
                </button>
                {/* <button onClick={newOrder}>
                  <icons.CartIcon />
                  New Order
                </button> */}
                <button onClick={showSavedOrders}>
                  <icons.DocumentDuplicateIcon />
                  Saved Orders
                </button>
                <button onClick={showOfflineSales}>
                  <icons.OfflineIcon />
                  Offline Sales
                </button>
                <button onClick={() => refetchProducts()}>
                  <icons.Reload
                    className={refetchingProducts ? styles.rotate : null}
                  />
                  Refresh
                </button>
              </div>
            </header>
          )}
          {_products && _products.data.data.data.length > 0 && (
            <div className={styles["form-group"]}>
              <div>
                <input
                  type="text"
                  placeholder="Search for product"
                  value={searchInput}
                  onChange={searchProducts}
                />
                {searchInput && <icons.CloseIcon onClick={clearInput} />}
              </div>
              <div>
                <select
                  name="product-category"
                  id="product-category"
                  value={selectedCategory}
                  onChange={selectCategory}
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {settings?.data.data[0].inventoryCategories?.map(
                    (category, index) => (
                      <option value={category.name} key={index}>
                        {category.name}
                      </option>
                    )
                  )}
                </select>
                {selectedCategory !== "" && (
                  <icons.CloseIcon onClick={clearInput} />
                )}
              </div>
            </div>
          )}
          <div className={styles.main}>
            <div
              className={classNames(styles["products-container"], {
                "!w-full": !cart.items,
              })}
            >
              <div
                className={classNames(styles.products, {
                  [styles.search]:
                    searchInput.trim() !== "" ||
                    selectedCategory.trim() !== "" ||
                    products?.length! < 8,
                })}
                style={
                  products?.length === 0
                    ? { height: "55vh" }
                    : { marginBottom: width! < 590 ? "30rem" : "" }
                }
              >
                {loadingProducts &&
                  !_products &&
                  Array(8)
                    .fill(undefined)
                    ?.map((_, index) => (
                      <div key={index} className={styles["product-loading"]}>
                        <div className={styles["product-image"]} />
                        <div className={styles["product-name"]}>
                          <h3 />
                        </div>
                        <span />
                      </div>
                    ))}

                {products?.length === 0 && !loadingProducts && (
                  <ErrorMessage
                    message={
                      searchInput || selectedCategory
                        ? "Not found"
                        : `There are no products, go to inventory to add products`
                    }
                  />
                )}
                {products &&
                  !loadingProducts &&
                  products?.length > 0 &&
                  products?.map((product) => (
                    <div
                      key={product._id}
                      className={`${styles.product} ${
                        product.type === "product" && styles["product-quantity"]
                      } ${product.lowStock && styles["low-quantity"]}`}
                      onClick={() => addToCart(product)}
                      data-quantity={
                        product.variants && product.variantItems?.length > 0
                          ? product.variantItems
                              .map((item) => item.stock?.quantity)
                              ?.reduce((prev, curr) => prev + curr)
                          : product.stock?.quantity
                      }
                    >
                      <div
                        className={classNames(styles["product-image"], {
                          "border border-[#eeeeee]": !product.image?.link,
                        })}
                      >
                        <span>
                          {utils.truncateString(
                            utils.getFirstLetters(product.name),
                            width! > 480 ? 100 : 7
                          )}
                        </span>
                        {product.image?.link && (
                          <img src={product.image?.link} alt="" />
                        )}
                      </div>
                      <div className={styles["product-name"]}>
                        <h3>{utils.truncateString(product.name, 20)}</h3>
                      </div>
                      {(product.priceLevels?.length === 0 ||
                        !product.priceLevels) && (
                        <span>
                          {product.variants
                            ? `${product.variantItems.length} ${
                                product.variantItems.length === 1
                                  ? "Variant"
                                  : "Variants"
                              }`
                            : `${currency?.code || ""}${
                                product.price?.selling ?? 0
                              }`}
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            {!cart.items && (
              <div
                className={styles.banner}
                onClick={() =>
                  dispatch(
                    actions.updateCart({
                      ...cart,
                      items: [],
                      grandTotal: 0,
                      totalItems: 0,
                      totalQty: 0,
                    })
                  )
                }
              >
                <icons.CartIcon />
              </div>
            )}

            <AnimatePresence>
              {cart.items && (
                <motion.div
                  initial={{ y: "100%" }}
                  animate={{ y: 0 }}
                  exit={{ y: "120%", transition: { duration: 0.3 } }}
                  transition={{ duration: 0.3 }}
                  className={styles.cart}
                  style={{ right: width! > 1600 ? (width! - 1600) / 2 : 0 }}
                  onMouseMove={() =>
                    scanMode && inputRef.current
                      ? inputRef.current.focus()
                      : null
                  }
                >
                  <div className={styles.header}>
                    <icons.PlusCircleIcon
                      onClick={() => setAddCustomer(true)}
                    />
                    <Select
                      styles={customStyles}
                      options={[
                        { value: "guest", label: "walk-in customer" },

                        ...customerOptions,
                      ]}
                      placeholder="Select Customer"
                      isClearable
                      name="customers"
                      id="customers"
                      value={cart.customer}
                      isLoading={!customers}
                      onChange={(e) => {
                        dispatch(actions.updateCustomer(e));
                      }}
                      classNamePrefix="react-select"
                    />
                    <p>Tax: {`${currency?.symbol ?? ""}${cart.tax}`} </p>
                  </div>
                  <div className={styles.items}>
                    <div className={styles["order-details"]}>
                      <p>
                        Cashier:{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {utils.truncateString(
                            `${user.firstName} ${user.lastName}`,
                            width! > 480 ? 100 : 7
                          )}
                        </span>
                      </p>
                      <p style={{ marginLeft: "auto", width: "4rem" }}>
                        {cart.totalItems === 1 || cart.totalItems === null
                          ? "Item"
                          : "Items"}
                        : <span>{cart.totalItems}</span>
                      </p>
                      <p style={{ width: "3rem" }}>
                        Qty: <span>{cart.totalQty}</span>
                      </p>
                    </div>
                    {cart.delivery && cart.rider && (
                      <div
                        className={classNames(
                          styles["order-details"],
                          "gap-2 border-t border-gray-400 border-solid"
                        )}
                      >
                        <p>
                          Rider:{" "}
                          <span className="capitalize">
                            {utils.truncateString(
                              cart.rider.value.fullName,
                              width! > 480 ? 100 : 7
                            )}
                          </span>
                        </p>
                        <p className="!ml-auto">
                          Location:{" "}
                          <span>
                            {utils.truncateString(
                              cart.delivery.value.location.name,
                              6
                            )}
                          </span>
                        </p>
                        <p>
                          Fee:{" "}
                          <span>
                            {" "}
                            {utils.formatNumber(
                              cart.delivery.value.deliveryFee
                            )}
                          </span>
                        </p>
                      </div>
                    )}
                    <div>
                      <div className={styles.main}>
                        {cart?.items?.map((item, index) => (
                          <div key={index} className={styles.item}>
                            <div className={styles.description}>
                              <h1>
                                {utils.truncateString(
                                  item.name,
                                  width! > 480 ? 100 : 14
                                )}
                              </h1>
                              <p className={styles.qty}>
                                Qty:{" "}
                                <span>
                                  {item.qty}
                                  {utils.truncateString(
                                    item.measurementType,
                                    3
                                  )}
                                </span>
                              </p>
                              <p>
                                Amt:{" "}
                                <span>
                                  {currency?.symbol || ""}
                                  {utils.formatNumber(item.totalPrice)}
                                </span>
                              </p>
                            </div>
                            <div className={styles.controls}>
                              {item?.type === "measurement" && (
                                <input
                                  type="number"
                                  value={Number(item.qty).toString()}
                                  step=".01"
                                  onChange={(e) =>
                                    setMeasurementQuantity({
                                      ...item,
                                      qty: +(
                                        e.target.valueAsNumber || 0
                                      ).toFixed(2),
                                      totalPrice:
                                        +e.target.value * item.price.selling,
                                    })
                                  }
                                />
                              )}
                              <icons.PlusCircleIcon
                                onClick={() => increment(item)}
                              />
                              <icons.MinusCircleIcon
                                onClick={() => decrement(item)}
                              />
                              <icons.XCircleIcon
                                onClick={() => removeProduct(item)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className={styles.actions}>
                        <button onClick={handleCheckout}>
                          Checkout {currency?.symbol || ""}
                          {utils.formatNumber(cart.grandTotal)}
                        </button>
                        <button
                          onClick={() => {
                            if (cart.items?.length === 0) {
                              toast.warning("Cart is empty!", {
                                autoClose: 500,
                                hideProgressBar: true,
                                toastId: "cart-is-empty",
                              });
                              return;
                            }
                            setModalDisplay(true);
                            setModalTitle("save order");
                          }}
                        >
                          Save Order
                        </button>
                        <button onClick={handlePrint}>Print Order</button>
                        <button
                          onClick={() => {
                            if (cart.items?.length === 0) {
                              dispatch(actions.clearCart());
                              // toast.warning("Cart is empty!", {
                              //   autoClose: 500,
                              //   hideProgressBar: true,
                              // toastId: "cart-is-empty",
                              // });
                              return;
                            }
                            setModalDisplay(true);
                            setModalTitle("clear cart");
                          }}
                        >
                          {cart.items?.length === 0 ? "Close" : " Clear Cart"}
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <AnimatePresence>
          {scanMode && (
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "tween" }}
              className={styles["scan-mode"]}
              onClick={() => inputRef.current && inputRef.current.focus()}
              onMouseMove={() => inputRef.current && inputRef.current.focus()}
            >
              <div
                style={{
                  maxWidth: "1600px",
                  marginInline: "auto",
                  position: "relative",
                  height: "100%",
                }}
              >
                <PageTitle title="Scanner" />
                <div className={classNames({ "!w-full": !cart.items })}>
                  <icons.CloseIcon
                    onClick={() => {
                      setScanMode(false);
                      setSearchInput("");
                    }}
                  />
                  <div className={styles.ping} />
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search Products"
                    value={searchInput}
                    onChange={scanProduct}
                  />
                  <h1>Start Scanning</h1>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div style={{ display: "none" }}>
        <Receipt ref={componentRef} bill />
      </div>
      <ControlPanel
        title={modalTitle}
        display={modalDisplay}
        product={product}
        addVariantToCart={() => addVariantToCart(variant)}
        variants={variants}
        variant={variant}
        setVariant={(variant) => setVariant(variant)}
        saveOrder={(customerName) => saveOrder(customerName, closeModal)}
        close={closeModal}
        deleteSavedOrder={(id) => deleteSavedOrder(id, closeModal)}
      />
      <AddCustomer display={addCustomer} close={() => setAddCustomer(false)} />
    </>
  );
});
