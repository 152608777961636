import { useQuery } from "@tanstack/react-query";
import { IQueryParams } from "models/base";
import { getBranches } from "services/branches";

export function useBranchesQuery(params?: IQueryParams) {
  return useQuery({
    queryKey: [`/branches`, params],
    queryFn: () => getBranches(params),
    refetchOnWindowFocus: false,
  });
}
