import React, { useState } from "react";
import * as actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { EyeOn, EyeOff } from "assets/svg/icons";
import useWindowDimension from "hooks/useWindowDimension";
import Button from "components/Elements/Button";
import Logo from "components/Elements/Logo";
import Slideshow from "components/Slideshow";
import styles from "./Login.module.scss";

export default function Login() {
  const dispatch = useDispatch();
  const { width } = useWindowDimension();
  const { loading } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const { loginAs } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");

  function handleSubmit(e) {
    e.preventDefault();
    if (loginAs === "business")
      dispatch(actions._loginBusiness({ email, password }));
    if (loginAs === "staff") dispatch(actions._loginStaff({ email, password }));
    if (loginAs === "customer")
      dispatch(actions._loginCustomer({ email, password }));
  }

  if (token) {
    if (user.account.role === "admin") return <Redirect to="/dashboard" />;
    if (user.account.role !== "admin") return <Redirect to="/pos" />;
  }

  return (
    <div className={styles.container}>
      <Logo />
      <form onSubmit={handleSubmit}>
        <h1>
          {loginAs === "business" && "Business "}
          {loginAs === "staff" && "Staff "}
          {loginAs === "customer" && "Customer "}
          Login
        </h1>
        <p>Welcome to One flare inventory solution</p>
        {/* <h6 style={{ color: "var(--accent-color)" }}>
          {loginAs === "business" && "Business"}
          {loginAs === "staff" && "Staff"}
          {loginAs === "customer" && "Customer"} Login
        </h6> */}
        <fieldset>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </fieldset>
        <fieldset>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type={inputType}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {inputType === "password" && (
            <EyeOn onClick={() => setInputType("text")} />
          )}
          {inputType === "text" && (
            <EyeOff onClick={() => setInputType("password")} />
          )}
        </fieldset>
        <p>
          Login as a{" "}
          {loginAs !== "staff" && (
            <span onClick={() => dispatch(actions.loginAsStaff())}>staff</span>
          )}
          {loginAs !== "business" && (
            <>
              {loginAs !== "staff" && " or "}
              <span onClick={() => dispatch(actions.loginAsBusiness())}>
                business
              </span>
            </>
          )}
          {loginAs !== "customer" && (
            <>
              {/* {" or "} */}
              <span onClick={() => dispatch(actions.loginAsCustomer())}>
                {/* customer */}
              </span>
            </>
          )}
        </p>
        <Link to="/password/recovery">Forgot Password</Link>
        <Button>
          {loading ? <ClipLoader size={12} color="#ffffff" /> : "Login"}
        </Button>
        <p style={{ textAlign: "center", margin: 0 }}>
          Don't have an account? <Link to="/register">Sign Up</Link>
        </p>
      </form>
      {width > 1045 && (
        <div>
          <Slideshow />
        </div>
      )}
    </div>
  );
}
