import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import * as icons from "assets/svg/icons";
import styles from "./PaginationControls.module.scss";

type Props = {
  range: number[];
  slice: { [key: string]: any };
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rows: number;
  setRows: (value: number) => void;
};

export default function PaginationControls({
  range,
  slice,
  page,
  setPage,
  rows,
  setRows,
}: Props) {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (slice?.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  return (
    <form className={styles.container}>
      <fieldset>
        <icons.StepBackward2 onClick={() => setPage(1)} />
        <icons.StepBackward
          onClick={() => page > 1 && setPage((page) => page - 1)}
        />
        <icons.StepForward
          onClick={() => page !== range.length && setPage((page) => page + 1)}
        />
        <icons.StepForward2 onClick={() => setPage(range.length)} />
      </fieldset>
      <fieldset>
        Page{" "}
        <strong>
          {page} of {range.length}
        </strong>
      </fieldset>
      <fieldset>
        <label htmlFor="rows">Go to page:</label>
        <input
          type="number"
          min={1}
          value={Number(pageNumber).toString()}
          onChange={(e) => setPageNumber(+e.target.value)}
        />
        <button
          type="button"
          onClick={() =>
            pageNumber >= 1 && pageNumber <= range.length && setPage(pageNumber)
          }
        >
          Go
        </button>
      </fieldset>
      <fieldset>
        <label htmlFor="rows">Rows per page:</label>
        <select
          name="rows"
          id="rows"
          value={rows}
          onChange={(e) => setRows(+e.target.value)}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </fieldset>
    </form>
  );
}
