import * as types from "../types";

const INITIAL_STATE = {
  data: null,
  dataCopy: null,
  error: false,
};

export default function staffs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.STAFFS_ERROR:
      return {
        ...state,
        error: action.bool,
      };
    case types.CLEAR_STAFFS:
      return {
        data: null,
        dataCopy: null,
        error: false,
      };
    case types.STAFFS_LOADED:
      return {
        ...state,
        data: action.staffs,
        dataCopy: action.staffs,
      };
    case types.UPDATE_STAFFS:
      return {
        ...state,
        data: action.staffs,
      };
    default:
      return state;
  }
}
