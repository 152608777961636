import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { IQueryParams } from "models/base";
import { getCustomers } from "services/customers";

export function useCustomersQuery(params?: IQueryParams) {
  const { _id = "" } = useSelector((state) => state.auth.branch) || {};
  return useQuery({
    queryKey: [`/customers`, { ...params, branchId: _id }],
    queryFn: () => getCustomers({ ...params, branchId: _id }),
    refetchOnWindowFocus: false,
  });
}
