import * as types from "../types";
import { getStaffs } from "services/Staffs";

export function staffsLoaded(payload) {
  return {
    type: types.STAFFS_LOADED,
    staffs: payload,
  };
}

export function updateStaffs(payload) {
  return {
    type: types.UPDATE_STAFFS,
    staffs: payload,
  };
}

export function clearStaffs() {
  return {
    type: types.CLEAR_STAFFS,
  };
}

export function staffsError(bool) {
  return {
    type: types.STAFFS_ERROR,
    bool,
  };
}

export function _getStaffs() {
  return (dispatch) => {
    dispatch(staffsError(false));
    getStaffs()
      .then((response) => {
        if (response.data.data) {
          dispatch(staffsLoaded(response.data.data.data));
          return;
        }
        if (!response.data.data) {
          dispatch(staffsLoaded([]));
        }
      })
      .catch(() => {
        dispatch(staffsError(true));
        dispatch(staffsLoaded(null));
      });
  };
}
