import React, { FormEvent, useState } from "react";
import { ISale } from "models/sales";
import { deleteSale } from "services/Sale";
import { notify } from "helpers";

type Props = {
  sale: ISale;
  cancel: () => void;
};

export default function DeleteSale({ sale, cancel }: Props) {
  const [password, setPassword] = useState("");

  function handleDeleteSale(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    deleteSale(sale._id, password).then(({ data }) => {
      cancel();
      notify(data.message, "success");
    });
    //   .catch(() => setLoading(false));
  }

  return (
    <form onSubmit={handleDeleteSale}>
      <div className="text-[0.9rem]">
        <input
          type="password"
          name="password"
          id="password"
          placeholder="Enter admin password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="border border-solid border-[#cccccc] w-[80%] h-9 mx-auto my-8 p-4 block"
          required
        />
      </div>
      <div className="flex gap-4 p-[0.8rem] border-t border-solid border-[#cccccc]">
        <button
          type="button"
          onClick={cancel}
          className="text-black text-[0.9rem] w-16 py-[0.1rem] px-2 ml-auto border border-solid border-[#cccccc]"
        >
          Cancel
        </button>
        <button className="text-white text-[0.9rem] w-16 py-[0.1rem] px-2 border border-solid border-red-500 bg-red-500">
          Delete
        </button>
      </div>
    </form>
  );
}
