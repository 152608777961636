import * as types from "../types";

export function clearCart() {
  return {
    type: types.CLEAR_CART,
  };
}

export function updateCart(payload) {
  return {
    type: types.UPDATE_CART,
    cart: payload,
  };
}

export function updateCustomer(payload) {
  return {
    type: types.UPDATE_CUSTOMER,
    customer: payload,
  };
}

export function updateRider(payload) {
  return {
    type: types.UPDATE_RIDER,
    rider: payload,
  };
}

export function updateDelivery(payload) {
  return {
    type: types.UPDATE_DELIVERY,
    delivery: payload,
  };
}

export function discountAmount(payload) {
  return {
    type: types.DISCOUNT_AMOUNT,
    discount: payload,
  };
}

export function discountPercent(payload) {
  return {
    type: types.DISCOUNT_PERCENT,
    discount: payload,
  };
}

export function clearSavedOrders() {
  return {
    type: types.CLEAR_SAVED_ORDERS,
  };
}

export function updateSavedOrders(payload) {
  return {
    type: types.UPDATE_SAVED_ORDERS,
    savedOrders: payload,
  };
}

export function clearOfflineSales() {
  return {
    type: types.CLEAR_OFFLINE_ORDERS,
  };
}

export function updateOfflineSales(payload) {
  return {
    type: types.UPDATE_OFFLINE_ORDERS,
    offlineSales: payload,
  };
}
