import React, { useState, useEffect, ChangeEvent } from "react";
import * as actions from "store/actions";
import { useSelector, useDispatch } from "react-redux";
import { useInventoryQuery } from "hooks/useInventoryQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { motion, AnimatePresence } from "framer-motion";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { updateSettings } from "services/Settings";
import {
  createProduct,
  deleteProduct,
  updateProduct,
} from "services/Inventory";
import { Widget } from "@uploadcare/react-widget";
import { customStyles, formatNumber, notify, truncateString } from "helpers";
import { ClipLoader } from "react-spinners";
import { IInventory } from "models/inventory";
import Modal from "components/Elements/Modal";
import ButtonToggle from "components/Elements/ButtonToggle";
import NumberInput from "components/Inputs/NumberInput";
import classNames from "classnames";
import Button from "components/Elements/Button";
import Select from "react-select";
import NoImagePlaceholder from "assets/images/no-image-placeholder.png";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import styles from "assets/styles/ActionModals.module.scss";

type Props = {
  display: boolean;
  close: () => void;
  product?: IInventory;
};

export default function AddProduct({ display, close, product }: Props) {
  const dispatch = useDispatch();
  const { user, branch } = useSelector((state) => state.auth);
  const { refetch: refetchProducts } = useInventoryQuery();
  const {
    data,
    isLoading: loadingSettings,
    refetch: refetchSettings,
  } = useSettingsQuery();
  const { data: branches } = useSelector((state) => state.branches);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [productMultiPrices, setProductMultiPrices] = useState(false);
  const [productVariant, setProductVariant] = useState(false);
  const [editProductMultiPrice, setEditProductMultiPrice] = useState(false);
  const [editProductVariant, setEditProductVariant] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [addedMultiPrices, setAddedMultiPrices] = useState([]);
  const [addedVariants, setAddedVariants] = useState([]);
  const [addNewProductCategory, setAddNewProductCategory] = useState(false);
  const settings = data?.data.data[0];
  const defaultProductForm = {
    type: "product",
    name: "",
    description: "",
    category: "",
    costPrice: "",
    sellingPrice: "",
    perQuantity: "",
    measurementType: "",
    unitOfMeasure: "",
    accountSetCode: "",
    structureCode: "",
    key: "",
    units: "",
    quantityPerUnit: 0,
    quantity: "",
    image: "",
    stockQuantity: "",
    newProductCategory: "",
    sku: "",
    branch: "",
    expiryDate: "",
    lowStock: "",
  };
  const [productForm, setProductForm] =
    useState<typeof defaultProductForm>(defaultProductForm);
  const [multiPriceForm, setMultiPriceForm] = useState({
    _id: "",
    name: "",
    price: "",
    unitOfMeasure: "",
    quantity: "",
    key: "",
  });
  const [variantForm, setVariantForm] = useState({
    _id: "",
    name: "",
    costPrice: "",
    sellingPrice: "",
    quantity: "",
    expiryDate: "",
    lowStock: "",
  });
  const isProduct = productForm.type === "product";
  const isService = productForm.type === "service";
  const isMeasurement = productForm.type === "measurement";
  const categoryOptions = settings?.inventoryCategories
    ?.map((category) => ({
      value: category.name,
      label: category.name,
    }))
    .reverse();
  const unitOptions = settings?.inventoryUnitsOfMeasure
    ? settings?.inventoryUnitsOfMeasure
        ?.map((category) => ({
          value: category.name,
          label: category.name,
        }))
        .reverse()
    : [];
  const setCode = settings?.inventoryAccountSetCode
    ? settings?.inventoryAccountSetCode
        ?.map((category) => ({
          value: category.name,
          label: category.name,
        }))
        .reverse()
    : [];
  const structureCode = settings?.inventoryStructureCode
    ? settings?.inventoryStructureCode
        ?.map((category) => ({
          value: category.name,
          label: category.name,
        }))
        .reverse()
    : [];
  const typeOptions = [
    { value: "product", label: "product" },
    { value: "service", label: "service" },
    { value: "measurement", label: "measurement" },
  ];

  const payload = {
    businessRef: user.businessRef,
    ref: user.businessRef,
    type: productForm.type,
    name: productForm.name,
    description: productForm.description,
    category: productForm.category,
    image: { link: productForm.image, description: productForm.name },
    barcode: productForm.sku,
    price: {
      cost: productForm.costPrice,
      selling: productForm.sellingPrice,
    },
    ...(productForm.measurementType && {
      measurementType: productForm.measurementType,
    }),
    stock: {
      units: productForm.units,
      perQty: productForm.perQuantity,
      quantity: productForm.quantity,
    },
    lowStock: productForm.lowStock.value,
    expiryDate: productForm.expiryDate
      ? format(new Date(productForm.expiryDate), "MM-dd-yyyy")
      : "",
    variants: productVariant,
    variantItems: productVariant
      ? addedVariants?.map((variant) => ({
          ...(!variant._id?.startsWith("id:") && variant),
          name: variant.name,
          category: productForm.category,
          description: productForm.description,
          price: { selling: variant.sellingPrice, cost: variant.costPrice },
          stock: {
            quantity: variant.quantity,
            units: productForm.units,
          },
          image: { link: productForm.image, description: productForm.name },
          lowStock: variant.lowStock.value,
          expiryDate: variant.expiryDate
            ? format(new Date(variant.expiryDate), "MM-dd-yyyy")
            : "",
        }))
      : null,
    priceLevels: productMultiPrices
      ? addedMultiPrices.map((priceLevel) => ({
          name: priceLevel.name,
          price: priceLevel.price,
          unitOfMeasure: priceLevel.unitOfMeasure,
          quantity: priceLevel.quantity,
          priceListCodeKey: priceLevel.key,
        }))
      : null,
    ...(user.sageEnabled && {
      UnitsOfMeasure: productForm.unitOfMeasure,
      AccountSetCode: productForm.accountSetCode,
      StructureCode: productForm.structureCode,
    }),
  };

  function updateMultiPriceForm(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setMultiPriceForm({
      ...multiPriceForm,
      [name]: value,
    });
  }

  function updateVariantForm(e: ChangeEvent<HTMLInputElement>) {
    const { value, name } = e.target;
    setVariantForm({
      ...variantForm,
      [name]: value,
    });
  }

  function updateForm(e) {
    const { value, name } = e.target;
    setProductForm({
      ...productForm,
      [name]: value,
    });
  }

  function uploadImage(e) {
    const { cdnUrl } = e;
    setProductForm({
      ...productForm,
      image: cdnUrl,
    });
  }

  function clearMultiPriceForm() {
    setMultiPriceForm({
      _id: "",
      name: "",
      price: "",
      unitOfMeasure: "",
      quantity: "",
      key: "",
    });
  }

  function clearVariantForm() {
    setVariantForm({
      _id: "",
      name: "",
      costPrice: "",
      sellingPrice: "",
      quantity: "",
      expiryDate: "",
      lowStock: "",
    });
  }

  function addPriceLevel(_id: string) {
    if (
      multiPriceForm.name === "" ||
      multiPriceForm.quantity === "" ||
      multiPriceForm.price === "" ||
      multiPriceForm.unitOfMeasure === ""
    ) {
      notify("Fill all required fields!", "error");
      return;
    }
    const priceExists = addedMultiPrices.find((price) => price._id === _id);
    if (priceExists) {
      let updatedPrices = [...addedMultiPrices];
      updatedPrices = updatedPrices?.map((price) => {
        if (price._id === _id) {
          return {
            name: multiPriceForm.name,
            price: multiPriceForm.price,
            unitOfMeasure: multiPriceForm.unitOfMeasure,
            quantity: multiPriceForm.quantity,
            key: multiPriceForm.key,
          };
        }
        return price;
      });
      setAddedMultiPrices(updatedPrices);
    }
    if (!priceExists) {
      setAddedMultiPrices([...addedMultiPrices, multiPriceForm]);
    }
    setEditProductMultiPrice(false);
    clearMultiPriceForm();
  }

  function addVariant(_id: string) {
    if (
      variantForm.name === "" ||
      variantForm.quantity === "" ||
      variantForm.sellingPrice === ""
    ) {
      notify("Fill all required fields!", "error");
      return;
    }
    const variantExists = addedVariants.find(
      (_variant) => _variant._id === _id
    );

    if (variantExists) {
      let updatedVariants = [...addedVariants];
      updatedVariants = updatedVariants?.map((_variant) => {
        if (_variant._id === _id) {
          return {
            name: variantForm.name,
            costPrice: variantForm.costPrice,
            sellingPrice: variantForm.sellingPrice,
            quantity: variantForm.quantity,
            lowStock: variantForm.lowStock.value,
            expiryDate: variantForm.expiryDate,
          };
        } else {
          return _variant;
        }
      });
      setAddedVariants(updatedVariants);
      setEditProductVariant(false);
      clearVariantForm();
      return;
    }
    setAddedVariants([...addedVariants, { ...variantForm, _id }]);
    // setProductForm({
    //   ...productForm,
    //   variants: [...productForm.variants, addedVariants],
    // });
    clearVariantForm();
  }

  // function updateMultiPriceLevel(_id) {
  //   const updatedMultiPrices;
  // }

  function removeVariant(_id) {
    const filteredVariants = addedVariants.filter(
      (variant) => variant._id !== _id
    );
    setAddedVariants(filteredVariants);
  }

  function removePriceLevel(id: string) {
    const filteredPriceLevels = addedMultiPrices.filter(
      (priceLevel) => priceLevel._id !== id
    );
    setAddedMultiPrices(filteredPriceLevels);
  }

  function editMultiPrice(_id) {
    setEditProductMultiPrice(true);
    const targetPrice = addedMultiPrices.find((price) => price._id === _id);
    setMultiPriceForm({
      _id: targetPrice._id,
      name: targetPrice.name,
      price: targetPrice.price,
      unitOfMeasure: targetPrice.unitOfMeasure,
      quantity: targetPrice.quantity,
      key: targetPrice.key,
    });
  }

  function editVariant(_id) {
    setEditProductVariant(true);
    const targetVariant = addedVariants.find((variant) => variant._id === _id);
    setVariantForm({
      _id: targetVariant._id,
      name: targetVariant.name,
      costPrice: targetVariant.costPrice,
      sellingPrice: targetVariant.sellingPrice,
      quantity: targetVariant.quantity,
      lowStock: {
        value: targetVariant.lowStock,
        label: targetVariant.lowStock ? "yes" : "no",
      },
      expiryDate: targetVariant?.expiryDate
        ? new Date(targetVariant?.expiryDate)
            .toISOString()
            .replace(/T.*/, "")
            .split("-")
            .join("-")
        : "",
    });
  }

  function addNewProductCategoryHandler() {
    if (productForm.newProductCategory === "") {
      toast.warning("Enter category name");
      return;
    }
    toast.promise(
      updateSettings(settings!._id, {
        inventoryCategories: [
          ...settings!.inventoryCategories,
          {
            name: productForm.newProductCategory,
          },
        ],
      }),
      {
        pending: "Adding Category",
        success: {
          render() {
            setAddNewProductCategory(false);
            setProductForm({
              ...productForm,
              newProductCategory: "",
            });
            refetchSettings();
            return "Category Added";
          },
        },
      }
    );
  }

  function addProductHandler() {
    createProduct(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        clearForm();
        close();
        setModalDisplay(false);
        refetchProducts();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function updateProductHandler() {
    updateProduct(product._id, payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        clearForm();
        setModalDisplay(false);
        close();
        refetchProducts();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function deleteProductHandler() {
    setModalDisplay(false);
    setDeleting(true);
    deleteProduct(product._id, { branchId: branch?._id ?? "" })
      .then(({ data }) => {
        setDeleting(false);
        notify(data.message, "success");
        setModalDisplay(false);
        close();
        refetchProducts();
      })
      .catch(() => {
        setDeleting(false);
      });
  }

  function submitHandler(e) {
    e.preventDefault();
    if (
      productForm.type?.trim() === "" ||
      productForm.category?.trim() === ""
      // || productForm.name?.trim() === ""
    ) {
      toast.warning("Fill all required fields!", {
        toastId: "fill-all-required-fields",
      });
      return;
    }
    if (productVariant && addedVariants.length === 0) {
      toast.warning("Add variants to continue!", {
        toastId: "add-variants-to-continue",
      });
      return;
    }
    setLoading(true);
    if (product) {
      updateProductHandler();
      return;
    }
    addProductHandler();
  }

  function clearForm() {
    setProductForm(defaultProductForm);
    setReadOnly(false);
    setAddedVariants([]);
    setProductVariant(false);
    setAddNewProductCategory(false);
    setEditProductVariant(false);
    // setProductExpiryReminder(false);
    clearVariantForm();
  }

  useEffect(() => {
    dispatch(actions._getBranches());
  }, []);

  useEffect(() => {
    if (product) {
      const branch = branches?.find(
        (branch) => branch._id === product?.branchId
      );
      for (const [key, value] of Object.entries(product)) {
        setProductForm((prevState) => ({
          ...prevState,
          [key]: value,
          image: product?.image?.link || "",
          sellingPrice: product.price?.selling || "",
          costPrice: product.price?.cost || 0,
          quantity: product.stock?.quantity || 0,
          quantityPerUnit: product.stock?.quantityPerUnit,
          units: product.stock?.units,
          stockUnits: product.stock?.stockUnits,
          perQuantity: product.stock?.perQty,
          lowStock: {
            value: product.lowStock,
            label: product.lowStock ? "yes" : "no",
          },
          sku: product.barcode,
          expiryDate: product?.expiryDateString
            ? format(new Date(product?.expiryDateString), "yyyy-MM-dd")
            : // .toISOString()
              // .replace(/T.*/, "")
              // .split("-")
              // .join("-")
              "",
          // branch,
        }));
      }
      if (product.priceLevels) {
        setAddedMultiPrices(
          product.priceLevels.map((price) => ({
            ...price,
            key: price.priceListCodeKey,
          }))
        );
        setProductMultiPrices(true);
      }
      if (product.variants) {
        // getVariantsHandler(product._id);
        setProductVariant(true);
        setAddedVariants(
          product.variantItems?.map((variant) => ({
            ...variant,
            image: variant?.image?.link || "",
            sellingPrice: variant?.price?.selling || 0,
            costPrice: variant?.price?.cost || 0,
            quantity: variant?.stock?.quantity || 0,
            units: variant?.stock.units,
            stockUnits: variant?.stock.stockUnits,
            lowStock: {
              value: variant.lowStock,
              label: variant.lowStock ? "yes" : "no",
            },
            expiryDate: variant?.expiryDate
              ? new Date(variant?.expiryDate)
              : "",
          }))
        );
      }
      setReadOnly(true);
    }
  }, [product]);

  return (
    <Modal
      display={display}
      close={() => {
        clearForm();
        setModalDisplay(false);
        close();
      }}
      title={
        product
          ? readOnly
            ? isService
              ? "Service Details"
              : "Product Details"
            : isService
            ? "Update Inventory Service"
            : "Update Inventory Product"
          : isService
          ? "Add Inventory Service"
          : "Add Inventory Product"
      }
      styles={styles}
    >
      <form onSubmit={submitHandler}>
        <div className={styles.body} style={{ maxHeight: "35rem" }}>
          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="product-type">
              Type
              <span>*</span>
            </label>
            {readOnly && (
              <input type="text" value={productForm.type} disabled={readOnly} />
            )}
            {!readOnly && (
              <Select
                styles={customStyles()}
                options={typeOptions}
                id="product-type"
                placeholder=""
                value={[{ value: productForm.type, label: productForm.type }]}
                onChange={(e) =>
                  setProductForm({
                    ...productForm,
                    type: e?.value,
                  })
                }
                classNamePrefix="react-select"
              />
            )}
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="product-name">
              Name
              <span>*</span>
            </label>
            <input
              type="text"
              name="name"
              id="product-name"
              value={productForm.name}
              onChange={updateForm}
              required
              disabled={readOnly}
            />
          </fieldset>

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="product-description">Description</label>
            <input
              style={{ textTransform: "initial" }}
              type="text"
              name="description"
              id="product-description"
              value={productForm.description}
              onChange={updateForm}
              disabled={readOnly}
            />
          </fieldset>

          {(isProduct || isMeasurement) && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="product-sku">SKU</label>
              <input
                type="text"
                name="sku"
                id="product-sku"
                value={productForm.sku}
                onChange={updateForm}
                disabled={readOnly}
              />
            </fieldset>
          )}

          <fieldset className={readOnly ? styles["read-only"] : null}>
            <label htmlFor="product-category">
              Category
              <span>*</span>
            </label>
            {!readOnly && !user.sageEnabled && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.4rem",
                }}
              >
                <input
                  style={{
                    height: "0.7rem",
                    width: "0.7rem",
                    marginRight: ".4rem",
                    borderColor: "#cccccc",
                    cursor: "pointer",
                  }}
                  type="checkbox"
                  name="addNewProductCategory"
                  id="addNewProductCategory"
                  checked={addNewProductCategory}
                  onChange={() => {
                    setAddNewProductCategory((prevState) => !prevState);
                    setProductForm({
                      ...productForm,
                      newProductCategory: "",
                    });
                  }}
                  disabled={readOnly}
                />
                <label
                  style={{ margin: 0, fontSize: ".7rem" }}
                  htmlFor="addNewProductCategory"
                >
                  Add new category
                </label>
              </div>
            )}

            <AnimatePresence>
              {addNewProductCategory && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  transition={{ type: "tween" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <fieldset className={readOnly ? styles["read-only"] : null}>
                    <input
                      type="text"
                      name="newProductCategory"
                      placeholder="Enter category name"
                      value={productForm.newProductCategory}
                      onChange={updateForm}
                      required
                      disabled={readOnly}
                    />
                    <Button
                      onClick={addNewProductCategoryHandler}
                      type="button"
                      style={{
                        position: "absolute",
                        right: 0,
                        height: 29,
                        width: "4rem",
                        borderRadius: "initial",
                        backgroundColor: "var(--accent-color)",
                        boxShadow: "none",
                      }}
                    >
                      Add
                    </Button>
                  </fieldset>
                </motion.div>
              )}
            </AnimatePresence>

            {readOnly && (
              <input
                type="text"
                value={productForm.category}
                disabled={readOnly}
              />
            )}

            {!addNewProductCategory && !readOnly && (
              <Select
                styles={customStyles()}
                options={categoryOptions}
                placeholder=""
                value={[
                  {
                    value: productForm.category,
                    label: productForm.category,
                  },
                ]}
                onChange={(e) =>
                  setProductForm({
                    ...productForm,
                    category: e?.value,
                  })
                }
                isLoading={loadingSettings}
                loadingMessage={() => "Loading categories"}
                classNamePrefix="react-select"
              />
            )}
          </fieldset>

          {!productVariant && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="product-cp">
                Cost Price {settings?.currency?.code || ""}
              </label>
              <NumberInput
                name="costPrice"
                id="product-cp"
                value={productForm.costPrice}
                onChange={updateForm}
                disabled={readOnly}
              />
            </fieldset>
          )}

          {!productVariant && !productMultiPrices && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="product-sp">
                Selling Price {settings?.currency?.code || ""}
                <span>*</span>
              </label>
              <NumberInput
                name="sellingPrice"
                id="product-sp"
                value={productForm.sellingPrice}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset>
          )}

          {(isProduct || isMeasurement) && !productVariant && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="product-quantity">
                Quantity
                <span>*</span>
              </label>
              <NumberInput
                name="quantity"
                id="product-quantity"
                value={productForm.quantity}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset>
          )}

          {isMeasurement && !productVariant && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="product-per-quantity">
                Per Quantity
                <span>*</span>
              </label>
              <NumberInput
                name="perQuantity"
                id="product-per-quantity"
                value={productForm.perQuantity}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset>
          )}

          {isMeasurement && !productVariant && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="measurement-type">
                Measurement Type
                <span>*</span>
              </label>
              <input
                type="text"
                name="measurementType"
                id="measurement-type"
                value={productForm.measurementType}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset>
          )}

          {user.sageEnabled && (
            <>
              {!readOnly && (
                <fieldset>
                  <label htmlFor="unit-of-measurement">
                    Unit
                    <span>*</span>
                  </label>
                  {readOnly && (
                    <input
                      type="text"
                      value={productForm.unitOfMeasure || ""}
                      disabled={readOnly}
                    />
                  )}
                  <Select
                    styles={customStyles()}
                    options={unitOptions}
                    placeholder=""
                    value={[
                      {
                        value: productForm.unitOfMeasure,
                        label: productForm.unitOfMeasure,
                      },
                    ]}
                    onChange={(e) =>
                      setProductForm({
                        ...productForm,
                        unitOfMeasure: e?.value,
                      })
                    }
                    isLoading={loadingSettings}
                    loadingMessage={() => "Loading units"}
                    classNamePrefix="react-select"
                  />
                </fieldset>
              )}

              {!readOnly && (
                <fieldset>
                  <label htmlFor="price-list-code-key">
                    Key
                    <span>*</span>
                  </label>
                  {readOnly && (
                    <input
                      type="text"
                      value={productForm.key || ""}
                      disabled={readOnly}
                    />
                  )}
                  <Select
                    styles={customStyles()}
                    options={["RETA", "RETAIL", "WHOLES"].map((key) => ({
                      value: key,
                      label: key,
                    }))}
                    placeholder=""
                    value={[
                      {
                        value: productForm.key,
                        label: productForm.key,
                      },
                    ]}
                    onChange={(e) =>
                      setProductForm({
                        ...productForm,
                        key: e?.value,
                      })
                    }
                    classNamePrefix="react-select"
                  />
                </fieldset>
              )}

              {!readOnly && (
                <fieldset>
                  <label htmlFor="account-set-code">
                    Account Set Code
                    <span>*</span>
                  </label>
                  {readOnly && (
                    <input
                      type="text"
                      value={productForm.accountSetCode || ""}
                      disabled={readOnly}
                    />
                  )}
                  <Select
                    styles={customStyles()}
                    options={setCode}
                    placeholder=""
                    value={[
                      {
                        value: productForm.accountSetCode,
                        label: productForm.accountSetCode,
                      },
                    ]}
                    onChange={(e) =>
                      setProductForm({
                        ...productForm,
                        accountSetCode: e?.value,
                      })
                    }
                    isLoading={loadingSettings}
                    loadingMessage={() => "Loading units"}
                    classNamePrefix="react-select"
                  />
                </fieldset>
              )}

              {!readOnly && (
                <fieldset>
                  <label htmlFor="structure-code">
                    Structure Code
                    <span>*</span>
                  </label>
                  {readOnly && (
                    <input
                      type="text"
                      value={productForm.structureCode || ""}
                      disabled={readOnly}
                    />
                  )}
                  <Select
                    styles={customStyles()}
                    options={structureCode}
                    placeholder=""
                    value={[
                      {
                        value: productForm.structureCode,
                        label: productForm.structureCode,
                      },
                    ]}
                    onChange={(e) =>
                      setProductForm({
                        ...productForm,
                        structureCode: e?.value,
                      })
                    }
                    isLoading={loadingSettings}
                    loadingMessage={() => "Loading units"}
                    classNamePrefix="react-select"
                  />
                </fieldset>
              )}
            </>
          )}

          {isProduct && !productVariant && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="expiry-date">
                Expiry date
                {/* <span>*</span> */}
              </label>
              <input
                type="date"
                name="expiryDate"
                id="expiry-date"
                onChange={updateForm}
                value={productForm.expiryDate}
                disabled={readOnly}
              />
            </fieldset>
          )}

          {false && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="branch">Branch</label>

              {readOnly && (
                <input
                  type="text"
                  value={productForm.branch?.name || "No branch"}
                  disabled={readOnly}
                />
              )}
              {!readOnly && (
                <Select
                  styles={customStyles()}
                  options={
                    branch ? [{ value: branch, label: branch?.name }] : []
                  }
                  id="branch"
                  placeholder=""
                  value={[
                    {
                      value: productForm?.branch,
                      label: productForm?.branch?.name,
                    },
                  ]}
                  onChange={(e) =>
                    setProductForm({
                      ...productForm,
                      branch: e?.value,
                    })
                  }
                  classNamePrefix="react-select"
                />
              )}
            </fieldset>
          )}

          {(isProduct || isMeasurement) && (
            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="image">Product Image</label>
              {!readOnly && (
                <Widget
                  onChange={uploadImage}
                  publicKey={
                    process.env.REACT_APP_UPLOAD_CARE_PUBLIC_KEY as string
                  }
                />
              )}
              {!product && productForm.image && (
                <img
                  src={productForm.image}
                  alt={productForm.name.toLowerCase()}
                />
              )}
              {product && productForm.image && (
                <img
                  className={classNames({ "!m-0": readOnly })}
                  src={productForm.image}
                  alt={productForm.name.toLowerCase()}
                />
              )}
              {product && !productForm.image && readOnly && (
                <img
                  className={classNames({ "!m-0": readOnly })}
                  src={NoImagePlaceholder}
                  alt={productForm.name.toLowerCase()}
                />
              )}
            </fieldset>
          )}

          {isProduct &&
            ((productForm?.priceLevels && isProduct) ||
              (!product && isProduct) ||
              !readOnly) && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label>
                  {productForm?.priceLevels && readOnly
                    ? "Multi Price"
                    : "Multi Price"}
                </label>
                {!readOnly && (
                  <ButtonToggle
                    enabled={productMultiPrices}
                    setEnabled={setProductMultiPrices}
                  />
                )}

                {addedMultiPrices && productMultiPrices && (
                  <ul className={styles.variants}>
                    <AnimatePresence mode="popLayout">
                      {addedMultiPrices?.map((priceLevel, index) => (
                        <motion.li
                          layout
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0.8, opacity: 0 }}
                          transition={{ type: "spring" }}
                          style={{
                            marginTop: readOnly && index === 0 ? 0 : "1rem",
                            // backgroundColor:
                            //   priceLevel._id === priceLevelForm._id
                            //     ? "#cccccc"
                            //     : "",
                          }}
                          key={priceLevel._id}
                        >
                          <span style={{ width: "35%" }}>
                            <b>{truncateString(priceLevel.name, 16)}</b>,
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>Unit:</b>
                            <br />
                            {priceLevel.unitOfMeasure},
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>Price:</b>
                            <br />
                            {settings?.currency?.symbol || ""}
                            {formatNumber(priceLevel.price)},
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>Qty:</b>
                            <br />
                            {formatNumber(priceLevel.quantity)}
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>key:</b>
                            <br />
                            {priceLevel.key}
                          </span>
                          {!readOnly && (
                            <>
                              <i
                                className="pointer mdi mdi-lead-pencil"
                                onClick={() => editMultiPrice(priceLevel._id)}
                              />
                              <i
                                className="pointer mdi mdi-close"
                                onClick={() => removePriceLevel(priceLevel._id)}
                              />
                            </>
                          )}
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </ul>
                )}
              </fieldset>
            )}

          {productMultiPrices && !readOnly && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              transition={{ type: "tween" }}
              style={{ marginBottom: "1rem" }}
            >
              <fieldset
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles["sub-group"]}>
                  <label htmlFor="price-level-name">
                    Name
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="price-level-name"
                    value={multiPriceForm.name}
                    onChange={updateMultiPriceForm}
                  />
                </div>
                <div className={styles["sub-group"]}>
                  <label htmlFor="price-level-unit">Unit</label>
                  {/* <input
                    type="text"
                    name="unitOfMeasure"
                    id="price-level-unit"
                    value={multiPriceForm.unitOfMeasure}
                    onChange={updateMultiPriceForm}
                  /> */}
                  {user.sageEnabled && (
                    <Select
                      styles={customStyles()}
                      options={unitOptions}
                      placeholder=""
                      value={[
                        {
                          value: multiPriceForm.unitOfMeasure,
                          label: multiPriceForm.unitOfMeasure,
                        },
                      ]}
                      onChange={(e) =>
                        setMultiPriceForm({
                          ...multiPriceForm,
                          unitOfMeasure: e?.value,
                        })
                      }
                      isLoading={loadingSettings}
                      loadingMessage={() => "Loading categories"}
                      classNamePrefix="react-select"
                    />
                  )}
                  {!user.sageEnabled && (
                    <input
                      type="text"
                      name="unitOfMeasure"
                      id="price-level-unit"
                      value={multiPriceForm.unitOfMeasure}
                      onChange={updateMultiPriceForm}
                    />
                  )}
                </div>
              </fieldset>

              <fieldset
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles["sub-group"]}>
                  <label htmlFor="price-level-price">Price</label>
                  <NumberInput
                    name="price"
                    id="price-level-price"
                    value={multiPriceForm.price}
                    onChange={updateMultiPriceForm}
                  />
                </div>
                <div className={styles["sub-group"]}>
                  <label htmlFor="price-level-qty">
                    Quantity
                    <span>*</span>
                  </label>
                  <NumberInput
                    name="quantity"
                    id="price-level-qty"
                    value={multiPriceForm.quantity}
                    onChange={updateMultiPriceForm}
                  />
                </div>
              </fieldset>

              {user.sageEnabled && (
                <fieldset
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={styles["sub-group"]}>
                    <label htmlFor="price-level-code-key">Key</label>
                    <Select
                      styles={customStyles()}
                      options={["RETA", "RETAIL", "WHOLES"].map((key) => ({
                        value: key,
                        label: key,
                      }))}
                      value={[
                        {
                          value: multiPriceForm.key,
                          label: multiPriceForm.key,
                        },
                      ]}
                      placeholder=""
                      isClearable
                      name="key"
                      id="key"
                      onChange={(e) =>
                        setMultiPriceForm({
                          ...multiPriceForm,
                          key: e?.value,
                        })
                      }
                      classNamePrefix="react-select"
                    />
                  </div>
                </fieldset>
              )}

              <fieldset className="!flex-row justify-between h-[29px]">
                <div className={styles["sub-group"]}>
                  <Button
                    onClick={() => {
                      editProductMultiPrice
                        ? addPriceLevel(multiPriceForm._id)
                        : addPriceLevel(v4());
                    }}
                    type="button"
                    className="h-[29px] w-full bg-blue-500"
                  >
                    {editProductMultiPrice ? "Update Price" : "Add Price"}
                  </Button>
                </div>
                <div className={styles["sub-group"]}>
                  <Button
                    onClick={() => {
                      addedMultiPrices.length === 0 &&
                        setProductMultiPrices(false);
                      clearMultiPriceForm();
                    }}
                    type="button"
                    className="h-[29px] w-full text-blue-500"
                  >
                    Cancel
                  </Button>
                </div>
              </fieldset>
            </motion.div>
          )}

          {isProduct &&
            ((productForm?.variants && isProduct) ||
              (!product && isProduct) ||
              !readOnly) && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label>
                  {productForm?.variants && readOnly
                    ? "Variants"
                    : "Does this product have variants?"}
                </label>
                {!readOnly && (
                  <ButtonToggle
                    enabled={productVariant}
                    setEnabled={setProductVariant}
                  />
                )}

                {addedVariants && productVariant && (
                  <ul className={styles.variants}>
                    <AnimatePresence mode="popLayout">
                      {addedVariants?.map((variant, index) => (
                        <motion.li
                          layout
                          initial={{ scale: 0.8, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0.8, opacity: 0 }}
                          transition={{ type: "spring" }}
                          style={{
                            marginTop: readOnly && index === 0 ? 0 : "1rem",
                            backgroundColor:
                              variant._id === variantForm._id ? "#cccccc" : "",
                          }}
                          key={variant._id}
                        >
                          <span style={{ width: "35%" }}>
                            <b>{truncateString(variant.name, 16)}</b>,
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>CP:</b>
                            <br />
                            {settings?.currency?.symbol || ""}
                            {formatNumber(variant.costPrice)},
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>SP:</b>
                            <br />
                            {settings?.currency?.symbol || ""}
                            {formatNumber(variant.sellingPrice)},
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>Qty:</b>
                            <br />
                            {formatNumber(variant.quantity)}
                          </span>
                          {!readOnly && (
                            <>
                              <i
                                className="pointer mdi mdi-lead-pencil"
                                onClick={() => editVariant(variant._id)}
                              />
                              <i
                                className="pointer mdi mdi-close"
                                onClick={() => removeVariant(variant._id)}
                              />
                            </>
                          )}
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </ul>
                )}
              </fieldset>
            )}

          {productVariant && !readOnly && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              transition={{ type: "tween" }}
              style={{ marginBottom: "1rem" }}
            >
              <fieldset
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles["sub-group"]}>
                  <label htmlFor="variant-name">
                    Description
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="variant-name"
                    value={variantForm.name}
                    onChange={updateVariantForm}
                  />
                </div>
                <div className={styles["sub-group"]}>
                  <label htmlFor="variant-cp">Cost Price</label>
                  <NumberInput
                    name="costPrice"
                    id="variant-cp"
                    value={variantForm.costPrice}
                    onChange={updateVariantForm}
                  />
                </div>
              </fieldset>

              <fieldset
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles["sub-group"]}>
                  <label htmlFor="variant-sp">
                    Selling Price
                    <span>*</span>
                  </label>
                  <NumberInput
                    name="sellingPrice"
                    id="variant-sp"
                    value={variantForm.sellingPrice}
                    onChange={updateVariantForm}
                  />
                </div>
                <div className={styles["sub-group"]}>
                  <label htmlFor="variant-qty">
                    Quantity
                    <span>*</span>
                  </label>
                  <NumberInput
                    name="quantity"
                    id="variant-qty"
                    value={variantForm.quantity}
                    onChange={updateVariantForm}
                  />
                </div>
              </fieldset>

              <fieldset
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles["sub-group"]}>
                  <label htmlFor="variant-expiry-date">Expiry date</label>
                  <input
                    type="date"
                    name="expiryDate"
                    id="variant-expiry-date"
                    onChange={updateVariantForm}
                    value={variantForm.expiryDate}
                    disabled={readOnly}
                  />
                </div>
              </fieldset>

              <fieldset className="!flex-row justify-between h-[29px]">
                <div className={styles["sub-group"]}>
                  <Button
                    onClick={() => {
                      editProductVariant
                        ? addVariant(variantForm._id)
                        : addVariant(`id:${v4()}`);
                    }}
                    type="button"
                    className="h-[29px] w-full bg-blue-500"
                  >
                    {editProductVariant ? "Update Variant" : "Add Variant"}
                  </Button>
                </div>
                <div className={styles["sub-group"]}>
                  <Button
                    onClick={() => {
                      addedVariants.length === 0 && setProductVariant(false);
                      clearVariantForm();
                    }}
                    type="button"
                    className="h-[29px] w-full text-blue-500"
                  >
                    Cancel
                  </Button>
                </div>
              </fieldset>
            </motion.div>
          )}
        </div>
        <div className={styles.footer}>
          {product && (
            <button type="button" onClick={() => setModalDisplay(true)}>
              {deleting ? <ClipLoader color="#ffffff" size={12} /> : "Delete"}
            </button>
          )}
          {!product && (
            <button
              type="button"
              onClick={() => {
                clearForm();
                close();
              }}
              className={styles.cancel}
            >
              Cancel
            </button>
          )}
          {readOnly && (
            <button
              style={{ width: "8rem" }}
              type="button"
              onClick={() => setReadOnly(false)}
            >
              Edit Product
            </button>
          )}
          {!readOnly && (
            <button
              style={{
                width: product ? "8rem" : "7rem",
                backgroundColor: "var(--accent-color)",
                borderColor: "var(--accent-color)",
              }}
            >
              {product &&
                (loading ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : (
                  "Update Product"
                ))}

              {!product &&
                (loading ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : isService ? (
                  "Add Service"
                ) : (
                  "Add Product"
                ))}
            </button>
          )}
        </div>
        <ConfirmationDialog
          display={modalDisplay}
          close={() => setModalDisplay(false)}
          primaryAction={deleteProductHandler}
        />
      </form>
    </Modal>
  );
}
