import React, { useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Reload } from "assets/svg/icons";
import { useInventoryQuery } from "hooks/useInventoryQuery";
import { useStaffsQuery } from "hooks/useStaffsQuery";
import { useCustomersQuery } from "hooks/useCustomersQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useSalesReportQuery } from "hooks/useSalesReportQuery";
import { useReactToPrint } from "react-to-print";
import { ISale } from "models/sales";
import { exportSales, exportSalesToCSV, updateSale } from "services/Sale";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import {
  padWithLeadingZeros,
  convertUtc,
  customStyles,
  formatNumber,
  truncateString,
} from "helpers";
import {
  StepBackward,
  StepBackward2,
  StepForward,
  StepForward2,
} from "assets/svg/icons";
import classNames from "classnames";
import Table from "components/Table";
import Modal from "components/Elements/Modal";
import SaleInfo from "./SaleInfo";
import DeleteSale from "./DeleteSale";
import ActionDropdown from "components/Elements/ActionDropdown";
import Loader from "components/Loader";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Elements/Button";
import Select from "react-select";
import Receipt from "components/Receipt/Receipt";
import styles from "assets/styles/Pages.module.scss";

export enum ModalTitles {
  SaleDetails = "Sale Details",
  DeleteSale = "Delete Sale",
}

export default function Sales() {
  const receiptRef = useRef(null);
  const { branch } = useSelector((state) => state.auth);
  const [sale, setSale] = useState<ISale | null>(null);
  const [exporting, setExporting] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [inputValues, setInputValues] = useState({
    item: "",
    staff: "",
    status: "",
    customer: "",
    paymentMethodType: "",
    startDate: "",
    endDate: "",
    category: "",
  });
  const { data: _products } = useInventoryQuery();
  const {
    data: sales,
    isLoading: loadingSales,
    isError: salesError,
    refetch,
    isRefetching,
  } = useSalesReportQuery({
    item: inputValues.item,
    staff: inputValues.staff,
    status: inputValues.status,
    customer: inputValues.customer,
    paymentMethodType: inputValues.paymentMethodType,
    startDate: inputValues.startDate,
    endDate: inputValues.endDate,
    category: inputValues.category,
    page,
    limit,
  });
  const { data: staffs, isLoading: loadingStaffs } = useStaffsQuery();
  const { data: customers, isLoading: loadingCustomers } = useCustomersQuery();
  const { data: settings } = useSettingsQuery();
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });
  const products = _products?.data?.data.data;
  const _inventoryProducts = products
    ?.filter((product) => !product.variants)
    ?.map((product) => ({
      value: {
        _id: product._id,
        name: product.name,
        price: product.price?.cost,
        quantity: 1,
      },
      label: product.name,
    }));
  const _inventoryVariants = products
    ?.filter((product) => product.variants)
    ?.map((product) =>
      product.variantItems.map((variant) => ({
        value: {
          _id: variant._id,
          name: `${product.name} - ${variant.name}`,
          price: variant.price?.selling,
          quantity: 1,
        },
        label: `${product.name} - ${variant.name}`,
      }))
    )
    .flat();
  const currency = settings?.data.data[0].currency;
  const paymentMethods = settings?.data.data[0].paymentMethods;
  const data = useMemo(
    () => (!sales ? [] : sales?.data?.data?.paginatedSales),

    [sales]
  );
  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_originalRow, rowIndex) =>
          padWithLeadingZeros(rowIndex + 1, 2),
        disableFilters: true,
      },
      {
        Header: "Ref",
        accessor: "saleRef",
        Cell: ({ value }) => <span>{truncateString(value, 12)}</span>,
        disableFilters: true,
      },
      {
        Header: "Type",
        accessor: (originalRow) =>
          originalRow.type === "walkin"
            ? "Guest"
            : originalRow.type === "order"
            ? "Customer"
            : originalRow.type,
        disableFilters: true,
      },
      {
        Header: "Items",
        accessor: (originalRow) => (
          <span>{`${formatNumber(originalRow.items.length)} Item(s)`}</span>
        ),
        disableFilters: true,
      },
      {
        Header: "Amount",
        accessor: (originalRow) =>
          inputValues.item
            ? originalRow.items[0].price * originalRow.items[0].quantity
            : originalRow.amount,
        Cell: ({ value }) => `${currency?.symbol || ""}${formatNumber(value)}`,
        disableFilters: true,
      },
      {
        Header: "Payment Type",
        accessor: (originalRow) =>
          originalRow.paymentMethods
            .map((paymentMethod) => paymentMethod.type)
            .join(", "),
        disableFilters: true,
        // Filter: PaymentMethodColumnFilter,
        // filter: "equals",
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Sold By",
        accessor: (originalRow) =>
          truncateString(
            `${originalRow.staff.firstName} ${originalRow.staff.lastName}`,
            15
          ),
        disableFilters: true,
        // Filter: SelectColumnFilter,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }) => convertUtc(value),
        disableFilters: true,
        // Filter: DateRangeColumnFilter,
        // filter: "dateBetween",
      },
      {
        Header: "Actions",
        Cell: ({ cell }: { cell: any }) => (
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative flex"
            onMouseEnter={() => setSale(cell.row.original)}
          >
            <ActionDropdown
              items={[
                {
                  title: "Complete Sale",
                  function: () => completeSale(cell.row.original),
                },
                { title: "Print", function: handlePrint },
                {
                  title: "Delete",
                  type: "error",
                  function: () => {
                    setModalTitle(ModalTitles.DeleteSale);
                    setModalDisplay(true);
                  },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [inputValues.item, currency?.symbol, handlePrint]
  );

  function completeSale(sale: ISale) {
    if (sale.status === "complete") {
      toast.warning("Sale has already been completed");
      return;
    }
    toast.promise(updateSale(sale._id, { ...sale, status: "complete" }), {
      pending: "Completing Sale...",
      success: {
        render() {
          refetch();
          return "Sale completed!";
        },
      },
    });
  }

  function handleInputChange(name: string, value: string) {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  }

  function handleExport() {
    setExporting(true);
    exportSales({
      startDate: inputValues.startDate,
      endDate: inputValues.endDate,
    })
      .then((response) => {
        setExporting(false);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "inventory.xlsx"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => setExporting(false));
  }

  function handleExportToCSV() {
    setExporting(true);
    exportSalesToCSV({
      item: inputValues.item,
      staff: inputValues.staff,
      status: inputValues.status,
      customer: inputValues.customer,
      paymentMethodType: inputValues.paymentMethodType,
      startDate: inputValues.startDate,
      endDate: inputValues.endDate,
      category: inputValues.category,
      branchId: branch?._id,
    })
      .then((response) => {
        setExporting(false);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "inventory.csv"); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => setExporting(false));
  }

  return (
    <>
      <div
        className={classNames(styles.container, {
          "h-[80%]": loadingSales,
        })}
      >
        <div className={styles.buttons}>
          <Button
            style={{
              backgroundColor: "var(--bg-white)",
              color: "var(--accent-color)",
              outline: "none",
            }}
            onClick={() => refetch()}
          >
            <Reload className={isRefetching ? styles.rotate : null} />
            Refresh
          </Button>
          <Link style={{ marginLeft: 0 }} to="/sales">
            <Button>Go Back</Button>
          </Link>
          <Button style={{ margin: 0 }} onClick={handleExportToCSV}>
            {exporting ? <ClipLoader color="#ffffff" size={12} /> : "Export"}
          </Button>
        </div>
        <form className={styles.filter}>
          <label>Filter</label>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={[
                {
                  label: "Non-Variant Products",
                  options: _inventoryProducts,
                },
                { label: "Variants", options: _inventoryVariants },
              ]}
              id="item"
              placeholder="Item"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("item", e?.value?._id || "")
              }
              classNamePrefix="react-select"
              isClearable
            />
          </fieldset>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={[
                { value: "pending", label: "Pending" },
                { value: "complete", label: "Complete" },
              ]}
              id="status"
              placeholder="Status"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("status", e?.value || "")
              }
              classNamePrefix="react-select"
              isClearable
            />
          </fieldset>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={settings?.data.data[0].inventoryCategories?.map(
                (method) => ({
                  value: method.name,
                  label: method.name,
                })
              )}
              id="category"
              placeholder="Category"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("category", e?.value || "")
              }
              classNamePrefix="react-select"
              isClearable
            />
          </fieldset>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={paymentMethods?.map((method) => ({
                value: method.type,
                label: method.type,
              }))}
              id="payment type"
              placeholder="Payment type"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("paymentMethodType", e?.value || "")
              }
              classNamePrefix="react-select"
              isClearable
            />
          </fieldset>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={
                !staffs
                  ? []
                  : // : typeof staffs.data.data === "object"
                    // ? []
                    staffs.data.data.data.map((staff) => ({
                      value: staff._id,
                      label: `${staff.firstName} ${staff.lastName}`,
                    }))
              }
              id="staff"
              placeholder="Staff"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("staff", e?.value || "")
              }
              noOptionsMessage={() => "No staffs"}
              classNamePrefix="react-select"
              isLoading={loadingStaffs}
              isClearable
            />
          </fieldset>
          <fieldset>
            <Select
              styles={customStyles({
                width: "10rem",
              })}
              options={
                !customers
                  ? []
                  : customers.data.data.map((customer) => ({
                      value: customer._id,
                      label: `${customer.firstName} ${customer.lastName}`,
                    }))
              }
              id="customer"
              placeholder="Customer"
              onChange={(e: { value: string | undefined }) =>
                handleInputChange("customer", e?.value || "")
              }
              noOptionsMessage={() => "No customers"}
              classNamePrefix="react-select"
              isLoading={loadingCustomers}
              isClearable
            />
          </fieldset>
          <fieldset className="text-[#808080] border border-[#cccccc] text-sm h-[29px] gap-2 flex items-center">
            <input
              type="date"
              onChange={(e) => handleInputChange("startDate", e.target.value)}
              className="h-full px-2"
            />
            <label>to</label>
            <input
              type="date"
              onChange={(e) => handleInputChange("endDate", e.target.value)}
              className="h-full px-2"
            />
          </fieldset>
        </form>
        {loadingSales && <Loader />}
        {salesError && <ErrorMessage message="An error occurred" />}
        {sales && (
          <>
            <h6 className="mb-2">
              Total Transactions: {currency?.symbol}
              {formatNumber(sales?.data?.data?.totalTransactions)} (
              {sales?.data?.data?.totalCount} Transactions)
            </h6>
            <h6>
              Total Profit: {currency?.symbol}
              {formatNumber(sales?.data?.data?.totalProfit)}
            </h6>
            <Table
              columns={columns}
              data={data}
              getRowProps={(row: any) => ({
                onClick: () => {
                  setModalTitle(ModalTitles.SaleDetails);
                  setModalDisplay(true);
                  setSale(row.cells[0].row.original);
                },
              })}
              totalTransactions={sales?.data?.data?.totalCount}
              totalSales={sales?.data?.data?.totalTransactions}
              totalProfit={sales?.data?.data?.totalProfit}
              salesReport
              noPagination
            />
          </>
        )}
        <form className={styles.pagination}>
          <button
            type="button"
            onClick={() => setPage(1)}
            disabled={loadingSales}
          >
            <StepBackward2 />
          </button>
          <button
            type="button"
            onClick={() => page > 1 && setPage((page) => page - 1)}
            disabled={loadingSales}
          >
            <StepBackward />
          </button>
          <button
            type="button"
            onClick={() => setPage((page) => page + 1)}
            disabled={loadingSales}
          >
            <StepForward />
          </button>
          <button
            type="button"
            onClick={() => setPage(sales?.data?.data?.totalPages)}
            disabled={loadingSales}
          >
            <StepForward2 />
          </button>
          <fieldset>
            Page{" "}
            <strong>
              {page} of{" "}
              {loadingSales ? (
                <ClipLoader
                  color="#000000"
                  size={12}
                  cssOverride={{ verticalAlign: "middle" }}
                />
              ) : (
                sales?.data?.data?.totalPages
              )}
            </strong>
          </fieldset>
          {/* <fieldset>
            <label htmlFor="">Go to page:</label>
            <input
              type="number"
              value={page}
              min={1}
              onChange={(e) => {
                setPage(+e.target.value);
                // const page = e.target.value
                //   ? Number(e.target.value) - 1
                //   : 0;
                // gotoPage(page);
              }}
            />
          </fieldset> */}
          <fieldset>
            <label htmlFor="">Rows per page:</label>
            <select
              value={limit}
              onChange={(e) => {
                setLimit(+e.target.value);
                setPage(1);
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </fieldset>
        </form>
      </div>
      {sale && (
        <Modal
          display={modalDisplay}
          title={modalTitle}
          close={() => {
            setModalTitle("");
            setModalDisplay(false);
            setSale(null);
          }}
        >
          {modalTitle === ModalTitles.SaleDetails && (
            <SaleInfo
              sale={sale}
              cancel={() => {
                setModalTitle("");
                setModalDisplay(false);
                setSale(null);
              }}
            />
          )}
          {modalTitle === ModalTitles.DeleteSale && (
            <DeleteSale
              sale={sale}
              cancel={() => {
                setModalTitle("");
                setModalDisplay(false);
                setSale(null);
              }}
            />
          )}
        </Modal>
      )}
      <div className="hidden">
        <Receipt sale={sale!} ref={receiptRef} />
      </div>
    </>
  );
}
