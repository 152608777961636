import axios from "axios";
import { IQueryParams } from "models/base";
import { ISageCustomerGroups } from "models/sage";

export function getSageCustomers(params?: IQueryParams) {
  return axios.get<ISageCustomerGroups>(`/customer/sage`, {
    params,
  });
}

export function getSageCustomerGroups(params?: IQueryParams) {
  return axios.get<ISageCustomerGroups>(`/customer/sage/group`, {
    params,
  });
}

export function getSageInventories(params?: IQueryParams) {
  return axios.get<ISageCustomerGroups>(`/v2/inventory/sage`, {
    params,
  });
}

export function getSageSales(params?: IQueryParams) {
  return axios.get<ISageCustomerGroups>(`/sale/sage`, {
    params,
  });
}
