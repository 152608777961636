import React, { ReactNode, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CloseIcon } from "assets/svg/icons";
import useWindowDimension from "hooks/useWindowDimension";
import Backdrop from "./Backdrop";

type Props = {
  title: string;
  display: boolean;
  close: () => void;
  children: ReactNode;
  styles?: { [key: string]: string };
};

export default function Modal({
  display,
  close,
  children,
  styles,
  title,
}: Props) {
  const { width } = useWindowDimension();

  useEffect(() => {
    if (display) {
      document.querySelector("html")!.style.overflow = "hidden";
    }
    if (!display) {
      document.querySelector("html")!.style.removeProperty("overflow");
    }
  }, [display]);

  return (
    <AnimatePresence>
      {display && (
        <>
          <Backdrop close={close} />
          <motion.div
            initial={{ scale: 0, x: "-50%", y: "-50%" }}
            animate={{ scale: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: width! <= 480 ? "95%" : "40%",
              maxWidth: "35rem",
              minWidth: width! <= 300 ? "18rem" : "23rem",
              backgroundColor: "#ffffff",
              zIndex: "1150",
            }}
            className={styles?.modal}
          >
            {title && (
              <div className="flex items-center justify-between py-3 px-4 border border-solid border-[#cccccc]">
                <h1 className="text-xl capitalize">{title}</h1>
                <CloseIcon
                  className="h-6 cursor-pointer fill-[#5d5d5d]"
                  onClick={close}
                />
              </div>
            )}
            <>{children}</>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
