import React from "react";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { ISale } from "models/sales";
import { convertUtc, formatNumber } from "helpers";

type Props = {
  sale: ISale;
  cancel: () => void;
};

export default function SaleInfo({ sale, cancel }: Props) {
  const { data: settings } = useSettingsQuery();
  const currency = settings?.data.data[0].currency;

  return (
    <>
      <div className="py-[1.3rem] px-4 space-y-4 text-[0.9rem]">
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Ref:</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc]">
            {sale.saleRef}
          </span>
        </div>
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Type:</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc]">
            {sale.type === "walkin"
              ? "Guest"
              : sale.type === "order"
              ? "Customer"
              : sale.type}
          </span>
        </div>
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Payment Type(s):</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc] capitalize">
            {sale.paymentMethods
              .map(
                (paymentMethod) =>
                  `${paymentMethod.type} (${
                    currency?.symbol || ""
                  }${formatNumber(paymentMethod.amount)})`
              )
              .join(", ")}
          </span>
        </div>
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Status:</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc] capitalize">
            {sale.status}
          </span>
        </div>
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Sold By:</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc] capitalize">
            {`${sale.staff.firstName} ${sale.staff.lastName}`}
          </span>
        </div>
        <div className="flex items-center">
          <h4 className="w-32 shrink-0">Date:</h4>
          <span className="inline-block w-full border-b border-solid border-[#cccccc] capitalize">
            {convertUtc(sale.createdAt)}
          </span>
        </div>
        <table className="w-full">
          <thead>
            <tr className="font-medium text-left">
              <th className="p-2">Item</th>
              <th className="p-2">Qty</th>
              <th className="p-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            {sale.items.map((item) => (
              <tr key={item._id}>
                <td className="px-2 border border-solid border-[#dddddd] capitalize">
                  {item.name}
                </td>
                <td className="px-2 border border-solid border-[#dddddd]">
                  {formatNumber(item.quantity)}
                </td>
                <td className="px-2 border border-solid border-[#dddddd]">
                  {currency?.symbol || ""}
                  {formatNumber(item.price)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="font-medium border-t-2 border-solid border-[#bbbbbb]">
            <tr>
              <td className="px-2 border border-solid border-[#dddddd]">
                Sub-Total
              </td>
              <td className="px-2 border border-solid border-[#dddddd]">
                {formatNumber(
                  sale.items
                    .map((item) => item.quantity)
                    .reduce((prev, curr) => prev + curr)
                )}
              </td>
              <td className="px-2 border border-solid border-[#dddddd]">
                {currency?.symbol || ""}
                {formatNumber(
                  sale.items
                    .map((item) => item.price * item.quantity)
                    .reduce((prev, curr) => prev + curr)
                )}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="px-2 border border-solid border-[#dddddd]"
              >
                Tax
              </td>
              <td className="px-2 border border-solid border-[#dddddd]">
                {currency?.symbol || ""}
                {formatNumber(sale.tax!)}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="px-2 border border-solid border-[#dddddd]"
              >
                Discount
              </td>
              <td className="px-2 border border-solid border-[#dddddd]">
                {currency?.symbol || ""}
                {formatNumber(sale.discountAmount)}
              </td>
            </tr>
            {sale.deliveryDetails && (
              <tr>
                <td
                  colSpan={2}
                  className="px-2 border border-solid border-[#dddddd]"
                >
                  Delivery Fee
                </td>
                <td className="px-2 border border-solid border-[#dddddd]">
                  {currency?.symbol || ""}
                  {formatNumber(sale.deliveryDetails.deliveryFee)}
                </td>
              </tr>
            )}
            <tr className="bg-[#2589f45d]">
              <td
                colSpan={2}
                className="px-2 border border-solid border-[#dddddd]"
              >
                Total
              </td>
              <td className="px-2 border border-solid border-[#dddddd]">
                {currency?.symbol || ""}
                {formatNumber(sale.amount)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="flex gap-4 p-[0.8rem] border-t border-solid border-[#cccccc]">
        <button
          className="text-black text-[0.9rem] w-16 py-[0.1rem] px-2 ml-auto border border-solid border-[#cccccc]"
          onClick={cancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
