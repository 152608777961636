// auth
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SET_USER = "SET_USER";
export const SET_EMAIL = "SET_EMAIL";
export const CLEAR_EMAIL = "CLEAR_EMAIL";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const LOG_OUT = "LOG_OUT";
export const INVALID_SESSION = "INVALID_SESSION";
export const LOGIN_AS_STAFF = "LOGIN_AS_STAFF";
export const LOGIN_AS_CUSTOMER = "LOGIN_AS_CUSTOMER";
export const LOGIN_AS_BUSINESS = "LOGIN_AS_BUSINESS";
export const SET_SUBSCRIPTION_DAYS_LEFT = "SET_SUBSCRIPTION_DAYS_LEFT";
export const SET_BRANCH = "SET_BRANCH";

// network
export const OFFLINE = "OFFLINE";
export const ONLINE = "ONLINE";

// pos
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_RIDER = "UPDATE_RIDER";
export const UPDATE_DELIVERY = "UPDATE_DELIVERY";
export const CLEAR_SAVED_ORDERS = "CLEAR_SAVED_ORDERS";
export const UPDATE_SAVED_ORDERS = "UPDATE_SAVED_ORDERS";
export const UPDATE_OFFLINE_ORDERS = "UPDATE_OFFLINE_ORDERS";
export const CLEAR_OFFLINE_ORDERS = "CLEAR_OFFLINE_ORDERS";
export const DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT";
export const DISCOUNT_PERCENT = "DISCOUNT_PERCENT";

// sales
export const SALES_LOADED = "SALES_LOADED";
export const UPDATE_SALES = "UPDATE_SALES";
export const CLEAR_SALES = "CLEAR_SALES";
export const SALES_ERROR = "SALES_ERROR";
export const SALES_RETURNS_LOADED = "SALES_RETURNS_LOADED";
export const UPDATE_SALES_RETURNS = "UPDATE_SALES_RETURNS";
export const SALES_RETURNS_ERROR = "SALES_RETURNS_ERROR";

// products
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

// warehouse
export const WAREHOUSE_PRODUCTS_LOADED = "WAREHOUSE_PRODUCTS_LOADED";
export const UPDATE_WAREHOUSE_PRODUCTS = "UPDATE_WAREHOUSE_PRODUCTS";
export const CLEAR_WAREHOUSE_PRODUCTS = "CLEAR_WAREHOUSE_PRODUCTS";
export const WAREHOUSE_PRODUCTS_ERROR = "WAREHOUSE_PRODUCTS_ERROR";
export const UPDATE_WAREHOUSE_HISTORY = "UPDATE_WAREHOUSE_HISTORY";
export const WAREHOUSE_HISTORY_LOADED = "WAREHOUSE_HISTORY_LOADED";
export const WAREHOUSE_HISTORY_ERROR = "WAREHOUSE_HISTORY_ERROR";

// purchases
export const PURCHASES_LOADED = "PURCHASES_LOADED";
export const UPDATE_PURCHASES = "UPDATE_PURCHASES";
export const CLEAR_PURCHASES = "CLEAR_PURCHASES";
export const PURCHASES_ERROR = "PURCHASES_ERROR";

// suppliers
export const SUPPLIERS_LOADED = "SUPPLIERS_LOADED";
export const UPDATE_SUPPLIERS = "UPDATE_SUPPLIERS";
export const CLEAR_SUPPLIERS = "CLEAR_SUPPLIERS";
export const SUPPLIERS_ERROR = "SUPPLIERS_ERROR";

// expenses
export const EXPENSES_LOADED = "EXPENSES_LOADED";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const CLEAR_EXPENSES = "CLEAR_EXPENSES";
export const EXPENSES_ERROR = "EXPENSES_ERROR";

// riders
export const RIDERS_LOADED = "RIDERS_LOADED";
export const UPDATE_RIDERS = "UPDATE_RIDERS";
export const CLEAR_RIDERS = "CLEAR_RIDERS";
export const RIDERS_ERROR = "RIDERS_ERROR";

// customers
export const CUSTOMERS_LOADED = "CUSTOMERS_LOADED";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const CUSTOMERS_ERROR = "CUSTOMERS_ERROR";

// reports
export const REPORTS_LOADED = "REPORTS_LOADED";
export const UPDATE_REPORTS = "UPDATE_REPORTS";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
export const REPORTS_ERROR = "REPORTS_ERROR";

// reservations
export const RESERVATIONS_LOADED = "RESERVATIONS_LOADED";
export const UPDATE_RESERVATIONS = "UPDATE_RESERVATIONS";
export const CLEAR_RESERVATIONS = "CLEAR_RESERVATIONS";
export const RESERVATIONS_ERROR = "RESERVATIONS_ERROR";

// branches
export const BRANCHES_LOADED = "BRANCHES_LOADED";
export const UPDATE_BRANCHES = "UPDATE_BRANCHES";
export const CLEAR_BRANCHES = "CLEAR_BRANCHES";
export const BRANCHES_ERROR = "BRANCHES_ERROR";

// staffs
export const STAFFS_LOADED = "STAFFS_LOADED";
export const UPDATE_STAFFS = "UPDATE_STAFFS";
export const CLEAR_STAFFS = "CLEAR_STAFFS";
export const STAFFS_ERROR = "STAFFS_ERROR";

// settings
export const SETTINGS_LOADED = "SETTINGS_LOADED";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

// business
export const LOGS_LOADED = "LOGS_LOADED";
export const LOGS_ERROR = "LOGS_ERROR";
export const QUANTITY_UPDATE_HISTORY_LOADED = "QUANTITY_UPDATE_HISTORY_LOADED";
export const QUANTITY_UPDATE_HISTORY_ERROR = "QUANTITY_UPDATE_HISTORY_ERROR";
export const ADMIN_DATA_LOADED = "ADMIN_DATA_LOADED";
export const ADMIN_DATA_ERROR = "ADMIN_DATA_ERROR";

// roles
export const ROLES_LOADED = "ROLES_LOADED";
export const ROLES_ERROR = "ROLES_ERROR";

// receipt
export const UPDATE_RECEIPT = "UPDATE_RECEIPT";
