import axios from "axios";
import { IQueryParams } from "models/base";
import { ICreateDeliveryPayload, IDeliveriesResponse } from "models/deliveries";

export function createDelivery(payload: ICreateDeliveryPayload) {
  return axios.post(`/delivery`, payload);
}

export function getDeliveries(params: IQueryParams) {
  return axios.get<IDeliveriesResponse>(`/delivery`, {
    params,
  });
}
