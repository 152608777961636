import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import { getSubscriptions, subscribe } from "services/Subscriptions";
import { toast } from "react-toastify";
import { formatNumber, notify } from "helpers";
import { _getAdminData } from "store/actions";
import { ClipLoader } from "react-spinners";
import Modal from "components/Elements/Modal";
import styles from "./SubscriptionModal.module.scss";

export default function SubscriptionModal({ display, close }) {
  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [paystackData, setPaystackData] = useState(null);
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);

  const config = {
    reference: paystackData?.tx_ref,
    email: paystackData?.email,
    amount: paystackData?.amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PRIVATE_KEY,
    // process.env.NODE_ENV === "development"
    //   ? process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
    //   : process.env.REACT_APP_PAYSTACK_PRIVATE_KEY,
  };
  const initializePayment = usePaystackPayment(config);

  function onSuccess(reference) {
    close();
    setPaystackData(null);
    notify("Your subscription is now active", "success");
  }

  function onClose() {}

  function handleSubscription() {
    if (!selectedOption) {
      toast.warning("Select Subscription", {
        toastId: "select-subscription",
      });
      return;
    }
    if (!duration) {
      toast.warning("Select duration", {
        toastId: "select-duration",
      });
      return;
    }
    setLoading(true);
    subscribe(JSON.parse(selectedOption)?._id, duration)
      .then((response) => {
        setLoading(false);
        notify(
          "Pending subscription created, continue with payment",
          "success"
        );
        dispatch(_getAdminData());
        setPaystackData(response.data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getSubscriptions().then((response) => setSubscriptions(response.data.data));
  }, []);

  return (
    <Modal
      display={display}
      close={() => {
        close();
        setDuration("");
        setSelectedOption(null);
      }}
      styles={styles}
      title="Renew Subscription"
    >
      <form>
        {subscriptions?.map((subscription) => (
          <fieldset key={subscription._id}>
            <label htmlFor="plans">{subscription.name}:</label>
            <input
              type="radio"
              value={JSON.stringify(subscription)}
              checked={JSON.parse(selectedOption)?._id === subscription._id}
              onChange={(e) => setSelectedOption(e.target.value)}
            />
          </fieldset>
        ))}
        {selectedOption && (
          <fieldset style={{ flexDirection: "column", width: "100%" }}>
            <label htmlFor="months">Select Number of Months</label>
            <select
              name="months"
              id="months"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            >
              <option value="" disabled></option>
              <option value="1">1 Month</option>
              <option value="2">2 Months</option>
              <option value="3">3 Months</option>
              <option value="4">4 Months</option>
              <option value="5">5 Months</option>
              <option value="6">6 Months</option>
              <option value="7">7 Months</option>
              <option value="8">8 Months</option>
              <option value="9">9 Months</option>
              <option value="10">10 Months</option>
              <option value="11">11 Months</option>
              <option value="12">12 Months</option>
            </select>
          </fieldset>
        )}
      </form>
      {duration && (
        <div className={styles["payment-options"]}>
          <h3>Payment Details</h3>
          <table>
            <thead>
              <tr>
                <th />
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Branches</td>
                <td>{formatNumber(JSON.parse(selectedOption)?.branch.max)}</td>
                <td>
                  &#8358;
                  {formatNumber(JSON.parse(selectedOption)?.branch.amount)}
                </td>
              </tr>
              <tr>
                <td>Staffs</td>
                <td>{formatNumber(JSON.parse(selectedOption)?.staff.max)}</td>
                <td>
                  &#8358;
                  {formatNumber(JSON.parse(selectedOption)?.staff.amount)}
                </td>
              </tr>
              <tr>
                <td>Inventory</td>
                <td>
                  {formatNumber(JSON.parse(selectedOption)?.inventory.max)}
                </td>
                <td>
                  &#8358;
                  {formatNumber(JSON.parse(selectedOption)?.inventory.amount)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Sub-total</td>
                <td />
                <td>
                  &#8358;{formatNumber(JSON.parse(selectedOption)?.price)}
                </td>
              </tr>
              <tr>
                <td>Duration (months)</td>
                <td />
                <td>{duration}</td>
              </tr>
              <tr style={{ borderTop: "1px solid #000000" }}>
                <td>Total</td>
                <td />
                <td>
                  &#8358;
                  {formatNumber(JSON.parse(selectedOption)?.price * +duration)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
      <div className={styles.footer}>
        <span
          onClick={() => {
            close();
            setDuration("");
            setSelectedOption(null);
          }}
        >
          Cancel
        </span>
        {!paystackData && (
          <button onClick={handleSubscription} style={{ width: "6rem" }}>
            {loading ? <ClipLoader color="#ffffff" size={12} /> : "Continue"}
          </button>
        )}
        {paystackData && (
          <button onClick={() => initializePayment(onSuccess, onClose)}>
            {loading ? (
              <ClipLoader color="#ffffff" size={12} />
            ) : (
              "Pay with Paystack"
            )}
          </button>
        )}
      </div>
    </Modal>
  );
}
