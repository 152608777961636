import axios from "axios";
import { IQueryParams } from "models/base";

export function getStaffs(params: { [key: string]: string }) {
  return axios.get(`/staff`, {
    params,
  });
}

export function createStaff(payload: any, params: IQueryParams) {
  return axios.post(`/auth/create/staff`, payload, { params });
}

export function updateStaff(staffId: string, payload: any) {
  return axios.patch(`/staff/${staffId}`, payload);
}

export function deleteStaff(staffId: string) {
  return axios.delete(`/staff/${staffId}`);
}

export function updateStaffPassword(staffId: string, payload: any) {
  return axios.patch(`/staff/password/${staffId}`, payload);
}

export function activateStaff(staffId: string) {
  return axios.post(`/staff/activate/${staffId}`);
}

export function deactivateStaff(staffId: string) {
  return axios.post(`/staff/deactivate/${staffId}`);
}

export function setStaffLocation(payload: { staff: string; location: string }) {
  return axios.post(`/staff/location`, payload);
}
