import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function usePageBlock(permission) {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user.account.role !== "admin" && !permission) {
      toast.info("You are not allowed to view this page", {
        toastId: "axios-error",
      });
      history.push("/pos");
    }
  }, []);

  if (user.account.role !== "admin" && !permission) return null;
}
