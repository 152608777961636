import axios from "axios";

export function getSubscriptions(params: any, branchId: string) {
  return axios.get(`/subscription`, {
    ...params, branchId
  });
}

export function getSubscriptionHistory(params: any) {
  return axios.get(`/subscription/history`, {
    params,
  });
}

export function subscribe(subscriptionId: string, duration: any) {
  return axios.post(`/subscription/${subscriptionId}/${duration}`);
}

export function updateSubscription(subscriptionId: string, body: any) {
  return axios.patch(`/subscription/update/${subscriptionId}`, body);
}
