import React, { useState, useEffect } from "react";
import { getAdminSettings } from "services/Settings";
import { Link, useHistory } from "react-router-dom";
import { createBusiness } from "services/auth";
import { ClipLoader } from "react-spinners";
import { notify } from "helpers";
import locations, { compare } from "data/location";
import Select from "react-select";
import useWindowDimension from "hooks/useWindowDimension";
import Slideshow from "components/Slideshow";
import Button from "components/Elements/Button";
import Logo from "components/Elements/Logo";
import styles from "./Registration.module.scss";

export default function Login() {
  const history = useHistory();
  const { width } = useWindowDimension();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [activeForm, setActiveForm] = useState("form1");
  const [cities, setCities] = useState(null);
  const [form, setForm] = useState({
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    category: "",
    address: "",
    city: "",
    state: "",
    country: "",
    ref: "",
  });
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: () => ({
      display: "flex",
      border: "1px solid #ccc",
      height: "2.5rem",
      borderRadius: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      textTransform: "capitalize",
    }),
    singleValue: (provided) => ({
      ...provided,
      textTransform: "capitalize",
      margin: 0,
    }),
  };
  const form1 = (
    <form onSubmit={handleContinue}>
      <div className={styles.indicator}>
        <span className={styles.active}>1</span>
        <span>2</span>
      </div>
      <h1>Create Account</h1>
      <p>Get access to premium features now</p>
      <fieldset>
        <label htmlFor="firstName">
          First Name <span>*</span>
        </label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          value={form.firstName}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="lastName">
          Last Name <span>*</span>
        </label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          value={form.lastName}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="email">
          Email <span>*</span>
        </label>
        <input
          id="email"
          name="email"
          type="email"
          value={form.email}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="password">
          Password <span>*</span>
        </label>
        <input
          id="password"
          name="password"
          type="password"
          value={form.password}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="confirmPassword">
          Confirm Password <span>*</span>
        </label>
        <input
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          value={form.confirmPassword}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="phoneNumber">
          Phone Number <span>*</span>
        </label>
        <input
          id="phoneNumber"
          name="phoneNumber"
          type="number"
          value={form.phoneNumber}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="ref">Referral Code</label>
        <input
          id="ref"
          name="ref"
          type="text"
          value={form.ref}
          onChange={updateForm}
        />
      </fieldset>

      <Button>Proceed</Button>
      <div>
        <p>
          Already have an account? <Link to="/">Sign in</Link>
        </p>
      </div>
    </form>
  );

  const form2 = (
    <form onSubmit={handleSubmit}>
      <div className={styles.indicator}>
        <span
          className={styles.active}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setActiveForm("form1");
          }}
        >
          1
        </span>
        <span className={styles.active}>2</span>
      </div>
      <h1>Create Account</h1>
      <p>Get access to premium features now</p>
      <fieldset>
        <label htmlFor="businessName">
          Business Name <span>*</span>
        </label>
        <input
          id="businessName"
          name="businessName"
          type="text"
          value={form.businessName}
          onChange={updateForm}
          required
        />
      </fieldset>
      <fieldset>
        <label htmlFor="category">
          Business Category <span>*</span>
        </label>
        <Select
          styles={customStyles}
          options={categories?.map((category) => ({
            value: category.name,
            label: category.name,
          }))}
          id="category"
          placeholder=""
          isClearable
          onChange={(e) =>
            setForm({
              ...form,
              category: e?.value,
            })
          }
          isLoading={!categories}
          classNamePrefix="react-select"
        />
      </fieldset>
      <fieldset>
        <label htmlFor="country">
          Country <span>*</span>
        </label>
        <Select
          styles={customStyles}
          options={[
            {
              value: "nigeria",
              label: "nigeria",
            },
          ]}
          id="country"
          placeholder=""
          isClearable
          onChange={(e) =>
            setForm({
              ...form,
              country: e?.value,
            })
          }
          classNamePrefix="react-select"
        />
      </fieldset>
      <fieldset>
        <label htmlFor="state">State</label>
        <Select
          styles={customStyles}
          value={[{ value: form.state, label: form.state }]}
          options={locations?.map((location) => ({
            value: location.state,
            label: location.state,
          }))}
          id="state"
          placeholder=""
          isClearable
          onChange={(e) =>
            setForm({
              ...form,
              state: e?.value,
            })
          }
          classNamePrefix="react-select"
        />
      </fieldset>
      <fieldset>
        <label htmlFor="city">City</label>
        <Select
          styles={customStyles}
          value={[{ value: form.city, label: form.city }]}
          options={cities?.map((city) => ({
            value: city,
            label: city,
          }))}
          id="city"
          placeholder=""
          isClearable
          onChange={(e) =>
            setForm({
              ...form,
              city: e?.value,
            })
          }
          classNamePrefix="react-select"
          noOptionsMessage={() => "Select State"}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="ref">Address</label>
        <input
          id="address"
          name="address"
          type="text"
          value={form.address}
          onChange={updateForm}
        />
      </fieldset>
      <Button>
        {loading ? <ClipLoader size={12} color="#ffffff" /> : "Sign up"}
      </Button>
    </form>
  );

  function updateForm(e) {
    const { value, name } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  }

  function handleContinue(e) {
    e.preventDefault();
    if (form.password !== form.confirmPassword) {
      notify("Passwords do not match", "error");
      return;
    }
    setActiveForm("form2");
  }
  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      phone: form.phoneNumber,
      password: form.password,
      account: {
        category: form.category,
      },
      location: {
        address: form.address,
        city: form.city,
        state: form.state,
        country: form.country,
      },
      businessName: form.businessName,
      businessEmail: form.email,
    };
    setLoading(true);
    createBusiness(payload)
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        history.push("/login");
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getAdminSettings().then(({ data }) => setCategories(data.data[0].category));
  }, []);

  useEffect(() => {
    locations.map((location) => {
      if (location.state === form.state) {
        const sort = location.lgas.sort(compare);
        setCities(sort);
      }
    });

    if (!form.state) {
      setCities(null);
      setForm({
        ...form,
        state: "",
        city: "",
      });
    }
  }, [form.state]);

  return (
    <div className={styles.container}>
      <Logo />
      {activeForm === "form1" && form1}
      {activeForm === "form2" && form2}
      {width > 1045 && (
        <div>
          <Slideshow />
        </div>
      )}
    </div>
  );
}
