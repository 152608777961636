import React, { useState, useEffect, useMemo } from "react";
import * as actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useCustomersQuery } from "hooks/useCustomersQuery";
import {
  createReservation,
  deleteReservation,
  updateReservation,
} from "services/Reservations";
import { useInventoryQuery } from "hooks/useInventoryQuery";
import { useSettingsQuery } from "hooks/useSettingsQuery";
import { useInvoicesQuery } from "hooks/useInvoicesQuery";
import { usePOS } from "hooks/usePOS";
import { convertTime12To24, customStyles, notify } from "helpers";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { format } from "date-fns";
import AddInventoryProduct from "views/Admin/Products/AddProduct";
import Modal from "components/Elements/Modal";
import Select from "react-select";
import styles from "assets/styles/ActionModals.module.scss";
import classNames from "classnames";
import { createInvoice } from "services/invoices";

export default function AddInvoice({ display, close, reservation }) {
  const { user, branch } = useSelector((state) => state.auth);
  const { data: products } = useInventoryQuery();
  const { data: customers } = useCustomersQuery();
  const { data: _settings } = useSettingsQuery();
  const { refetch: refetchInvoices } = useInvoicesQuery();
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [defaultInvoiceForm] = useState({
    products: null,
    paymentMethods: null,
    status: "pending",
    details: "",
    customer: "",
    amount: "",
    amountPaid: "",
  });
  const [invoiceForm, setInvoiceForm] = useState(defaultInvoiceForm);
  const settings = _settings?.data.data[0];
  const customerOptions = useMemo(
    () =>
      customers
        ? customers?.data.data.map((customer) => {
            return {
              value: customer,
              label: `${customer.firstName} ${customer.lastName}`,
            };
          })
        : [],
    [customers]
  );
  const paymentMethods = useMemo(
    () =>
      settings
        ? settings?.paymentMethods.map((method) => {
            return {
              value: method,
              label: method.type,
            };
          })
        : [],
    [settings]
  );
  const _inventoryProducts = products?.data.data.data
    ?.filter((product) => product.type === "product" && !product.variants)
    ?.map((product) => ({
      value: {
        _id: product._id,
        name: product.name,
        price: product.price?.cost ?? "",
        selling: product.price?.selling ?? "",
        quantity: 1,
      },
      label: product.name,
    }));
  const _inventoryVariants = products?.data.data.data
    ?.filter((product) => product.type === "product" && product.variants)
    ?.map((product) =>
      product.variantItems.map((variant) => ({
        value: {
          _id: variant._id,
          name: `${product.name} - ${variant.name}`,
          price: variant.price?.cost ?? "",
          selling: variant.price?.selling ?? "",
          quantity: 1,
        },
        label: `${product.name} - ${variant.name}`,
      }))
    )
    .flat();
  const statusOptions = [
    { value: "pending", label: "pending" },
    { value: "completed", label: "completed" },
    { value: "upcoming", label: "upcoming" },
  ];
  console.log(invoiceForm);
  const payload = {
    items: invoiceForm.products?.map((item) => {
      return {
        _id: item.value._id,
        name: item.value.name,
        price: item.value.selling,
        cost: item.value.cost,
        quantity: item.value.quantity,
      };
    }),
    paymentMethods: invoiceForm.paymentMethods?.map((paymentMethod) => {
      return {
        _id: paymentMethod.value._id,
        type: paymentMethod.value.type,
        details: "",
        amount: paymentMethod.value.amount,
      };
    }),
    customer: invoiceForm.customer?._id,
    status: invoiceForm.status,
    amount: invoiceForm.products
      ?.map((product) => product.value.selling * product.value.quantity)
      ?.reduce((prev, curr) => prev + curr, 0),
    amountPaid: invoiceForm.paymentMethods
      ?.map((paymentMethod) => paymentMethod.value.amount)
      ?.reduce((prev, curr) => prev + curr, 0),
    invoice: true,
    staff: {
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.account.role,
    },
  };

  function updateForm(e) {
    const { value, name } = e.target;
    setInvoiceForm({
      ...invoiceForm,
      [name]: value,
    });
  }

  function updateProductProps(props, value, _id) {
    const updatedProducts = invoiceForm.products?.map((product) => {
      if (product.value._id === _id) {
        return {
          ...product,
          value: {
            ...product.value,
            [props]: +value,
          },
        };
      } else {
        return product;
      }
    });
    setInvoiceForm({
      ...invoiceForm,
      products: updatedProducts,
    });
  }

  function updatePaymentAmount(value, _id) {
    const updatedPaymentMethods = invoiceForm.paymentMethods?.map(
      (paymentMethod) => {
        if (paymentMethod.value._id === _id) {
          return {
            ...paymentMethod,
            value: {
              ...paymentMethod.value,
              amount: +value,
            },
          };
        } else {
          return paymentMethod;
        }
      }
    );
    setInvoiceForm({
      ...invoiceForm,
      paymentMethods: updatedPaymentMethods,
    });
  }

  function addInvoiceHandler() {
    createInvoice(payload, { branchId: branch?._id ?? "" })
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        clearForm();
        close();
        refetchInvoices();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function updateInvoiceHandler() {
    updateReservation(reservation._id, payload)
      .then((response) => {
        setLoading(false);
        notify(response.data.message, "success");
        clearForm();
        close();
        refetchInvoices();
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function deleteInvoiceHandler() {
    setDeleting(true);
    deleteReservation(reservation._id)
      .then(({ data }) => {
        setDeleting(false);
        notify(data.message, "success");
        close();
        refetchInvoices();
      })
      .catch(() => {
        setDeleting(false);
      });
  }

  function submitHandler(e) {
    e.preventDefault();
    if (invoiceForm.status?.trim() === "") {
      toast.warning("Fill all required fields");
      return;
    }
    setLoading(true);

    if (reservation) {
      updateInvoiceHandler();
      return;
    }
    addInvoiceHandler();
  }

  function clearForm() {
    setInvoiceForm(defaultInvoiceForm);
    setReadOnly(false);
  }

  useEffect(() => {
    if (reservation) {
      console.log(reservation);
      //   for (const [key, value] of Object.entries(reservation)) {
      //   }
      setInvoiceForm((prevState) => ({
        ...prevState,
        amount: reservation.amount.saleAmount,
        amountPaid: reservation.amount.amountPaid,
      }));
      setReadOnly(true);
    }
  }, [reservation]);

  return (
    <>
      <Modal
        display={display}
        close={() => {
          clearForm();
          close();
        }}
        title={reservation ? "Update Invoice" : "Add Invoice"}
        styles={styles}
      >
        <form onSubmit={submitHandler}>
          <div className={styles.body} style={{ height: "auto" }}>
            {!readOnly && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="product">
                  Product
                  <span>*</span>
                </label>
                <div className="flex items-center mb-2">
                  <input
                    className="w-3 h-3 mr-2 border border-gray-500 cursor-pointer"
                    type="checkbox"
                    name="addNewProduct"
                    id="addNewProduct"
                    checked={addNewProduct}
                    onChange={() => setAddNewProduct((prevState) => !prevState)}
                  />
                  <label className="!m-0 !text-xs" htmlFor="addNewProduct">
                    Add new product
                  </label>
                </div>
                <Select
                  isMulti
                  styles={customStyles()}
                  options={[
                    {
                      label: "Non-Variant Products",
                      options: _inventoryProducts,
                    },
                    { label: "Variants", options: _inventoryVariants },
                  ]}
                  id="product"
                  placeholder=""
                  isClearable
                  value={invoiceForm?.products?.map((product) => ({
                    value: product.value,
                    label: product.label,
                  }))}
                  onChange={(e) =>
                    setInvoiceForm({
                      ...invoiceForm,
                      products: e,
                    })
                  }
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "Select"}
                />
              </fieldset>
            )}

            {invoiceForm.products?.length > 0 && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="product-quantities">
                  Product Quantities
                  <span>*</span>
                </label>
                <div className="grid grid-cols-3 gap-3">
                  {invoiceForm.products?.map((product) => (
                    <div
                      key={product.value._id}
                      style={{ width: "100%" }}
                      className={styles["sub-group"]}
                    >
                      <label className={classNames({ "!mb-0": !reservation })}>
                        {product.label} ({product.value.selling})
                      </label>
                      <input
                        type="number"
                        name="amount"
                        min={1}
                        id="amount"
                        value={product.value.quantity}
                        onChange={(e) =>
                          updateProductProps(
                            "quantity",
                            e.target.value,
                            product.value._id
                          )
                        }
                        required
                        disabled={readOnly}
                      />
                    </div>
                  ))}
                </div>
              </fieldset>
            )}

            {!readOnly && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="product">
                  Payment Options
                  <span>*</span>
                </label>
                <Select
                  isMulti
                  styles={customStyles()}
                  options={paymentMethods}
                  id="paymentOptions"
                  placeholder=""
                  isClearable
                  value={invoiceForm?.paymentMethods?.map((paymentMethod) => ({
                    value: paymentMethod.value,
                    label: paymentMethod.label,
                  }))}
                  onChange={(e) =>
                    setInvoiceForm({
                      ...invoiceForm,
                      paymentMethods: e,
                    })
                  }
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "Select"}
                />
              </fieldset>
            )}

            {invoiceForm.paymentMethods?.length > 0 && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="product-quantities">
                  Payment Methods
                  <span>*</span>
                </label>
                <div className="grid grid-cols-3 gap-3">
                  {invoiceForm.paymentMethods?.map((paymentMethod) => (
                    <div
                      key={paymentMethod.value._id}
                      style={{ width: "100%" }}
                      className={styles["sub-group"]}
                    >
                      <label className={classNames({ "!mb-0": !reservation })}>
                        {paymentMethod.label}
                      </label>
                      <input
                        type="number"
                        name="amount"
                        id="amount"
                        value={paymentMethod.value.quantity}
                        onChange={(e) =>
                          updatePaymentAmount(
                            e.target.value,
                            paymentMethod.value._id
                          )
                        }
                        required
                        disabled={readOnly}
                      />
                    </div>
                  ))}
                </div>
              </fieldset>
            )}

            {/* <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="name">
                Name
                <span>*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={invoiceForm.name}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset>

            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="number"
                name="phone"
                id="phone"
                value={invoiceForm.phone}
                onChange={updateForm}
                disabled={readOnly}
                required
              />
            </fieldset> */}

            {/* {readOnly && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="status">
                  Status
                  <span>*</span>
                </label>
                {readOnly && (
                  <input
                    type="text"
                    value={invoiceForm.status}
                    disabled={readOnly}
                  />
                )}
                {!readOnly && (
                  <Select
                    styles={customStyles()}
                    options={statusOptions}
                    id="status"
                    placeholder=""
                    isClearable
                    value={[
                      {
                        value: invoiceForm.status,
                        label: invoiceForm.status,
                      },
                    ]}
                    onChange={(e) =>
                      setInvoiceForm({
                        ...invoiceForm,
                        status: e?.value,
                      })
                    }
                    classNamePrefix="react-select"
                  />
                )}
              </fieldset>
            )} */}

            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="amount">Total Amount</label>
              <input
                type="number"
                name="amount"
                id="amount"
                value={
                  invoiceForm.amount ||
                  invoiceForm.products
                    ?.map(
                      (product) =>
                        product.value.selling * product.value.quantity
                    )
                    ?.reduce((prev, curr) => prev + curr, 0)
                }
                onChange={updateForm}
                disabled={true}
              />
            </fieldset>

            <fieldset className={readOnly ? styles["read-only"] : null}>
              <label htmlFor="amount">Amount Paid</label>
              <input
                type="number"
                name="amountPaid"
                id="amountPaid"
                value={
                  invoiceForm.amountPaid ||
                  invoiceForm.paymentMethods
                    ?.map((paymentMethod) => paymentMethod.value.amount)
                    ?.reduce((prev, curr) => prev + curr, 0)
                }
                onChange={updateForm}
                disabled={true}
              />
            </fieldset>

            {!readOnly && (
              <fieldset className={readOnly ? styles["read-only"] : null}>
                <label htmlFor="status">
                  Customer
                  <span>*</span>
                </label>
                {readOnly && (
                  <input
                    type="text"
                    value={invoiceForm.customer}
                    disabled={readOnly}
                  />
                )}
                {!readOnly && (
                  <Select
                    styles={customStyles()}
                    options={customerOptions}
                    id="customer"
                    placeholder=""
                    isClearable
                    value={[
                      {
                        value: invoiceForm.customer,
                        label: `${invoiceForm.customer?.firstName ?? ""} ${
                          invoiceForm.customer?.lastName ?? ""
                        }`,
                      },
                    ]}
                    onChange={(e) =>
                      setInvoiceForm({
                        ...invoiceForm,
                        customer: e?.value,
                      })
                    }
                    classNamePrefix="react-select"
                  />
                )}
              </fieldset>
            )}
          </div>
          <div className={styles.footer}>
            {reservation && (
              <button
                type="button"
                // onClick={deleteInvoiceHandler}
                className="opacity-0"
              >
                {deleting ? <ClipLoader color="#ffffff" size={12} /> : "Delete"}
              </button>
            )}
            {!reservation && (
              <button
                type="button"
                onClick={() => {
                  clearForm();
                  close();
                }}
                className={styles.cancel}
              >
                Cancel
              </button>
            )}
            {/* {readOnly && (
              <button
                style={{ width: "8rem" }}
                type="button"
                onClick={() => setReadOnly(false)}
              >
                Edit
              </button>
            )} */}
            {!readOnly && (
              <button
                style={{
                  width: reservation ? "9rem" : "8rem",
                  backgroundColor: "var(--accent-color)",
                  borderColor: "var(--accent-color)",
                }}
              >
                {reservation &&
                  (loading ? (
                    <ClipLoader color="#ffffff" size={12} />
                  ) : (
                    "Update Invoice"
                  ))}
                {!reservation &&
                  (loading ? (
                    <ClipLoader color="#ffffff" size={12} />
                  ) : (
                    "Add Invoice"
                  ))}
              </button>
            )}
          </div>
        </form>
      </Modal>
      <AddInventoryProduct
        display={addNewProduct}
        close={() => setAddNewProduct(false)}
      />
    </>
  );
}
