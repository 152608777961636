import axios from "axios";
import { IQueryParams } from "models/base";

export function getSuppliers(params?: IQueryParams) {
  return axios.get(`/supplier`, { params });
}

export function createSupplier(body: any, params: IQueryParams) {
  return axios.post(`/supplier`, body, { params });
}

export function updateSupplier(supplierId: any, body: any) {
  return axios.patch(`/supplier/${supplierId}`, body);
}

export function deleteSupplier(supplierId: any) {
  return axios.delete(`/supplier/${supplierId}`);
}
