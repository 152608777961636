import axios from "axios";
import { IQueryParams } from "models/base";
import { ILogsResponse } from "models/logs";
import { IQuantityUpdateHistoryResponse } from "models/quantityUpdateHistory";

export function getLogs(params: IQueryParams) {
  return axios.get<ILogsResponse>(`/business/logs`, { params });
}

export function getAdminData(params: IQueryParams) {
  return axios.get(`/business`, { params });
}

export function getQuantityUpdateHistory(params: IQueryParams) {
  return axios.get<IQuantityUpdateHistoryResponse>(
    `/business/inventory/quantityupdatehistory`,
    {
      params,
    }
  );
}

export function uploadFile(
  type: "inventory" | "warehouse",
  formData: FormData
) {
  return axios.post(`/business/upload/${type}`, formData);
}
