import React, { Dispatch, SetStateAction, useRef } from "react";
import { useSelector } from "react-redux";
import { useLogOut } from "hooks/useLogOut";
import { NavLink } from "react-router-dom";
import { isAdmin } from "helpers";
import { getNavLinks } from "helpers/getNavLinks";
import { ChevronDoubleRight } from "assets/svg/icons";
import useOnClickOutside from "hooks/useOnClickOutside";
import Logo from "components/Elements/Logo";
import classNames from "classnames";

type Props = {
  display: boolean;
  collapsed: boolean;
  collapse: () => void;
  close: () => void;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
};

export default function Sidebar({
  display,
  collapsed,
  collapse,
  close,
  setCollapsed,
}: Props) {
  const ref = useRef(null);
  const { logOut } = useLogOut();
  const { user, permissions } = useSelector((state) => state.auth);
  useOnClickOutside(ref, () => {
    collapse();
    close();
  });

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
          height: "100vh",
          width: "calc((100vw - 1600px)/2)",
          backgroundColor: "#eef6ff",
        }}
      />
      <div
        ref={ref}
        className={classNames({
          "bg-blue-50 text-zinc-50 fixed 2xl:static 2xl:translate-x-0 z-20 shrink-0":
            true,
          "transition-all duration-300 ease-in-out": true,
          "w-64": !collapsed,
          "w-16": collapsed,
          "-translate-x-full": !display,
        })}
      >
        <div
          className={classNames({
            "flex flex-col justify-between h-screen sticky inset-0 w-full":
              true,
          })}
        >
          <div
            className={classNames({
              "flex items-center transition-none h-20": true,
              "px-4 justify-between": !collapsed,
              "justify-center": collapsed,
            })}
          >
            {!collapsed && <Logo width={80} />}
            <button
              className="grid w-10 h-10 transition-colors rounded-full opacity-100 place-content-center hover:bg-white"
              onClick={() => setCollapsed((prevState) => !prevState)}
            >
              <ChevronDoubleRight className="w-5 h-5 stroke-blue-300" />
            </button>
          </div>
          <nav className="flex-grow">
            <ul
              className={classNames({
                "flex flex-col gap-1 items-stretch max-h-[75vh] overflow-y-auto":
                  true,
              })}
            >
              {getNavLinks(permissions)?.map(
                (link) =>
                  (link.allowed || isAdmin(user)) && (
                    <li key={link.path} onClick={close}>
                      <NavLink
                        to={link.path}
                        className={classNames({
                          "flex items-center h-10 text-blue-400 border border-transparent transition-colors duration-300 overflow-hidden hover:border-blue-400":
                            true,
                          "rounded-md py-1 px-2 mx-3 gap-4": !collapsed,
                          "justify-center rounded-full p-1 mx-2 w-10":
                            collapsed,
                        })}
                        activeClassName="!text-white bg-blue-400"
                      >
                        <span className="scale-90">{link.icon}</span>
                        {/* <span>{!collapsed && link.title}</span> */}
                        {!collapsed && (
                          <span className="text-sm capitalize whitespace-nowrap">
                            {link.title}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  )
              )}
              <li>
                <button
                  onClick={logOut}
                  className={classNames({
                    "flex items-center text-red-400 border border-transparent hover:border-red-400 transition-colors duration-300":
                      true,
                    "rounded-md py-1 px-2 mx-3 gap-4 ": !collapsed,
                    "justify-center rounded-full p-1 mx-2 w-10 h-10": collapsed,
                  })}
                >
                  <i className="scale-90 mdi mdi-logout menu-icon" />
                  {!collapsed && <span className="text-sm">Log Out</span>}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
